import React from 'react';
import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBreakpoints } from 'hooks/useBreakpoints';
import Background from 'components/common/Background';
import cornerImg from 'assets/img/icons/corner.png';
import { DOWN, MONEY, UP, UPCOMINGVALUE } from './constants';

export default function BizIntelItems({ stat, index, showInitStat }) {
  const { breakpoints } = useBreakpoints();
  const { title, amount, subAmount, type, prefix } = stat;

  return (
    <Card
      style={{ minWidth: 190 }}
      className={classNames('me-2 mb-3 ', { 'w-100': breakpoints.down('sm') })}
    >
      <Background image={cornerImg} className="bg-card" />
      <Card.Body className="p-md-3">
        <h6>{title}</h6>
        <p
          className={classNames('font-sans-serif lh-1 mb-2', {
            'w-100 fs-4': breakpoints.down('sm'),
            'fs-1': breakpoints.up('sm')
          })}
        >
          <CountUp
            start={0}
            end={amount}
            decimals={stat.unit ? 2 : 0}
            duration={2}
            className="text-700"
            separator=","
            prefix={prefix === MONEY ? '$' : ''}
          />
        </p>
        <div className="d-flex align-items-center mb-2">
          <h6
            className={classNames('fs--2 text-500 mb-0', {
              'd-none': type === UPCOMINGVALUE
            })}
          >
            {subAmount}
          </h6>
          <h6
            className={classNames('fs--2 ps-3 mb-0', {
              'text-success': type === UP,
              'text-danger': type === DOWN,
              'd-none': type === UPCOMINGVALUE
            })}
          >
            <FontAwesomeIcon icon={type === UP ? 'caret-up' : 'caret-down'} className="me-1" />
            {stat.percent}%
          </h6>
        </div>
        {showInitStat && index === 0 && <p className="fs--2 mb-0">Last 7 days (inc Today)</p>}
      </Card.Body>
    </Card>
  );
}
