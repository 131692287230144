import yup from 'validation.yup';
import { dateOfBirthSchema, isContactNumber, isEmail, transformFn } from 'helpers/utils';
import { YupMessage } from 'constants/index';

export const UserFormSchema = yup
  .object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    role: yup.number().transform(transformFn).required(),
    tax_number: yup.string(),
    date_of_birth: yup.string().concat(dateOfBirthSchema),
    address: yup.object().shape({
      city: yup.string(),
      detail_address: yup.string(),
      state_id: yup.number().transform(transformFn).nullable(),
      country_id: yup.number().transform(transformFn).nullable(),
      postcode: yup.string()
    }),
    email: yup.lazy(value => {
      if (!value) return yup.string().required();
      return yup.string().matches(isEmail, YupMessage.INVALID_EMAIL);
    }),
    contact_number: yup.lazy(value => {
      if (!value) return yup.string().nullable();
      return yup.string().matches(isContactNumber, YupMessage.INVALID_FORMAT);
    })
  })
  .required();
