import { useState, useCallback, useEffect } from 'react';
import companyServices from 'services/company.services';

export default function useFetchCompany({ groupId } = {}) {
  const [companiesByGroup, setCompaniesByGroup] = useState([]);
  const [listCompany, setListCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCompanyByGroup, setLoadingCompanyByGroup] = useState(false);
  const [loadingDetailCompany, setLoadingDetailCompany] = useState(false);
  const [detailCompany, setDetailCompany] = useState(false);
  const [loadingCompanySupervisor, setLoadingCompanySupervisor] = useState(false);
  const [companySupervisor, setCompanySupervisors] = useState([]);

  useEffect(() => {
    if (groupId) {
      fetchCompaniesByGroup(groupId);
    }
    if (isNaN(groupId)) {
      setCompaniesByGroup([]);
    }
  }, [groupId]);

  const fetchCompanySupervisor = useCallback(async id => {
    try {
      setLoadingCompanySupervisor(true);
      const data = await companyServices.getCompanySupervisors(id);
      setCompanySupervisors(data.items || []);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingCompanySupervisor(false);
    }
  }, []);

  const fetchListCompany = useCallback(async body => {
    try {
      setLoading(true);
      const data = await companyServices.list(body);
      setListCompany(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDetailCompany = useCallback(async id => {
    try {
      setLoadingDetailCompany(true);
      const data = await companyServices.detail(id);
      setDetailCompany(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingDetailCompany(false);
    }
  }, []);

  const fetchCompaniesByGroup = async groupId => {
    try {
      setLoadingCompanyByGroup(true);
      const data = await companyServices.listCompanyByGroup(groupId);
      setCompaniesByGroup(data.items || []);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingCompanyByGroup(false);
    }
  };

  const resetCompanyDetail = useCallback(() => {
    setDetailCompany({});
  }, []);

  return {
    loading,
    loadingCompanyByGroup,
    listCompany,
    detailCompany,
    companiesByGroup,
    fetchCompaniesByGroup,
    fetchListCompany,
    resetCompanyDetail,
    fetchDetailCompany,
    loadingDetailCompany,
    fetchCompanySupervisor,
    companySupervisor,
    loadingCompanySupervisor
  };
}
