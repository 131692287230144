import React from 'react';
import AsyncSelect from 'components/common/search/AsyncSelect';
import { Controller, useFormContext } from 'react-hook-form';
import { Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { WizardInpuHorizontal } from 'components/wizard';
import { commonConfig, formLayout } from 'helpers/formLayoutConfig';
import { getListOptions, getValueObj } from 'helpers/utils';
import { COMPANY_PRINCIPAL, FLEET_PRINCIPAL, useAuthorizePrincipal } from './useAuthorizePrincipal';
import { useAuth } from 'hooks/useAuth';

const ParticipalItems = ({
  data,
  loading,
  viewMode,
  listState,
  isDisabled,
  listCountry,
  viewDetailMode,
  isAssignExistUser,
  fetchAvailablePrincipal,
  typePrincipal,
  onChangeCountry,
  onBlurEmailInput
}) => {
  const { userData } = useAuth();
  const defaultCountryCode = userData?.address?.countryCode;
  const permissions = useAuthorizePrincipal(typePrincipal);
  const {
    control,
    watch,
    register,
    formState: { errors }
  } = useFormContext();
  const errorMsg = getValueObj(errors, ['existing_principal']);
  if (loading && !viewDetailMode) return <LoadingOverlay />;

  const genHintText = () => {
    if (viewDetailMode) return '';
    if (typePrincipal === FLEET_PRINCIPAL) {
      return 'Please select Company first';
    }
    if (typePrincipal === COMPANY_PRINCIPAL) {
      return 'Please select Group first';
    }
  };
  const hintText = genHintText();

  if (isAssignExistUser) {
    return (
      <Row className="mb-1">
        <Col {...formLayout.label}>
          <label className="form-label col-form-label">
            Email<span className="is-required">*</span>
          </label>
          {hintText && <small className="d-block">({hintText})</small>}
        </Col>
        <Col {...formLayout.col}>
          <Controller
            name="existing_principal"
            control={control}
            defaultValue={null}
            render={({ field }) => {
              return (
                <AsyncSelect
                  isClearable
                  hasMedia={false}
                  cacheOptions={false}
                  value={field.value}
                  placeholder="Search by name or email..."
                  isDisabled={isDisabled}
                  fetchFn={fetchAvailablePrincipal}
                  onChange={item => field.onChange(item)}
                  selectClass={classNames('async-form-control', {
                    'is-invalid': errorMsg?.message
                  })}
                  wrapperClassname={classNames('ms-0', {
                    'is-invalid': errorMsg?.message
                  })}
                />
              );
            }}
          />
          <Form.Control.Feedback type="invalid">{errorMsg?.message}</Form.Control.Feedback>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {loading && viewDetailMode && <LoadingOverlay />}
      <WizardInpuHorizontal
        required
        label="First Name"
        name="user_principal.first_name"
        errors={errors}
        placeholder="First Name"
        {...commonConfig({
          register: register('user_principal.first_name'),
          disabled: viewMode,
          permissions: permissions['first_name']
        })}
      />
      <WizardInpuHorizontal
        required
        label="Last Name"
        name="user_principal.last_name"
        errors={errors}
        placeholder="Last Name"
        {...commonConfig({
          register: register('user_principal.last_name'),
          disabled: viewMode,
          permissions: permissions['last_name']
        })}
      />
      <WizardInpuHorizontal
        required
        type="number"
        label="Tax Number"
        name="user_principal.tax_number"
        errors={errors}
        placeholder="Tax Number"
        {...commonConfig({
          register: register('user_principal.tax_number'),
          disabled: viewMode,
          permissions: permissions['tax_number']
        })}
      />
      <WizardInpuHorizontal
        name="user_principal.date_of_birth"
        type="date"
        label="Date Of Birth"
        control={control}
        watch={watch}
        disabled={viewMode}
        errors={errors}
        {...commonConfig({
          disabled: viewMode,
          register: register('user_principal.date_of_birth'),
          permissions: permissions['date_of_birth']
        })}
      />
      <WizardInpuHorizontal
        type="select"
        watch={watch}
        required
        defaultOption={{
          label: data?.user_principal?.address?.country_name,
          value: data?.user_principal?.address?.country_id
        }}
        options={getListOptions(listCountry)}
        label="Country"
        name="user_principal.address.country_id"
        errors={errors}
        placeholder="Add Country"
        {...commonConfig({
          formControlProps: {
            onChange: onChangeCountry
          },
          register: register('user_principal.address.country_id', {
            valueAsNumber: true
          }),
          disabled: viewMode,
          permissions: permissions['country_id']
        })}
      />
      <WizardInpuHorizontal
        type="select"
        watch={watch}
        options={getListOptions(listState)}
        label="State"
        name="user_principal.address.state_id"
        errors={errors}
        placeholder="Add State"
        {...commonConfig({
          register: register('user_principal.address.state_id', {
            valueAsNumber: true
          }),
          disabled: viewMode,
          permissions: permissions['state_id']
        })}
      />
      <WizardInpuHorizontal
        label="Address"
        name="user_principal.address.detail_address"
        errors={errors}
        placeholder="Address"
        {...commonConfig({
          register: register('user_principal.address.detail_address'),
          disabled: viewMode,
          permissions: permissions['detail_address']
        })}
      />
      <WizardInpuHorizontal
        label="Locality"
        name="user_principal.address.city"
        errors={errors}
        placeholder="Locality"
        {...commonConfig({
          register: register('user_principal.address.city'),
          disabled: viewMode,
          permissions: permissions['city']
        })}
      />

      <WizardInpuHorizontal
        label="Post code / Zipcode"
        name="user_principal.address.postcode"
        errors={errors}
        placeholder="Post code / Zipcode"
        {...commonConfig({
          register: register('user_principal.address.postcode'),
          disabled: viewMode,
          permissions: permissions['postcode']
        })}
      />
      <WizardInpuHorizontal
        label="Phone number"
        required
        name="user_principal.contact_number"
        errors={errors}
        placeholder="Phone number"
        type="phoneInput"
        control={control}
        defaultCountry={defaultCountryCode}
        {...commonConfig({
          disabled: viewMode,
          permissions: permissions['contact_number']
        })}
      />
      <WizardInpuHorizontal
        label="Email"
        required
        name="user_principal.email"
        errors={errors}
        placeholder="Email"
        {...commonConfig({
          formControlProps: {
            onBlur: onBlurEmailInput
          },
          register: register('user_principal.email'),
          disabled: viewMode,
          permissions: permissions['email']
        })}
      />
    </>
  );
};

export default ParticipalItems;
