import React, { useEffect, useRef } from 'react';
import { Button, Card } from 'react-bootstrap';
import { WizardInpuHorizontal, WizardInput } from 'components/wizard';
import { searchPanelCommonConfig } from 'helpers/formLayoutConfig';
import { useForm, FormProvider } from 'react-hook-form';
import useGetCountry from 'hooks/customHooks/useGetCountry';
import { resetObjectValues, getListOptions } from 'helpers/utils';
import useFetchGroup from 'pages/Group/services/useFetchGroup';
import useFetchCompany from 'pages/Company/services/useFetchCompany';
import useFetchFleet from 'pages/Fleet/services/useFetchFleet';
import LoadingButton from 'components/common/LoadingButton';
import { CloseIcon } from 'assets/img/icons';
import PageViewWrapper from './PageViewWrapper';
import { GROUP, COMPANY, FLEET, DRIVER } from 'constants/index';
import { normalizeData } from './helper';
import classNames from 'classnames';

export default function ScreenSearchPanel({
  loadingList,
  onSubmitFilter,
  activeFilter,
  pageKey,
  setFilterStatus
}) {
  const countryRef = useRef(null);
  const fleetRef = useRef(null);
  const companyRef = useRef(null);
  const groupRef = useRef(null);
  const stateRef = useRef(null);
  const formMethods = useForm();
  const { register, watch, setValue, handleSubmit, control, reset, getValues } = formMethods;
  const countryCode = watch('country')?.value;
  const groupId = watch('group')?.value;
  const companyId = watch('company')?.value;

  const { listCountry, listState, fetchCountries, loadingState } = useGetCountry({
    countryId: countryCode
  });
  const { listGroup, fetchListGroup } = useFetchGroup();
  const { companiesByGroup, loadingCompanyByGroup } = useFetchCompany({ groupId });
  const { listFleetByCompany, loadingListFleetByCompany } = useFetchFleet({ companyId });

  useEffect(() => {
    fetchCountries();
    fetchListGroup({ pageSize: 1000 });
  }, []);

  useEffect(() => {
    if (!activeFilter) {
      onResetFilter();
    }
  }, [activeFilter]);

  useEffect(() => {
    if (groupId && listGroup?.items?.length) {
      const selectedGroup = listGroup.items.find(item => item.id === groupId);
      setValue('country', selectedGroup?.country_id);
    }
  }, [groupId, listGroup]);

  useEffect(() => {
    if (companyId && companiesByGroup.length) {
      const selectedCompany = companiesByGroup.find(item => item.id === companyId);
      setValue('state', selectedCompany?.state_id);
    }
  }, [companyId, companiesByGroup]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const onSubmit = data => {
    const formattedData = normalizeData(data);
    onSubmitFilter(formattedData);
    scrollToTop();
  };

  const onResetFilter = () => {
    const initialForm = resetObjectValues(getValues());
    reset(initialForm);
  };

  const handleOnReset = () => {
    [countryRef, fleetRef, companyRef, stateRef, groupRef].map(ref => {
      if (ref?.current) ref.current.clearValue();
    });
    onResetFilter();
    onSubmitFilter({});
    scrollToTop();
  };

  const getIncludedDisabledLabel = () => {
    switch (pageKey) {
      case GROUP:
        return 'Include inactive group';
      case COMPANY:
        return 'Include inactive company';
      case FLEET:
        return 'Include inactive fleet';
      case DRIVER:
        return 'Include inactive driver';
      default:
        break;
    }
  };

  return (
    <div
      className={classNames('mx-md-2 filter-content position-relative overflow-hidden', {
        hidden: !activeFilter,
        'overflow-visible': activeFilter
      })}
      style={{ width: activeFilter ? 220 : 'auto' }}
    >
      <Card className="border">
        <Card.Header className="p-2 d-flex align-items-center bg-primary-subtle">
          <h5 className="mb-0 fs-0 mr-auto fw-bold">Filters</h5>
          <CloseIcon onClick={() => setFilterStatus(false)} width="12" height="12" />
        </Card.Header>
        <Card.Body className="border-top fs--1 position-relative p-1 pb-3">
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <PageViewWrapper canView={[DRIVER].includes(pageKey)}>
                <WizardInput
                  type="number"
                  label="Driver Accreditation"
                  name="dc_number"
                  {...searchPanelCommonConfig({
                    register: register('dc_number')
                  })}
                />
                <WizardInput
                  label="Driver Email"
                  name="email"
                  {...searchPanelCommonConfig({
                    register: register('email')
                  })}
                />
                <WizardInput
                  label="Driver Name"
                  name="driver_name"
                  {...searchPanelCommonConfig({
                    register: register('driver_name')
                  })}
                />
              </PageViewWrapper>
              <PageViewWrapper canView={[GROUP].includes(pageKey)}>
                <WizardInput
                  label="Group Name"
                  name="group_name"
                  {...searchPanelCommonConfig({
                    register: register('group_name')
                  })}
                />
              </PageViewWrapper>
              <PageViewWrapper canView={[COMPANY].includes(pageKey)}>
                <WizardInput
                  label="Company Name"
                  name="company_name"
                  {...searchPanelCommonConfig({
                    register: register('company_name')
                  })}
                />
              </PageViewWrapper>
              <PageViewWrapper canView={[FLEET].includes(pageKey)}>
                <WizardInput
                  label="Fleet Name"
                  name="fleet_name"
                  {...searchPanelCommonConfig({
                    register: register('fleet_name')
                  })}
                />
              </PageViewWrapper>
              <PageViewWrapper canView={[DRIVER, COMPANY, FLEET].includes(pageKey)}>
                <WizardInput
                  type="new-select"
                  label="Group"
                  name="group"
                  isClearable
                  ref={groupRef}
                  control={control}
                  options={getListOptions(listGroup.items || [])}
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('group')
                  })}
                />
              </PageViewWrapper>
              <PageViewWrapper canView={[DRIVER, FLEET].includes(pageKey)}>
                <WizardInput
                  type="new-select"
                  label="Company"
                  name="company"
                  isClearable
                  ref={companyRef}
                  control={control}
                  isLoading={loadingCompanyByGroup}
                  options={getListOptions(companiesByGroup)}
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('company')
                  })}
                />
              </PageViewWrapper>
              <PageViewWrapper canView={[DRIVER].includes(pageKey)}>
                <WizardInput
                  type="new-select"
                  label="Fleet"
                  name="fleet"
                  ref={fleetRef}
                  isClearable
                  isLoading={loadingListFleetByCompany}
                  options={getListOptions(listFleetByCompany)}
                  control={control}
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('fleet')
                  })}
                />
              </PageViewWrapper>
              <PageViewWrapper canView={[DRIVER, GROUP, COMPANY, FLEET].includes(pageKey)}>
                <WizardInput
                  type="new-select"
                  control={control}
                  isClearable
                  ref={countryRef}
                  options={getListOptions(listCountry)}
                  label="Country"
                  name="country"
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('country')
                  })}
                />
              </PageViewWrapper>
              <PageViewWrapper canView={[DRIVER, GROUP, COMPANY, FLEET].includes(pageKey)}>
                <WizardInput
                  type="new-select"
                  control={control}
                  isClearable
                  ref={stateRef}
                  options={getListOptions(listState)}
                  label="State"
                  name="state"
                  isLoading={loadingState}
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('state')
                  })}
                />
              </PageViewWrapper>
              <WizardInpuHorizontal
                type="checkbox"
                label={<span className="fs--075 text-nowrap">{getIncludedDisabledLabel()}</span>}
                name="status"
                {...searchPanelCommonConfig({
                  register: register('status'),
                  formGroupProps: {
                    className: 'mb-1 px-1 mt-2 fs--2'
                  }
                })}
              />
              <div className="d-flex gap-3 justify-content-center mt-4">
                <Button
                  className="default-btn"
                  variant="falcon-default"
                  size="sm"
                  onClick={handleOnReset}
                >
                  Reset
                </Button>
                <LoadingButton
                  isLoading={loadingList}
                  className="default-btn d-flex justify-content-center"
                  btnProps={{
                    type: 'submit',
                    size: 'sm'
                  }}
                  name="Apply"
                />
              </div>
            </form>
          </FormProvider>
        </Card.Body>
      </Card>
    </div>
  );
}
