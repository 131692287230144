export const FORMAT_DATE_TIME = 'DD/MM/YYYY';
export const FORMAT_DATE_TIME_I = 'DD/MM/YYYY A';
export const FORMAT_DATE_TIME_II = 'DD MMM YYYY HH:mm';
export const FORMAT_DATE_TIME_III = 'DD/MM/YYYY HH:mm';
export const FORMAT_DATE_TIME_IV = 'DD MMM YYYY hh:mm A';
export const FORMAT_DATE_TIME_V = 'DD-MM-YYYY';
export const FORMAT_DATE_TIME_VI = 'YYYY-MM-DD';
export const DATA_PER_PAGE = 20;

export const SERVICE_FEE_TYPE = [
  {
    value: 1,
    label: 'Percentage'
  },
  {
    value: 2,
    label: 'Number'
  }
];

export const PLATFORM_FEE_TYPE = [
  {
    value: 1,
    label: 'Percentage'
  },
  {
    value: 2,
    label: 'Number'
  }
];

export const PAYMENT_POINT = [
  {
    label: 'Company',
    value: 'C'
  },
  {
    label: 'Driver/Fleet',
    value: 'DF'
  }
];

export const PAYMENT_POINT_FLEET = [
  {
    label: 'Fleet',
    value: 'F'
  },
  {
    label: 'Driver',
    value: 'D'
  },
  {
    label: 'Company',
    value: 'C'
  }
];

export const PAYMENT_METHOD_TYPE = [
  {
    label: 'Card Present',
    value: 'card_present'
  },
  {
    label: 'Card',
    value: 'card'
  }
];

export const CARD_TYPE = [
  {
    label: 'American Express',
    value: 'amex'
  },
  {
    label: 'Mastercard',
    value: 'mastercard'
  },
  {
    label: 'Visa',
    value: 'visa'
  },
  {
    label: 'eftpos Australia',
    value: 'eftpos_au'
  }
];

export const PAYOUT_STATUS = [
  {
    label: 'Group Unpaid',
    value: 'group'
  },
  {
    label: 'Endpoint Unpaid',
    value: 'endpoint'
  }
];

export const FILTER_NUMBER_OPTIONS = [
  {
    label: 'is equal to',
    value: 'equal'
  },
  {
    label: 'is between',
    value: 'between'
  },
  {
    label: 'is greater than',
    value: 'min'
  },
  {
    label: 'is less than',
    value: 'max'
  }
];

export const TABLE_COLUMN_FILTER_KEY = {
  FLEET: 'fleetSettings',
  GROUP: 'groupSettings',
  DRIVER: 'driverSettings',
  COMPANY: 'companySettings',
  TRANSACTION: 'transactionSettings'
};

export const LIST_REFUND_OPTIONS = isPartialRefund => [
  {
    value: 'full-refund',
    label: 'Full Refund'
  },
  {
    value: 'partial-refund',
    label: 'Partial  Refund'
  },
  ...(isPartialRefund
    ? [
        {
          value: 'cancel',
          label: 'Cancel'
        }
      ]
    : [])
];

export const DUPLICATE_EMAIL = 'DUPLICATE EMAIL';

export const userBasicStatusConfig = {
  0: {
    label: 'Inactive',
    type: 'danger',
    icon: 'ban'
  },
  1: {
    label: 'Active',
    type: 'success',
    icon: 'check'
  }
};

export const userStatusConfig = {
  ...userBasicStatusConfig,
  2: {
    label: 'Locked',
    type: 'warning',
    icon: 'lock'
  },
  3: {
    label: 'Archived',
    type: 'secondary',
    icon: 'folder-plus'
  }
};

export const DRIVER = 'DRIVER';
export const GROUP = 'GROUP';
export const COMPANY = 'COMPANY';
export const FLEET = 'FLEET';
