import { useState, useCallback } from 'react';
import driverServices from 'services/driver.services';

export default function useFetchDriver() {
  const [driverList, setDriverList] = useState({});
  const [loading, setLoading] = useState(false);
  const [driverDetail, setDriverDetail] = useState({});
  const [loadingDetail, setLoadingDetail] = useState(false);

  const fetchDriverList = useCallback(async body => {
    try {
      setLoading(true);
      const data = await driverServices.list(body);
      setDriverList(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDriverDetail = useCallback(async body => {
    try {
      setLoadingDetail(true);
      const data = await driverServices.detail(body);
      setDriverDetail(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingDetail(false);
    }
  }, []);

  const resetDriverDetail = useCallback(() => {
    setDriverDetail({});
  }, []);

  return {
    loading,
    driverList,
    driverDetail,
    fetchDriverList,
    fetchDriverDetail,
    resetDriverDetail,
    loadingDetail
  };
}
