import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { WizardInput } from 'components/wizard';
import { FILTER_NUMBER_OPTIONS } from 'constants';
import { searchPanelCommonConfig } from 'helpers/formLayoutConfig';
import Flex from 'components/common/Flex';

export default function AmountFilter() {
  const { control, register, watch, setValue } = useFormContext('');
  const amountType = watch('amount_type')?.value;
  const [typeAmount, setTypeAmount] = useState(FILTER_NUMBER_OPTIONS[0].value);

  useEffect(() => {
    if (amountType) {
      setTypeAmount(amountType);
      setValue('min_amount', undefined);
      setValue('max_amount', undefined);
      setValue('amount', undefined);
    }
  }, [amountType]);

  return (
    <>
      <WizardInput
        type="new-select"
        label="Amount"
        name="amount_type"
        control={control}
        options={FILTER_NUMBER_OPTIONS}
        {...searchPanelCommonConfig({
          formControlProps: {
            min: 0
          },
          register: register('amount_type')
        })}
      />
      {typeAmount === FILTER_NUMBER_OPTIONS[1].value ? (
        <Flex>
          <WizardInput
            label=""
            type="number"
            name="min_amount"
            {...searchPanelCommonConfig({
              formControlProps: { min: 0 },
              register: register('min_amount', {
                setValueAs: v => (v ? parseFloat(v) : undefined)
              }),
              formGroupProps: {
                className: 'mt-2 mb-1 ps-1 pe-1'
              }
            })}
          />
          <WizardInput
            label=""
            type="number"
            name="max_amount"
            {...searchPanelCommonConfig({
              formControlProps: { min: 0 },
              register: register('max_amount', {
                setValueAs: v => (v ? parseFloat(v) : undefined)
              }),
              formGroupProps: {
                className: 'mt-2 mb-1 pe-1 ps-1'
              }
            })}
          />
        </Flex>
      ) : (
        <WizardInput
          label=""
          type="number"
          name="amount"
          {...searchPanelCommonConfig({
            formControlProps: {
              min: 0
            },
            register: register('amount', {
              setValueAs: v => (v ? parseFloat(v) : undefined)
            }),
            formGroupProps: {
              className: 'mt-2 mb-1 px-1'
            }
          })}
        />
      )}
    </>
  );
}
