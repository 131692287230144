import React from 'react';
import { Card, Table } from 'react-bootstrap';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { ROLES } from 'constants';
import { useAuth } from 'hooks/useAuth';
import AuthenticationWrapper from 'components/authentication/AuthenticationWrapper';
import {
  renderRows,
  stripeParamsRows,
  transactionRows,
  transactionRefundsRows
} from '../rowsConfig';
import SendingReceipt from './subcomponents/SendingReceipt';

export default function TransactionDetails({ data, loading, fetchTransactionDetail }) {
  const { transactionStatus = '', id, isRefundReceiptSent } = data;
  const isRefundProgress = transactionStatus.toUpperCase().includes('REFUND');
  const { viewAsRole } = useAuth();

  return (
    <Card className="h-100">
      <Card.Header className="p-2 bg-primary-subtle">
        <h5>Transaction Details</h5>
      </Card.Header>
      <Card.Body className="p-2">
        <div className="table-responsive fs--1 position-relative d-flex flex-column h-100">
          {loading && <LoadingOverlay />}
          <Table size="sm" className="border-bottom mb-0" bordered>
            <tbody>{renderRows(data, transactionRows, viewAsRole)}</tbody>
          </Table>
          {isRefundProgress && (
            <>
              <SendingReceipt
                transactionId={id}
                isRefundReceiptSent={isRefundReceiptSent}
                fetchTransactionDetail={fetchTransactionDetail}
              />
              <Table size="sm" className="border-bottom" bordered>
                <tbody>{renderRows(data, transactionRefundsRows, viewAsRole)}</tbody>
              </Table>
            </>
          )}
          <AuthenticationWrapper viewAsRole={viewAsRole} permissions={[ROLES.ADMIN]}>
            <Table size="sm" className="border-bottom mt-auto" bordered>
              <tbody>{renderRows(data, stripeParamsRows)}</tbody>
            </Table>
          </AuthenticationWrapper>
        </div>
      </Card.Body>
    </Card>
  );
}
