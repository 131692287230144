import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import ErrorBoundary from 'layouts/ErrorBoundary';
import { routes } from './routes';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import { PATH_TRANSACTIONS } from './route.path';
import AuthenticationRoute from './AuthenticationRoute';
import useLayOut from 'layouts/useLayOut';

const AppRoutes = () => {
  const { isLogin } = useAuth();
  return (
    <ErrorBoundary>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        <Route index path="/" element={<Navigate replace to={PATH_TRANSACTIONS} />} />
        {routes.map((route, idx) => {
          const { isPrivate, withoutLayout, mainPath, isFunc } = route;
          const wrapperElement = useLayOut({ withoutLayout, isPrivate, isFunc });
          return (
            <Route path={mainPath} element={wrapperElement} key={`${mainPath}-${idx}`}>
              {route.childPath.map(item => {
                const { path, element, ...restItem } = item;
                return (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <AuthenticationRoute {...restItem} isPrivate={isPrivate}>
                        {element}
                      </AuthenticationRoute>
                    }
                  />
                );
              })}
            </Route>
          );
        })}
        <Route path="*" element={isLogin ? <MainLayout /> : <ErrorLayout />}>
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;
