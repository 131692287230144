import React from 'react';
import dayjs from 'dayjs';
import { FORMAT_DATE_TIME_II } from 'constants';
import StripeStatus from '../common/StripeStatus';
import CurrencyAmount from '../common/CurrencyAmount';
import { PAYMENT_METHOD_TYPE } from 'constants';
import { ROLES } from 'constants/index';
import PayOutStatus from '../common/PayOutStatus';

export const transactionRows = [
  {
    name: 'receiptNumber',
    label: 'Receipt #',
    formatter: data => (
      <a href={data.receiptUrl} target="_blank" rel="noopener noreferrer" className="fs--0">
        {data.receiptNumber}
      </a>
    )
  },
  {
    name: 'transactionTime',
    label: 'Date/time',
    formatter: data => <>{dayjs(data.transactionTime).format(FORMAT_DATE_TIME_II)}</>
  },
  {
    name: 'stripeStatus',
    label: 'Status',
    formatter: data => <StripeStatus status={data?.transactionStatus} />
  },
  {
    name: 'driverDc',
    label: 'Driver Accreditation'
  },
  {
    name: 'driverName',
    label: 'Driver Name'
  },
  {
    name: 'businessRegistrationId',
    label: 'Driver Tax ID'
  },
  {
    name: 'vehiclePlateNumber',
    label: 'Vehicle ID'
  },
  {
    name: 'state',
    label: 'State'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'fleetName',
    label: 'Fleet Name'
  },
  {
    name: 'companyName',
    label: 'Company'
  },
  {
    name: 'groupName',
    label: 'Group'
  },
  {
    name: 'pickUpLocation',
    label: 'Pickup Location'
  },
  {
    name: 'dropOffLocation',
    label: 'Drop off location'
  },
  {
    name: 'stripeCardBrand',
    label: 'Card Type',
    formatterClass: 'text-capitalize',
    formatter: data => <>{data?.stripeInfo?.stripeCardBrand}</>
  },
  {
    name: 'stripePartialCardNo',
    label: 'Card Number',
    formatter: data => <>{data?.stripeInfo?.stripePartialCardNo}</>
  },
  {
    name: 'fare',
    label: 'Fare Amount',
    formatter: data => <CurrencyAmount amount={data.fare} />
  },
  {
    name: 'extras',
    label: 'Extras Amount',
    formatter: data => <CurrencyAmount amount={data.extras} />
  },
  {
    name: 'tip',
    label: 'Tip Amount',
    formatter: data => <CurrencyAmount amount={data.tip} />
  },
  {
    name: 'voucherDiscount',
    label: 'Discount Voucher',
    formatter: data => <CurrencyAmount amount={data.voucherDiscount} />
  },
  {
    name: 'serviceFeeAmount',
    label: 'Service Fee Amount (ex tax)',
    formatter: data => <CurrencyAmount amount={data.serviceFeeAmount} />
  },
  {
    name: 'serviceFeeAmountTax',
    label: 'Service Fee Tax Amount',
    formatter: data => <CurrencyAmount amount={data.serviceFeeAmountTax} />
  },
  {
    name: 'serviceFee',
    label: 'Service Fee (inc tax)',
    formatter: data => <CurrencyAmount amount={data.serviceFee} />
  },
  {
    name: 'stripeCurrency',
    label: 'Currency',
    formatterClass: 'text-uppercase',
    formatter: data => <>{data?.stripeInfo?.stripeCurrency}</>
  },
  {
    name: 'faceValue',
    label: 'Face Value',
    formatter: data => <CurrencyAmount amount={data.faceValue} />
  },
  {
    name: 'amount',
    label: 'Amount',
    formatter: data => <CurrencyAmount amount={data.amount} />
  }
];

export const transactionRefundsRows = [
  {
    name: 'refund_receipt_url',
    label: 'Refund Receipt #',
    formatter: data => (
      <>
        {data?.refundReceiptUrl && (
          <a href={data?.refundReceiptUrl} target="_blank" rel="noreferrer">
            Click here
          </a>
        )}
      </>
    )
  },
  {
    name: 'payment_point_2_stripe_refund_date',
    label: 'Refund Date/Time',
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint2StripeRefundDate
          ? dayjs
              .unix(data?.stripePayment?.paymentPoint2StripeRefundDate)
              .format(FORMAT_DATE_TIME_II)
          : ''}
      </>
    )
  },
  {
    name: 'payment_point_2_stripe_refund_amount',
    label: 'Refund Amount',
    formatter: data => <CurrencyAmount amount={data?.refundAmount} />
  }
];

export const stripeParamsRows = [
  {
    name: 'stripeFee',
    label: 'Stripe Fee',
    formatter: data => <CurrencyAmount amount={data?.stripeInfo?.stripeFee} />
  },
  {
    name: 'stripePaymentTypeMethods',
    label: 'Payment Method Type',
    formatter: data => (
      <>
        {
          PAYMENT_METHOD_TYPE.find(
            item => item.value === data?.stripeInfo?.stripePaymentTypeMethods
          )?.label
        }
      </>
    )
  },
  {
    name: 'stripePaymentNetwork',
    label: 'Network',
    formatterClass: 'text-capitalize',
    formatter: data => <>{data?.stripeInfo?.stripePaymentNetwork}</>
  },
  {
    name: 'stripePaymentIntentId',
    label: 'Stripe Payment Intent ID',
    formatter: data => <>{data?.stripeInfo?.stripePaymentIntentId}</>
  },
  {
    name: 'stripeTransferGroup',
    label: 'Stripe Transfer Group',
    formatter: data => <>{data?.stripeInfo?.stripeTransferGroup}</>
  }
];

export const endpointPayoutRows = [
  {
    name: 'paymentPoint2Payto',
    label: 'Endpoint Type',
    formatter: data => <>{data?.stripePayment?.paymentPoint2Payto}</>
  },
  {
    name: 'paymentPoint2Name',
    label: 'Endpoint Name',
    formatter: data => <>{data?.stripePayment?.paymentPoint2Name}</>
  },
  {
    name: 'faceValue',
    label: 'Face Value',
    formatter: data => <CurrencyAmount amount={data?.faceValue} />
  },
  {
    name: 'paymentPoint2Rate',
    label: 'Endpoint Rate',
    formatter: data => <>{`${data?.stripePayment?.paymentPoint2Rate || 0}%`}</>
  },
  {
    name: 'paymentPoint2RateAmount',
    label: 'Endpoint Rate Amount',
    formatter: data => <>${data?.stripePayment?.paymentPoint2RateAmount}</>
  },
  {
    name: 'platformFeeRate',
    label: 'Platform Fee Rate',
    formatter: data => <>{`${data.platformFeeRate || 0}%`}</>
  },
  {
    name: 'platformFeeAmountIncTax',
    label: 'Platform Fee Amount',
    formatter: data => <>${data.platformFeeAmountIncTax}</>
  },
  {
    name: 'paymentPoint2Amount',
    label: 'Endpoint Amount',
    formatter: data => <CurrencyAmount amount={data?.stripePayment?.paymentPoint2Amount} />
  },
  {
    name: 'paymentPoint2StripePayoutAmount',
    label: 'Endpoint Payout Amount',
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint2StripePayoutAmount !== '0.00' &&
          `$${data?.stripePayment?.paymentPoint2StripePayoutAmount}`}
      </>
    )
  },
  {
    name: 'paymentPoint2StripePayoutStatus',
    label: 'Endpoint Payout Status',
    formatter: data => (
      <PayOutStatus status={data?.stripePayment?.paymentPoint2StripePayoutStatus} />
    )
  },
  {
    name: 'paymentPoint2StripePayoutDate',
    label: 'Endpoint Payout Date',
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint2StripePayoutDate
          ? dayjs
              .unix(data?.stripePayment?.paymentPoint2StripePayoutDate)
              .format(FORMAT_DATE_TIME_II)
          : ''}
      </>
    )
  },
  {
    name: 'paymentPoint2TransferId',
    label: 'Endpoint Payout Stripe ID',
    formatter: data => <>{data?.stripePayment?.paymentPoint2TransferId}</>,
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
  },
  {
    name: 'paymentPoint2StripePayoutId',
    label: 'Endpoint Batch Payout ID',
    formatter: data => <>{data?.stripePayment?.paymentPoint2StripePayoutId}</>,
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
  },
  {
    name: 'paymentPoint2StripeAccountId',
    label: 'Destination Account ID',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <>{data?.stripePayment?.paymentPoint2StripeAccountId}</>
  }
];

export const endpointPayoutRefundRows = [
  {
    name: 'paymentPoint2Payto',
    label: 'Endpoint Type',
    formatter: data => <>{data?.stripePayment?.paymentPoint2Payto}</>
  },
  {
    name: 'paymentPoint2Name',
    label: 'Endpoint Name',
    formatter: data => <>{data?.stripePayment?.paymentPoint2Name}</>
  },
  {
    name: 'faceValue',
    label: 'Face Value',
    formatter: data => <CurrencyAmount amount={data?.faceValue} />
  },
  {
    name: 'paymentPoint2Rate',
    label: 'Endpoint Rate',
    formatter: data => <>{`${data?.stripePayment?.paymentPoint2Rate || 0}%`}</>
  },
  {
    name: 'paymentPoint2RateAmount',
    label: 'Endpoint Rate Amount',
    formatter: data => <>${data?.stripePayment?.paymentPoint2RateAmount}</>
  },
  {
    name: 'platformFeeRate',
    label: 'Platform Fee Rate',
    formatter: data => <>{`${data.platformFeeRate || 0}%`}</>
  },
  {
    name: 'platformFeeAmountIncTax',
    label: 'Platform Fee Amount',
    formatter: data => <>${data.platformFeeAmountIncTax}</>
  },
  {
    name: 'paymentPoint2Amount',
    label: 'Endpoint Amount',
    formatter: data => <CurrencyAmount amount={data?.stripePayment?.paymentPoint2Amount} />
  },
  {
    name: 'paymentPoint2StripeRefundAmount',
    label: 'Endpoint Refund Amount',
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint2StripeRefundAmount !== '0.00' &&
          `$${data?.stripePayment?.paymentPoint2StripeRefundAmount}`}
      </>
    )
  },
  {
    name: 'paymentPoint2StripePayoutStatus',
    label: 'Endpoint Refund Status',
    formatter: data => (
      <PayOutStatus status={data?.stripePayment?.paymentPoint2StripePayoutStatus} />
    )
  },
  {
    name: 'paymentPoint2StripeRefundDate',
    label: 'Endpoint Refund Date',
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint2StripeRefundDate
          ? dayjs
              .unix(data?.stripePayment?.paymentPoint2StripeRefundDate)
              .format(FORMAT_DATE_TIME_II)
          : ''}
      </>
    )
  },
  {
    name: 'paymentPoint2TransactionReversalId',
    label: 'Endpoint Refund Stripe ID',
    formatter: data => <>{data?.stripePayment?.paymentPoint2TransactionReversalId}</>,
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
  },
  {
    name: 'paymentPoint2StripeSourceId',
    label: 'Source Account ID',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <>{data?.stripePayment?.paymentPoint2StripeSourceId}</>
  }
];

export const groupPayoutRows = [
  {
    name: 'paymentPoint1GroupName',
    label: 'Group Name',
    formatter: data => <>{data?.stripePayment?.paymentPoint1GroupName}</>,
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
  },
  {
    name: 'paymentPoint1GroupRate',
    label: 'Group Effective Rate',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <>{`${data?.stripePayment?.paymentPoint1GroupRate || 0}%`}</>
  },
  {
    name: 'paymentPoint1GroupAmount',
    label: 'Group Effective Amount',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <CurrencyAmount amount={data?.stripePayment?.paymentPoint1GroupAmount} />
  },
  {
    name: 'paymentPoint1GroupStripePayoutAmount',
    label: 'Group Payout Amount',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint1GroupStripePayoutAmount !== '0.00' &&
          `$${data?.stripePayment?.paymentPoint1GroupStripePayoutAmount}`}
      </>
    )
  },
  {
    name: 'paymentPoint1GroupStripePayoutStatus',
    label: 'Group Payout Status',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <PayOutStatus status={data?.stripePayment?.paymentPoint1GroupStripePayoutStatus} />
    )
  },
  {
    name: 'paymentPoint1GroupStripePayoutDate',
    label: 'Group Payout Date',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint1GroupStripePayoutDate
          ? dayjs
              .unix(data?.stripePayment?.paymentPoint1GroupStripePayoutDate)
              .format(FORMAT_DATE_TIME_II)
          : ''}
      </>
    )
  },
  {
    name: 'paymentPoint1TransferId',
    label: 'Group Payout Stripe ID',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <>{data?.stripePayment?.paymentPoint1TransferId}</>
  },
  {
    name: 'paymentPoint1GroupStripePayoutId',
    label: 'Endpoint Batch Payout ID',
    formatter: data => <>{data?.stripePayment?.paymentPoint1GroupStripePayoutId}</>,
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
  },
  {
    name: 'paymentPoint1GroupStripeAccountId',
    label: 'Destination Account ID',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <>{data?.stripePayment?.paymentPoint1GroupStripeAccountId}</>
  }
];

export const groupPayoutRefundRows = [
  {
    name: 'paymentPoint1GroupName',
    label: 'Group Name',
    formatter: data => <>{data?.stripePayment?.paymentPoint1GroupName}</>,
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
  },
  {
    name: 'paymentPoint1GroupRate',
    label: 'Group Effective Rate',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <>{`${data?.stripePayment?.paymentPoint1GroupRate || 0}%`}</>
  },
  {
    name: 'paymentPoint1GroupAmount',
    label: 'Group Effective Amount',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <CurrencyAmount amount={data?.stripePayment?.paymentPoint1GroupAmount} />
  },
  {
    name: 'paymentPoint1GroupStripeRefundAmount',
    label: 'Group Refund Amount',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint1GroupStripeRefundAmount !== '0.00' &&
          `$${data?.stripePayment?.paymentPoint1GroupStripeRefundAmount}`}
      </>
    )
  },
  {
    name: 'paymentPoint1GroupStripePayoutStatus',
    label: 'Group Refund Status',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <PayOutStatus status={data?.stripePayment?.paymentPoint1GroupStripePayoutStatus} />
    )
  },
  {
    name: 'paymentPoint1GroupStripeRefundDate',
    label: 'Group Refund Date',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint1GroupStripeRefundDate
          ? dayjs
              .unix(data?.stripePayment?.paymentPoint1GroupStripeRefundDate)
              .format(FORMAT_DATE_TIME_II)
          : ''}
      </>
    )
  },
  {
    name: 'paymentPoint1TransactionReversalId',
    label: 'Group Refund Stripe ID',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <>{data?.stripePayment?.paymentPoint1TransactionReversalId}</>
  },
  {
    name: 'paymentPoint1StripeSourceId',
    label: 'Source Account ID',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => <>{data?.stripePayment?.paymentPoint1StripeSourceId}</>
  }
];

export const parentPayoutRows = [
  {
    name: 'parentCommissionRate',
    label: 'Parent Effective Rate',
    formatter: data => <>{`${data.parentCommissionRate || 0}%`}</>
  },
  {
    name: 'paymentPoint0Amount',
    label: 'Parent Effective Amount',
    formatter: data => <CurrencyAmount amount={data?.stripePayment?.paymentPoint0Amount} />
  },
  {
    name: 'paymentPoint0StripePayoutAmount',
    label: [
      {
        value: 'Parent Payout Amount',
        label: 'Parent Payout Amount',
        type: 'default'
      },
      {
        value: 'Parent Refund Amount',
        label: 'Parent Refund Amount',
        type: 'refund'
      }
    ],
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint0StripePayoutAmount !== '0.00' &&
          `$${data?.stripePayment?.paymentPoint0StripePayoutAmount}`}
      </>
    )
  },
  {
    name: 'paymentPoint0StripePayoutStatus',
    label: [
      {
        value: 'Parent Payout Status',
        label: 'Parent Payout Status',
        type: 'default'
      },
      {
        value: 'Parent Refund Status',
        label: 'Parent Refund Status',
        type: 'refund'
      }
    ],
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <PayOutStatus status={data?.stripePayment?.paymentPoint0StripePayoutStatus} />
    )
  },
  {
    name: 'paymentPoint0StripePayoutDate',
    label: [
      {
        value: 'Parent Payout Date',
        label: 'Parent Payout Date',
        type: 'default'
      },
      {
        value: 'Parent Refund Date',
        label: 'Parent Refund Date',
        type: 'refund'
      }
    ],
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint0StripePayoutDate
          ? dayjs
              .unix(data?.stripePayment?.paymentPoint0StripePayoutDate)
              .format(FORMAT_DATE_TIME_II)
          : ''}
      </>
    )
  },
  {
    name: 'paymentPoint0StripePayoutId',
    label: 'Parent Batch Payout ID',
    formatter: data => <>{data?.stripePayment?.paymentPoint0StripePayoutId}</>
  }
];

export const parentPayoutRefundRows = [
  {
    name: 'parentCommissionRate',
    label: 'Parent Effective Rate',
    formatter: data => <>{`${data.parentCommissionRate || 0}%`}</>
  },
  {
    name: 'paymentPoint0Amount',
    label: 'Parent Effective Amount',
    formatter: data => <CurrencyAmount amount={data?.stripePayment?.paymentPoint0Amount} />
  },
  {
    name: 'paymentPoint0RefundAmount',
    label: 'Parent Refund Amount',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint0RefundAmount !== '0.00' &&
          `$${data?.stripePayment?.paymentPoint0RefundAmount}`}
      </>
    )
  },
  {
    name: 'paymentPoint0StripePayoutStatus',
    label: 'Parent Refund Status',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <PayOutStatus status={data?.stripePayment?.paymentPoint0StripePayoutStatus} />
    )
  },
  {
    name: 'paymentPoint0RefundDate',
    label: 'Parent Refund Date',
    permissions: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP],
    formatter: data => (
      <>
        {data?.stripePayment?.paymentPoint0RefundDate
          ? dayjs.unix(data?.stripePayment?.paymentPoint0RefundDate).format(FORMAT_DATE_TIME_II)
          : ''}
      </>
    )
  },
  {
    name: 'paymentPoint0RefundId',
    label: 'Parent Refund ID',
    formatter: data => <>{data?.stripePayment?.paymentPoint0RefundId}</>
  }
];

export const renderRows = (data, config, viewAsRole) => (
  <>
    {config.map(item => {
      if (item.permissions?.length && !item.permissions.includes(viewAsRole)) return null;
      const itemLabel = typeof item.label === 'string' ? item.label : item.label[0].label;
      return (
        <tr key={item.name}>
          <td className="bg-100 w-50 text-start">{itemLabel}</td>
          <td className={item.formatterClass || ''}>
            {item.formatter ? item.formatter(data) : data[item.name]}
          </td>
        </tr>
      );
    })}
  </>
);
