export const formLayout = {
  label: {
    xs: 12,
    sm: 5,
    lg: 5
  },
  col: {
    xs: 12,
    sm: 7,
    lg: 7
  }
};

export const commonConfig = ({
  register = {},
  disabled = true,
  formGroupProps,
  labelClassName,
  formControlProps,
  permissions,
  formGroupLayout = formLayout
}) => {
  return {
    formControlProps: {
      size: 'sm',
      className: 'fs--1',
      disabled,
      ...register,
      ...formControlProps
    },
    formGroupProps: {
      className: 'mb-1',
      ...formGroupProps
    },
    labelClassName: labelClassName || 'text-nowrap form-label mb-1',
    formGroupLayout,
    permissions
  };
};

export const searchPanelCommonConfig = ({
  register = {},
  formGroupProps,
  formControlProps,
  labelClassName,
  permissions,
  formGroupLayout = formLayout
}) => {
  return {
    formControlProps: {
      size: 'sm',
      className: 'fs--1',
      ...register,
      ...formControlProps
    },
    formGroupProps: {
      className: 'mb-2 px-1',
      ...formGroupProps
    },
    labelClassName: labelClassName || 'text-nowrap form-label mb-1',
    formGroupLayout,
    permissions
  };
};
