import { useState, useEffect } from 'react';
import useDebounce from 'hooks/useDebounce';
import userServices from 'services/user.services';

export default function useCheckDuplicateEmail({ email, bypass, initialValue }) {
  const [isValidEmail, checkValidEmail] = useState(true);
  const [errMsg, setErrMsg] = useState(null);
  const [pendingCheckingEmail, setPendingCheckingEmail] = useState(false);
  const inputEmail = useDebounce(email);

  const checkDuplicateEmail = async email => {
    try {
      setPendingCheckingEmail(true);
      await userServices.checkValidInfo({ text: email, type: 'email' });
      checkValidEmail(true);
    } catch (err) {
      checkValidEmail(false);
      setErrMsg(err?.error?.message);
    } finally {
      setPendingCheckingEmail(false);
    }
  };

  const resetState = () => {
    setErrMsg(null);
    checkValidEmail(false);
  };

  useEffect(() => {
    if (inputEmail && !bypass && initialValue !== email) {
      checkDuplicateEmail(inputEmail);
    }
  }, [inputEmail, bypass, initialValue]);

  return {
    errMsg,
    isValidEmail,
    pendingCheckingEmail,
    resetState
  };
}
