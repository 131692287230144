import React from 'react';
import noData from 'assets/img/icons/empty-box.svg';

export default function NoDataBlock() {
  return (
    <div className="d-flex justify-content-center align-items-center w-100" style={{ height: 100 }}>
      <div className="d-flex flex-sm-column">
        <img className="icon" src={noData} alt="empty-data" />
        <span className="fs--1 text-center">No Data</span>
      </div>
    </div>
  );
}
