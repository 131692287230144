import React from 'react';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SERVICE_FEE_TYPE, PAYMENT_POINT } from 'constants';

export const visibleColumnIds = [
  'name',
  'status',
  'stripe_connect_enabled',
  'state',
  'country',
  'group_name',
  'group_comm',
  'service_fee',
  'payment_point',
  'company_comm',
  'driver_numbers'
];

export const availableColumns = [
  'name',
  'status',
  'stripe_connect_enabled',
  'state',
  'country',
  'group_name',
  'group_comm',
  'service_fee_type',
  'service_fee',
  'payment_point',
  'company_comm',
  'driver_numbers'
];

// All columns
export const columnsConfig = [
  {
    accessor: 'name',
    Header: 'Name',
    disableSortBy: true,
    width: 180,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'py-2'
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    disableSortBy: true,
    width: 100,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <Badge bg={status === 1 ? 'success' : 'danger'} className="badge-min-w">
          {status === 1 ? 'Active' : 'Inactive'}
          <FontAwesomeIcon icon="check" className="ms-1" transform="shrink-2" />
        </Badge>
      );
    }
  },
  {
    accessor: 'stripe_connect_enabled',
    Header: 'Stripe Connect Enabled',
    disableSortBy: true,
    headerProps: { className: 'text-900 text-center' },
    cellProps: {
      className: 'justify-content-center'
    },
    Cell: rowData => {
      const { stripe_connect_enabled: item } = rowData.row.original;
      return (
        <Badge bg={item === 1 ? 'success' : 'danger'} className="badge-min-w">
          {item === 1 ? 'Yes' : 'No'}
          <FontAwesomeIcon icon="check" className="ms-1" transform="shrink-2" />
        </Badge>
      );
    }
  },
  {
    accessor: 'state',
    Header: 'State',
    disableSortBy: true,
    width: 200,
    headerProps: { className: 'text-900 ps-2' },
    cellProps: {
      className: 'ps-2'
    }
  },
  {
    accessor: 'country',
    Header: 'Country',
    width: 80,
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'group_name',
    Header: 'Group',
    width: 200,
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'group_comm',
    Header: 'Group Rate (inc Tax)',
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'justify-content-end'
    },
    Cell: rowData => {
      const { group_comm: groupComm } = rowData.row.original;
      return <span>{groupComm}%</span>;
    }
  },
  {
    accessor: 'service_fee_type',
    Header: 'Service Fee Type',
    width: 120,
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => {
      const { service_fee_type: type } = rowData.row.original;
      return <span>{SERVICE_FEE_TYPE.find(item => item.value === type)?.label}</span>;
    }
  },
  {
    accessor: 'service_fee',
    Header: 'Service Fee',
    disableSortBy: true,
    width: 100,
    headerProps: { className: 'text-900 text-end' },
    cellProps: {
      className: 'justify-content-end'
    },
    Cell: rowData => {
      const { service_fee: serviceFee, service_fee_type: serviceFeeType } = rowData.row.original;
      if (serviceFeeType === SERVICE_FEE_TYPE[0].value) {
        return <span>{serviceFee}%</span>;
      }
      return <span>${serviceFee} </span>;
    }
  },
  {
    accessor: 'payment_point',
    Header: 'Payment Point',
    width: 120,
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => {
      const { payment_point: ppoint } = rowData.row.original;
      return <span>{PAYMENT_POINT.find(item => item.value === ppoint)?.label}</span>;
    }
  },
  {
    accessor: 'company_comm',
    Header: 'Endpoint Rate (inc Tax)',
    disableSortBy: true,
    headerProps: { className: 'text-900', width: 150 },
    cellProps: {
      className: 'justify-content-end',
      width: 150
    },
    Cell: rowData => {
      const { company_comm: companyComm } = rowData.row.original;
      return <>{companyComm}%</>;
    }
  },
  {
    accessor: 'driver_numbers',
    width: 80,
    Header: 'Drivers',
    disableSortBy: true,
    headerProps: { className: 'text-900 text-end' },
    cellProps: {
      className: 'justify-content-end'
    }
  }
];
