import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { LIST_ROLES, YupMessage, DUPLICATE_EMAIL } from 'constants/index';
import { getListOptions, getValueObj, isValidEmail as isValidEmailInput } from 'helpers/utils';
import { commonConfig } from 'helpers/formLayoutConfig';
import { WizardInpuHorizontal } from 'components/wizard';
import userServices from 'services/user.services';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useAuth } from 'hooks/useAuth';
import FooterActionBtn from 'components/pages/FooterActionBtn';
import { useUserContext } from 'pages/Users/useUserContext';
import { UserFormSchema } from 'pages/Users/config';
import { useCheckDuplicateEmail, useCheckValidABN, useGetCountry } from 'hooks/customHooks';

export default function GeneralInfo() {
  const { userDetail, userId, onCloseModal, loadingDetail, fetchUserList } = useUserContext();
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
  const [disabledForm, setDisabledForm] = useState(true);
  const viewDetailMode = Boolean(userId); // view detail item
  const viewMode = viewDetailMode && disabledForm; // only view item
  const editMode = viewDetailMode && !disabledForm; // enable update item

  const { formState, watch, control, setError, register, clearErrors, handleSubmit, reset } =
    useForm({
      resolver: yupResolver(UserFormSchema),
      mode: 'all',
      values: {
        ...userDetail,
        role: userDetail.roles?.[0]
      }
    });

  const { errors } = formState;
  const countryCode = watch('address.country_id');
  const emailInput = watch('email');
  const abnInput = watch('tax_number');

  const { fetchCountries, listCountry, listState } = useGetCountry({ countryId: countryCode });

  const { pendingCheckAbn } = useCheckValidABN({
    formState,
    setError,
    clearErrors,
    abn: abnInput,
    name: 'tax_number',
    bypass: !abnInput || viewMode,
    validateSpecificField: true
  });

  const { errMsg, isValidEmail, pendingCheckingEmail, resetState } = useCheckDuplicateEmail({
    email: emailInput,
    bypass: viewMode,
    initialValue: userDetail.email
  });

  const onCreateUpdateSuccess = () => {
    onCloseModal();
    fetchUserList();
  };

  const onSubmit = async data => {
    try {
      setLoading(true);
      if (userId) {
        await userServices.updateUser(userId, data);
        toast.success('The user was successfully updated.');
        onCreateUpdateSuccess();
        return;
      }
      await userServices.createUser(data);
      toast.success('The user was successfully created.');
      onCreateUpdateSuccess();
    } catch (error) {
      if (error?.error?.type === DUPLICATE_EMAIL) {
        setError('email', { type: 'optionality', message: error?.error?.message });
      }
    } finally {
      setLoading(false);
    }
  };

  const onReset = () => {
    reset(userDetail);
    setDisabledForm(true);
  };

  const onEnableEditForm = () => {
    setDisabledForm(false);
  };

  const onValidateEmail = () => {
    if (!emailInput) {
      setError('email', {
        type: 'optionality',
        message: YupMessage.REQUIRED
      });
      resetState();
      return;
    }

    const errorMsgEmail = getValueObj(errors, 'email'.split('.'));

    if (isValidEmailInput(emailInput) && isValidEmail && errMsg && errorMsgEmail?.type === 'custom')
      clearErrors('email');

    if (errMsg && !isValidEmail) {
      setError('email', {
        type: 'custom',
        message: errMsg
      });
    }

    if (!isValidEmailInput(emailInput)) {
      setError('email', {
        type: 'custom',
        message: YupMessage.INVALID_EMAIL
      });
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const isPending = loadingDetail || pendingCheckAbn || pendingCheckingEmail;
  const disabledCreate =
    isPending || Object.keys(errors).length > 0 || !isValidEmailInput(emailInput);
  const isDisabledEmailField = viewMode || (editMode && !!userDetail?.verified_at);

  return (
    <>
      <form id="add-update-company" onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <Card.Header className="d-flex justify-center align-center bg-primary-subtle">
            <h5 className="mb-0 fs-0 mr-auto">Details</h5>
          </Card.Header>
          <Card.Body className="border-top fs--1 position-relative">
            {loadingDetail && <LoadingOverlay />}
            <Row>
              <Col xs={12} lg={6}>
                <WizardInpuHorizontal
                  required
                  label="First Name"
                  name="first_name"
                  errors={errors}
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  placeholder="First Name"
                  {...commonConfig({
                    register: register('first_name'),
                    disabled: viewMode
                  })}
                />
                <WizardInpuHorizontal
                  required
                  label="Last Name"
                  name="last_name"
                  errors={errors}
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  placeholder="Last Name"
                  {...commonConfig({
                    register: register('last_name'),
                    disabled: viewMode
                  })}
                />
                <WizardInpuHorizontal
                  required
                  type="select"
                  watch={watch}
                  options={LIST_ROLES.filter(item => [1, 2].includes(item.value))}
                  label="Role"
                  name="role"
                  errors={errors}
                  placeholder="Role"
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    disabled: viewMode,
                    register: register('role')
                  })}
                />
                <WizardInpuHorizontal
                  type="number"
                  label="Tax Number"
                  name="tax_number"
                  errors={errors}
                  placeholder="Tax Number"
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    register: register('tax_number'),
                    disabled: viewMode
                  })}
                />
                <WizardInpuHorizontal
                  name="date_of_birth"
                  type="date"
                  label="Date Of Birth"
                  control={control}
                  watch={watch}
                  errors={errors}
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    disabled: viewMode,
                    register: register('date_of_birth')
                  })}
                />
                <WizardInpuHorizontal
                  type="select"
                  watch={watch}
                  options={getListOptions(listCountry)}
                  label="Country"
                  name="address.country_id"
                  errors={errors}
                  placeholder="Add Country"
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    register: register('address.country_id', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode
                  })}
                />
                <WizardInpuHorizontal
                  type="select"
                  watch={watch}
                  options={getListOptions(listState)}
                  label="State"
                  name="address.state_id"
                  errors={errors}
                  placeholder="Add State"
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    register: register('address.state_id', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode
                  })}
                />
              </Col>
              <Col xs={12} lg={6}>
                <WizardInpuHorizontal
                  label="Address"
                  name="address.detail_address"
                  errors={errors}
                  placeholder="Address"
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    register: register('address.detail_address'),
                    disabled: viewMode
                  })}
                />
                <WizardInpuHorizontal
                  label="Locality"
                  name="address.city"
                  errors={errors}
                  placeholder="Locality"
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    register: register('address.city'),
                    disabled: viewMode
                  })}
                />

                <WizardInpuHorizontal
                  label="Post code/Zipcode"
                  name="address.postcode"
                  errors={errors}
                  placeholder="Post code/Zipcode"
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    register: register('address.postcode'),
                    disabled: viewMode
                  })}
                />
                <WizardInpuHorizontal
                  label="Phone number"
                  name="contact_number"
                  errors={errors}
                  placeholder="Phone number"
                  type="phoneInput"
                  control={control}
                  defaultCountry={userData?.address?.countryCode}
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    disabled: viewMode
                  })}
                />
                <WizardInpuHorizontal
                  label="Email"
                  required
                  name="email"
                  errors={errors}
                  placeholder="Email"
                  formGroupProps={{
                    className: 'mb-1 g-0'
                  }}
                  {...commonConfig({
                    formControlProps: {
                      onBlur: onValidateEmail
                    },
                    register: register('email'),
                    disabled: isDisabledEmailField
                  })}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </form>

      <div className="d-flex ml-auto px-4 py-2 position-absolute w-100 bottom-0 start-0 justify-content-end bg-body-quaternary border-top">
        <FooterActionBtn
          id={userId}
          onClose={onCloseModal}
          loading={loading}
          onReset={onReset}
          viewMode={viewMode}
          disabled={disabledCreate}
          viewDetailMode={viewDetailMode}
          onEdit={onEnableEditForm}
          formName="add-update-company"
        />
      </div>
    </>
  );
}
