import { YupMessage } from 'constants/index';
import { dateOfBirthSchema, isContactNumber, isEmail } from 'helpers/utils';
import yup from 'validation.yup';

const transformFn = value => (Number.isNaN(value) ? null : value);

export const addOrUpdateDriverSchema = yup
  .object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.lazy(value => {
      if (!value) return yup.string().required();
      return yup.string().matches(isEmail, YupMessage.INVALID_EMAIL);
    }),
    tax_number: yup.string(),
    date_of_birth: yup.string().concat(dateOfBirthSchema),
    contact_number: yup.string().matches(isContactNumber, YupMessage.INVALID_FORMAT).required(),
    group_id: yup.number().transform(transformFn).required(),
    company_id: yup.number().transform(transformFn).required(),
    dc_number: yup.string().required(),
    address: yup.object().shape({
      country_id: yup.number().transform(transformFn).required().min(1, YupMessage.REQUIRED)
    })
  })
  .required();

export const formLayout = {
  label: {
    xs: 12,
    sm: 5,
    lg: 5
  },
  col: {
    xs: 12,
    sm: 7,
    lg: 7
  }
};
