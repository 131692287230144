import { removeEmptyProperties } from 'helpers/utils';

export const normalizeData = data => {
  const { state, country, group, company, fleet, status, ...restData } = data;

  const newData = {
    ...restData,
    state_id: state?.value,
    country_id: country?.value,
    group_id: group?.value,
    fleet_id: fleet?.value,
    company_id: company?.value,
    status: status ? undefined : 1
  };

  return removeEmptyProperties(newData);
};
