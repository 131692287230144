import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import logoDarkMode from 'assets/img/cabfare-logo-white.svg';
import logo from 'assets/img/cabfare-logo.svg';

const LogoComponent = ({ className, width, at }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  return (
    <div
      className={classNames(
        'd-flex',
        {
          'align-items-center py-3': at === 'navbar-vertical',
          'align-items-center': at === 'navbar-top',
          'flex-center fw-bolder fs-5 mb-4': at === 'auth'
        },
        className
      )}
    >
      <img src={isDark ? logoDarkMode : logo} alt="Logo" width={width} />
    </div>
  );
};

const Logo = ({ at, width, className, to = '/', ...rest }) => {
  if (to === '#') return <LogoComponent width={width} className={className} at={at} />;
  return (
    <Link
      to={to}
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <LogoComponent width={width} className={className} at={at} />
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 150 };

export default Logo;
