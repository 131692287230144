import { createContext, useContext } from 'react';

export const CompanyContext = createContext({
  data: {},
  companyId: null,
  onCloseModal: () => {},
  fetchDetailCompany: () => {},
  fetchListCompany: () => {},
  loadingDetailCompany: false,
  onCreateOrEditCompanySuccess: () => {}
});

CompanyContext.displayName = 'CompanyContext';

export const useCompanyContext = () => useContext(CompanyContext);
