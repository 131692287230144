import React, { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
  AdvanceTable,
  AdvanceTableWrapper,
  AdvanceTableHeader,
  AdvanceTablePagination
} from 'components/common/advance-table';
import { DATA_PER_PAGE, ROLES, TABLE_COLUMN_FILTER_KEY, COMPANY } from 'constants/index';
import useFetchCompany from './services/useFetchCompany';
import useGetTableColumns from './services/useGetTableColumn';
import AddUpdateCompany from './AddUpdateCompany';
import ScreenSearchPanel from 'components/common/search/ScreenSearchPanel';
import { useAuth } from 'hooks/useAuth';
import { CompanyContext } from './useCompanyContext';

export default function Company() {
  const { viewAsRole } = useAuth();
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const { availableColumns, visibleColumnIds, defaultVisibleColumnIds } = useGetTableColumns(
    TABLE_COLUMN_FILTER_KEY.COMPANY
  );
  const [modalInfo, setModalInfo] = useState({ companyId: null, show: false });
  const { companyId, show } = modalInfo;

  const {
    loading,
    listCompany,
    loadingDetailCompany,
    detailCompany,
    fetchListCompany,
    fetchDetailCompany,
    resetCompanyDetail
  } = useFetchCompany();

  const { items = [], total_count: totalCount = 0 } = listCompany || {};

  const onAddNew = () => {
    setModalInfo({ companyId: null, show: true });
  };

  const onClickRow = row => {
    fetchDetailCompany(row.id);
    setModalInfo({ companyId: row.id, show: true });
  };

  const onCloseModal = () => {
    resetCompanyDetail();
    setModalInfo({ companyId: null, show: false });
  };

  const onCreateOrEditCompanySuccess = () => {
    onCloseModal();
    fetchListCompany({
      pageIndex: tablePageIndex
    });
  };

  const onChangePage = page => {
    setTablePageIndex(page);
  };

  const providerValues = useMemo(
    () => ({
      companyId,
      onCloseModal,
      fetchListCompany,
      fetchDetailCompany,
      loadingDetailCompany,
      onCreateOrEditCompanySuccess,
      data: companyId ? detailCompany : {}
    }),
    [
      companyId,
      onCloseModal,
      detailCompany,
      fetchListCompany,
      fetchDetailCompany,
      loadingDetailCompany,
      onCreateOrEditCompanySuccess
    ]
  );

  return (
    <CompanyContext.Provider value={providerValues}>
      <AdvanceTableWrapper
        sortable
        pagination
        data={items}
        perPage={DATA_PER_PAGE}
        loading={loading}
        rowsPerPageSelection
        onChangePage={onChangePage}
        totalCount={totalCount}
        columns={availableColumns}
        fetchData={fetchListCompany}
        visibleColumnIds={visibleColumnIds}
        defaultVisibleColumnIds={defaultVisibleColumnIds}
      >
        <Card className="mb-3">
          <Card.Header>
            <AdvanceTableHeader
              table
              canAddNew
              title="Companies"
              onAddNew={onAddNew}
              hasPermissionAdd={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP].includes(
                viewAsRole
              )}
              canFilter
              canFilterColumns
              filterKey={TABLE_COLUMN_FILTER_KEY.COMPANY}
            />
          </Card.Header>
          <Card.Body className="p-0 custom-table d-flex flex-wrap pb-md-3 position-relative">
            <div className="flex-1 d-flex flex-column flex-1 scrollbar visible-scrollbar rounded-end-lg">
              <AdvanceTable
                table
                onClickRow={onClickRow}
                headerClassName="bg-200 text-nowrap"
                rowClassName="align-middle white-space-nowrap cursor-pointer"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </div>
            <ScreenSearchPanel table pageKey={COMPANY} />
          </Card.Body>
        </Card>
      </AdvanceTableWrapper>
      {show && <AddUpdateCompany show={show} companyId={companyId} onCloseModal={onCloseModal} />}
    </CompanyContext.Provider>
  );
}
