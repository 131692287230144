import { useState, useCallback, useEffect } from 'react';
import commonServices from 'services/common.services';

export default function useGetCountry({ countryId } = {}) {
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [listState, setListState] = useState([]);
  const [listCountry, setListCountry] = useState([]);

  const fetchCountries = useCallback(async () => {
    try {
      setLoading(true);
      const data = await commonServices.listCountry();
      setListCountry(data.items);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchStatesByCountry = useCallback(async countryCode => {
    try {
      setLoadingState(true);
      const data = await commonServices.listStateByCountry(countryCode);
      setListState(data.items || []);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingState(false);
    }
  }, []);

  const resetListState = useCallback(() => {
    setListState([]);
  }, []);

  useEffect(() => {
    if (countryId) {
      fetchStatesByCountry(countryId);
    }
    if (isNaN(countryId)) {
      resetListState();
    }
  }, [countryId]);

  return {
    loading,
    loadingState,
    listCountry,
    listState,
    fetchCountries,
    resetListState,
    fetchStatesByCountry
  };
}
