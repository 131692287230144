import { YupMessage } from 'constants/index';
import {
  transformFn,
  percentageSchema,
  isContactNumber,
  userPrincipalSchema,
  twoDigitsValidationSchema,
  isEmail
} from 'helpers/utils';
import yup from 'validation.yup';

export const genCompanyFormSchema = isAssignExistUser =>
  yup
    .object({
      name: yup.string().required(),
      email: yup.lazy(value => {
        if (!value) return yup.string().default('').nullable();
        return yup.string().matches(isEmail, YupMessage.INVALID_EMAIL);
      }),
      tax_number: yup.string().required(),
      receipt_contact_info: yup.string().required(),
      contact_number: yup.lazy(value => {
        if (!value) return yup.string().default('').nullable();
        return yup.string().matches(isContactNumber, YupMessage.INVALID_FORMAT);
      }),
      address: yup.object().shape({
        postcode: yup.string().required(),
        city: yup.string().required(),
        country_id: yup.number().transform(transformFn).required(),
        detail_address: yup.string().required()
      }),
      timezone_id: yup.number().transform(transformFn).required(),
      group_id: yup.number().transform(transformFn).required(),
      company_comm: yup
        .number()
        .concat(percentageSchema)
        .concat(twoDigitsValidationSchema)
        .when('group_comm', (groupRate, schema) =>
          schema.max(parseFloat(groupRate, 10) || 0, YupMessage.NOT_GREATER_THAN_GROUP_RATE)
        )
        .required(),
      payment_point: yup.string().required(),
      ...(isAssignExistUser
        ? { existing_principal: yup.object().required() }
        : { user_principal: yup.object().concat(userPrincipalSchema) })
    })
    .required();
