import React, { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
  AdvanceTable,
  AdvanceTableWrapper,
  AdvanceTableHeader,
  AdvanceTablePagination
} from 'components/common/advance-table';
import useFetchUser from './services/useFetchUser';
import { DATA_PER_PAGE, ROLES } from 'constants/index';
import { userColumns } from './services/UserTableColumns';
import { useAuth } from 'hooks/useAuth';
import { UserContext } from './useUserContext';
import AddUpdateUser from './AddUpdateUser';

const Users = () => {
  const { viewAsRole } = useAuth();
  const columns = useMemo(() => userColumns, []);
  const {
    loading,
    userList,
    fetchUserList,
    loadingUserDetail,
    userDetail,
    fetchUserDetail,
    resetUserDetail
  } = useFetchUser();

  const [modalInfo, setModalInfo] = useState({ userId: null, show: false });
  const { userId, show } = modalInfo;
  const { items = [], total_count: totalCount = 0 } = userList || {};

  const onAddNew = () => {
    setModalInfo({ userId: null, show: true });
  };

  const onClickRow = row => {
    fetchUserDetail(row.id);
    setModalInfo({ userId: row.id, show: true });
  };

  const onCloseModal = () => {
    setModalInfo({ userId: null, show: false });
    resetUserDetail();
  };

  const providerValues = useMemo(
    () => ({
      userId,
      userDetail,
      onCloseModal,
      fetchUserList,
      loadingUserDetail
    }),
    [userId, userDetail, fetchUserList, onCloseModal, loadingUserDetail]
  );

  return (
    <>
      <AdvanceTableWrapper
        sortable
        pagination
        data={items}
        perPage={DATA_PER_PAGE}
        loading={loading}
        columns={columns}
        rowsPerPageSelection
        fetchData={fetchUserList}
        totalCount={totalCount}
      >
        <Card className="mb-3">
          <Card.Header>
            <AdvanceTableHeader
              table
              canAddNew
              onAddNew={onAddNew}
              canSearch
              title="Users"
              hasPermissionAdd={[ROLES.ADMIN].includes(viewAsRole)}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              onClickRow={onClickRow}
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap cursor-pointer"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      {show && (
        <UserContext.Provider value={providerValues}>
          <AddUpdateUser show={show} onCloseModal={onCloseModal} userId={userId} />
        </UserContext.Provider>
      )}
    </>
  );
};

export default Users;
