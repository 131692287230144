import { axiosClient } from './request';

const companyServices = {
  create(body) {
    return axiosClient.post('/companies', body);
  },
  list({
    pageSize = 20,
    pageIndex = 0,
    sortBy = [],
    globalFilter = '',
    dates = [null, null],
    moreFilterData = {}
  } = {}) {
    const _sortBy = sortBy.map(item => `${item.id}.${item.desc ? 'desc' : 'asc'}`).toString();
    return axiosClient.get('/companies', {
      params: {
        limit: pageSize,
        page: pageIndex + 1,
        search: globalFilter,
        sort_by: _sortBy,
        start_date: dates[0],
        end_date: dates[1],
        ...moreFilterData
      }
    });
  },
  detail(id) {
    return axiosClient.get(`/companies/${id}`);
  },
  updateCompany(id, body) {
    return axiosClient.put(`companies/${id}`, body);
  },
  listFleetByCompany(companyId) {
    return axiosClient.get(`companies/${companyId}/fleets`);
  },
  listCompanyByGroup(groupId) {
    return axiosClient.get(`groups/${groupId}/companies`);
  },
  deleteCompanySupervisor(userId, supervisorId) {
    return axiosClient.delete(`companies/${userId}/users/${supervisorId}`);
  },
  getCompanySupervisors(id) {
    return axiosClient.get(`/companies/${id}/supervisors`);
  },
  getListAvailableToSupervisor(id, params) {
    return axiosClient.get(`/companies/${id}/assign-users`, { params });
  },
  assignToComSupervisor(id, uid) {
    return axiosClient.post(`companies/${id}/users/${uid}`);
  },
  connectStripe(companyId) {
    return axiosClient.post(`companies/${companyId}/invite`);
  },
  getAvailablePrincipal(companyId, params) {
    return axiosClient.get(`companies/${companyId}/principals`, { params });
  }
};

export default companyServices;
