import React, { useState } from 'react';
import { Card, Form, Row, Col } from 'react-bootstrap';
import { useAuth } from 'hooks/useAuth';
import { toast } from 'react-toastify';
import {
  AdvanceTable,
  AdvanceTableWrapper,
  AdvanceTablePagination
} from 'components/common/advance-table';
import useFetchVehicle from 'pages/Drivers/services/useFetchVehicle';
import { DATA_PER_PAGE, ROLES } from 'constants/index';
import { vehicleColumnsConfig } from 'pages/Drivers/services/columnsConfig';
import LoadingButton from 'components/common/LoadingButton';
import vehicleServices from 'services/vehicle.services';

export default function Vehicle({ id }) {
  const { viewAsRole } = useAuth();
  const { vehicleList, loadingList, fetchListVehicle } = useFetchVehicle({ userId: id });
  const { items = [], total_count: totalCount = 0 } = vehicleList || {};
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [plateNumber, setPlateNumber] = useState(null);
  const VEHICLE_TYPE = 0;
  const OWNER_TYPE = 'D';

  const onAddPlateNumber = async () => {
    try {
      setLoadingSubmit(true);
      await vehicleServices.create({
        plate_number: plateNumber,
        owner_type: OWNER_TYPE,
        owner_id: id,
        type: VEHICLE_TYPE
      });
      fetchListVehicle({
        pageIndex: 0,
        pageSize: 100,
        userId: id
      });
      setPlateNumber('');
      toast.success('The vehicle was successfully created.');
    } catch (err) {
      if (err?.error?.type === 'CONFLICT_VEHICLE') {
        toast.error(err.error.message);
        return;
      }
      toast.error('The vehicle was created fail.');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onUpdateVehicle = async (rowValues, rowId) => {
    try {
      const { plate_number, status } = rowValues;
      await vehicleServices.update({ plate_number, status }, rowId);

      toast.success('The vehicle was successfully updated.');
    } catch (err) {
      console.error(err.message);
      toast.error('The vehicle was updated fail.');
    }
  };

  return (
    <>
      <div>
        <div className="mb-2">
          <Form.Group as={Row}>
            <Form.Label column xs={12} className="text-900">
              Plate Number
            </Form.Label>
            <Col xs={12}>
              <Form.Control
                onChange={e => setPlateNumber(e.target.value)}
                style={{ maxWidth: 250 }}
                value={plateNumber || ''}
                size="sm"
                className="fs--1"
                type="text"
                placeholder="Enter plate number"
              />
            </Col>
          </Form.Group>

          <LoadingButton
            className="mb-3 mt-2"
            isLoading={loadingSubmit}
            size="sm"
            name="Create vehicle"
            btnProps={{
              disabled: !plateNumber,
              onClick: onAddPlateNumber
            }}
          />
        </div>
      </div>

      <Card>
        <Card.Header className="d-flex justify-center align-center bg-primary-subtle">
          <h5 className="mb-0 fs-0 mr-auto">Vehicle</h5>
        </Card.Header>
        <Card.Body className="border-top fs--1 p-0">
          <AdvanceTableWrapper
            data={items.map((item, idx) => ({ ...item, no: idx + 1 }))}
            perPage={DATA_PER_PAGE}
            loading={loadingList}
            fetchData={fetchListVehicle}
            columns={vehicleColumnsConfig}
            totalCount={totalCount}
            editableRow
            onUpdateRow={onUpdateVehicle}
            canEditTableRow={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL].includes(viewAsRole)}
          >
            <Card>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
}
