import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';

export default function PayOutStatus({ status, minWidth = 'auto' }) {
  if (!status) return null;
  const payoutStatus = status?.toUpperCase();

  return (
    <SoftBadge
      pill
      minWidth={minWidth}
      bg={classNames({
        success: payoutStatus === 'PAID',
        secondary: payoutStatus === 'INCOMPLETE',
        warning: ['REFUNDED', 'REFUND-PENDING', 'REFUND-PARTIAL'].includes(payoutStatus),
        danger: payoutStatus === 'FAILED',
        info: payoutStatus === 'PENDING'
      })}
      className="d-inline-flex flex-center py-1"
    >
      <p className="mb-0">
        {payoutStatus === 'PAID' && 'Paid'}
        {payoutStatus === 'PENDING' && 'Pending'}
        {payoutStatus === 'INCOMPLETE' && 'Incomplete'}
        {payoutStatus === 'REFUND-PARTIAL' && 'Partial Refund'}
        {payoutStatus === 'REFUND-PENDING' && 'Refund Pending'}
        {payoutStatus === 'REFUNDED' && 'Refunded'}
        {payoutStatus === 'FAILED' && 'Failed'}
      </p>
      <FontAwesomeIcon
        icon={classNames({
          check: payoutStatus === 'PAID',
          clock: ['PENDING', 'REFUND-PENDING', 'REFUND-PARTIAL'].includes(payoutStatus),
          receipt: payoutStatus === 'REFUNDED',
          wrench: payoutStatus === 'INCOMPLETE',
          exclamation: payoutStatus === 'FAILED'
        })}
        transform="shrink-2"
        className="ms-1"
      />
    </SoftBadge>
  );
}

PayOutStatus.propTypes = {
  status: PropTypes.oneOf([
    'pending',
    'paid',
    'refund-pending',
    'refunded',
    'failed',
    'incomplete',
    'refund-partial'
  ])
};
