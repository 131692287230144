import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const CustomPhoneInputControl = forwardRef(
  ({ value, onChange, isInvalid, name, isDisabled, ...rest }, ref) => {
    return (
      <Form.Control
        ref={ref}
        value={value}
        name={name}
        isInvalid={isInvalid}
        onChange={onChange}
        {...rest}
        disabled={isDisabled}
      />
    );
  }
);

CustomPhoneInputControl.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool
};

export default CustomPhoneInputControl;
