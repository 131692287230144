import { useAuth } from 'hooks/useAuth';
import {
  columnsConfig,
  visibleColumnForAdmin,
  visibleColumnForDriver,
  availableColumnsForAdmin,
  availableColumnsForDriver,
  visibleColumnForCabfareIntenal,
  availableColumnsForCabfareIntenal
} from '../columnsConfig';

export default function useGetTableColumns() {
  const { viewAsRole, userSettings } = useAuth();
  const isAdmin = viewAsRole === 1;
  const isCabfareInternal = viewAsRole === 2;
  const selectedColumns = userSettings?.transactionSettings?.selectedColumns || [];

  if (isAdmin) {
    return {
      defaultVisibleColumnIds: visibleColumnForAdmin,
      visibleColumnIds: selectedColumns.length > 0 ? selectedColumns : visibleColumnForAdmin,
      availableColumns: columnsConfig.filter(item =>
        availableColumnsForAdmin.includes(item.accessor)
      )
    };
  }

  if (isCabfareInternal) {
    return {
      defaultVisibleColumnIds: visibleColumnForCabfareIntenal,
      visibleColumnIds:
        selectedColumns.length > 0 ? selectedColumns : visibleColumnForCabfareIntenal,
      availableColumns: columnsConfig.filter(item =>
        availableColumnsForCabfareIntenal.includes(item.accessor)
      )
    };
  }

  return {
    defaultVisibleColumnIds: visibleColumnForDriver,
    visibleColumnIds: selectedColumns.length > 0 ? selectedColumns : visibleColumnForDriver,
    availableColumns: columnsConfig.filter(item =>
      availableColumnsForDriver.includes(item.accessor)
    )
  };
}
