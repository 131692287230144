import React from 'react';
import { Offcanvas, Tab, Tabs } from 'react-bootstrap';
import { BasicInfo, GeneralInfo, UserNotes } from './components';
import NoDataBlock from 'components/common/NoDataBlock';

export default function AddUpdateUser({ show, userId, onCloseModal }) {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={onCloseModal}
        placement={'end'}
        backdropClassName="custom-drawer"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <span className="me-1">{userId ? 'Viewing User' : 'Creating User'}</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {userId && <BasicInfo />}
          <Tabs>
            <Tab
              eventKey="general"
              title="General"
              tabClassName="fs-1"
              className="border-bottom border-x p-4"
            >
              <GeneralInfo />
            </Tab>
            <Tab
              eventKey="notes"
              title="Notes"
              tabClassName="fs-1"
              className="border-bottom border-x p-3"
            >
              <UserNotes />
            </Tab>
            <Tab
              eventKey="history"
              title="History"
              tabClassName="fs-1"
              className="border-bottom border-x p-3"
            >
              <NoDataBlock />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
