import { DATA_PER_PAGE } from 'constants';
import { axiosClient } from './request';

const vehicleServices = {
  list({ userId, pageIndex = 0, pageSize = DATA_PER_PAGE } = {}) {
    return axiosClient.get(`/drivers/${userId}/vehicles`, {
      params: {
        limit: pageSize,
        page: pageIndex + 1
      }
    });
  },
  create(body) {
    return axiosClient.post('/vehicles', body);
  },
  update(body, id) {
    return axiosClient.put(`/vehicles/${id}`, body);
  }
};

export default vehicleServices;
