import { useState, useCallback } from 'react';
import userServices from 'services/user.services';
import { camelCaseObjNormalize } from 'helpers/utils';

export default function useFetchUser() {
  const [userList, setUserList] = useState({});
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [loadingUserDetail, setLoadingUserDetail] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [loadingUserProfile, setLoadingUserProfile] = useState(false);

  const fetchUserList = useCallback(async body => {
    try {
      setLoading(true);
      const data = await userServices.listUser(body);
      setUserList(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUserDetail = useCallback(async body => {
    try {
      setLoadingUserDetail(true);
      const data = await userServices.getUserDetail(body);
      setUserDetail(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingUserDetail(false);
    }
  }, []);

  const fetchUserProfile = useCallback(async () => {
    try {
      setLoadingUserProfile(true);
      const data = await userServices.userProfile();
      setUserProfile(camelCaseObjNormalize(data));
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingUserProfile(false);
    }
  }, []);

  const resetUserDetail = () => {
    setUserDetail({});
  };

  return {
    userList,
    loading,
    userProfile,
    resetUserDetail,
    loadingUserProfile,
    fetchUserProfile,
    userDetail,
    fetchUserList,
    fetchUserDetail,
    loadingUserDetail
  };
}
