import { createContext, useContext } from 'react';

export const GroupContext = createContext({
  data: {},
  groupId: null,
  loadingDetail: false,
  onCloseModal: () => {},
  fetchListGroup: () => {},
  fetchGroupDetail: () => {},
  onAddOrUpdateGroupSuccess: () => {}
});
GroupContext.displayName = 'GroupContext';

export const useGroupContext = () => useContext(GroupContext);
