import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import useQuery from 'hooks/useQuery';
import authServices from 'services/auth.services';
import {
  PATH_STRIPE_CONNECT_ERROR,
  PATH_STRIPE_RESEND_SUCESS,
  PATH_STRIPE_RESEND_FAIL
} from 'routes/route.path';
import Flex from 'components/common/Flex';

export default function StripeConnect() {
  const navigate = useNavigate();
  const { query } = useQuery();

  const verifyStripeConnect = async body => {
    try {
      const { data } = await authServices.verifyStripeConnect({
        ...body,
        id: parseInt(body?.id, 10)
      });
      if (data?.platform === 'website') {
        const url = data.code === 200 ? PATH_STRIPE_RESEND_SUCESS : PATH_STRIPE_RESEND_FAIL;
        navigate(url, { replace: true });
      } else {
        if (data.code !== 200) {
          navigate(PATH_STRIPE_CONNECT_ERROR, { replace: true });
        }

        if (data?.url) {
          window.location.href = data.url;
        }
      }
    } catch (err) {
      navigate(PATH_STRIPE_CONNECT_ERROR, { replace: true });
    }
  };

  useEffect(() => {
    if (query?.id) {
      verifyStripeConnect(query);
    }
  }, [query?.email]);

  return (
    <Flex className="w-100 min-vh-100" justifyContent="center" alignItems="center">
      <Spinner animation="grow" />
    </Flex>
  );
}
