import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserStatusSelection = ({
  id,
  loading,
  canModify,
  userStatus,
  statusConfig,
  minWidth = 80,
  btnRevealClass,
  onChangeStatus
}) => {
  if (!id) return null;

  return (
    <Dropdown
      align="start"
      className={classNames({ 'btn-reveal-trigger ': !canModify || loading })}
    >
      <Dropdown.Toggle
        size="sm"
        {...(!canModify
          ? {
              onClick: e => {
                e.preventDefault();
              }
            }
          : {})}
        color="white"
        style={{ minWidth }}
        data-boundary="viewport"
        variant={statusConfig[userStatus]?.type}
        className={classNames('fs--075', {
          [btnRevealClass]: btnRevealClass
        })}
      >
        {statusConfig[userStatus]?.label}
        {loading && <FontAwesomeIcon icon="spinner" className="fs--2 fa-spin ms-2" />}
      </Dropdown.Toggle>
      <Dropdown.Menu className="border py-0" style={{ minWidth }}>
        <div className="py-2">
          {Object.keys(statusConfig).map(configId => (
            <Dropdown.Item key={configId} onClick={() => onChangeStatus(configId)}>
              {statusConfig[configId].label}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

UserStatusSelection.propTypes = {
  minWidth: PropTypes.number,
  canModify: PropTypes.bool,
  btnRevealClass: PropTypes.string
};

export default UserStatusSelection;
