import { axiosClient } from './request';

const driverServices = {
  list({
    pageSize = 20,
    pageIndex = 0,
    globalFilter = '',
    fleet_id,
    company_id,
    group_id,
    moreFilterData = {}
  } = {}) {
    return axiosClient.get('/drivers', {
      params: {
        fleet_id,
        company_id,
        group_id,
        limit: pageSize,
        page: pageIndex + 1,
        search: globalFilter,
        ...moreFilterData
      }
    });
  },
  create(body) {
    return axiosClient.post('/drivers', body);
  },
  detail({ driver_id, ...rest }) {
    return axiosClient.get(`/drivers/${driver_id}`, {
      params: rest
    });
  },
  updateDriver(id, body) {
    return axiosClient.put(`/drivers/${id}`, body);
  }
};

export default driverServices;
