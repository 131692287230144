import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { getValueObj } from 'helpers/utils';
import { FORMAT_DATE_TIME } from 'constants';

export default function DateOfBirth({ onChange, errors, value, name, disabled }) {
  const errorMsg = getValueObj(errors, name.split('.'));
  const isError = Boolean(errorMsg?.message);
  const [date, setDate] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  useEffect(() => {
    const newDate = String(date).length === 1 ? `0${date}` : date;
    const newMonth = String(month).length === 1 ? `0${month}` : month;
    if (!newDate && !newMonth && !year) {
      onChange(undefined);
      return;
    }
    onChange(`${newDate}/${newMonth}/${year}`);
  }, [date, month, year]);

  useEffect(() => {
    const currentDate = dayjs(value, FORMAT_DATE_TIME, true);
    if (currentDate.isValid()) {
      setDate(currentDate.get('date'));
      setMonth(currentDate.get('month') + 1);
      setYear(currentDate.get('year'));
    }
  }, [value]);

  const onlyNumbericInput = e => {
    if (isNaN(e.key) && !['Backspace', 'Tab'].includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Flex className={classNames('dob', { 'is-invalid': isError })}>
        <div className="me-1">
          <input
            required
            maxLength={2}
            placeholder="Date"
            disabled={disabled}
            value={date}
            onKeyDown={onlyNumbericInput}
            onChange={e => {
              setDate(e.target.value);
            }}
            className={classNames('form-control form-control-sm', { 'is-invalid': isError })}
          />
        </div>
        <div className="me-1">
          <input
            required
            maxLength={2}
            placeholder="Month"
            disabled={disabled}
            value={month}
            onKeyDown={onlyNumbericInput}
            onChange={e => {
              setMonth(e.target.value);
            }}
            className={classNames('form-control form-control-sm', { 'is-invalid': isError })}
          />
        </div>
        <div>
          <input
            required
            maxLength={4}
            placeholder="Year"
            disabled={disabled}
            value={year}
            onKeyDown={onlyNumbericInput}
            onChange={e => {
              setYear(e.target.value);
            }}
            className={classNames('form-control form-control-sm', { 'is-invalid': isError })}
          />
        </div>
      </Flex>
      <Form.Control.Feedback type="invalid">{errorMsg?.message}</Form.Control.Feedback>
    </>
  );
}
