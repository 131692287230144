import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';

export default function VehicleStatus({ status = 0 }) {
  return (
    <SoftBadge
      pill
      bg={classNames({
        success: status === 1,
        warning: status === 2,
        danger: status === 0
      })}
      className="d-inline-flex flex-center"
    >
      <p className="mb-0">
        {status === 1 && 'Active'}
        {status === 0 && 'Inactive'}
        {status === 2 && 'Busy'}
      </p>
      <FontAwesomeIcon
        icon={classNames({
          check: status === 1,
          clock: status === 2,
          ban: status === 0
        })}
        transform="shrink-2"
        className="ms-1"
      />
    </SoftBadge>
  );
}

VehicleStatus.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};
