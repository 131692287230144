import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { useAuthStore } from 'stores/auth.store';
import { PATH_LOGIN } from 'routes/route.path';
import { onSignOutFirebaseSession } from 'services/firebase.service';
import useFetchUser from 'pages/Users/services/useFetchUser';
import UserDetails from 'components/pages/UserDetails';

const ProfileDropdown = () => {
  const { fetchUserList, userProfile, fetchUserProfile, loadingUserProfile } = useFetchUser();
  const { clearStore, userData } = useAuthStore();
  const [showDrawer, setShowDrawer] = useState(false);
  const navigate = useNavigate();
  const { avatar, name = '' } = userData || {};

  const onLogOut = () => {
    clearStore(() => navigate(PATH_LOGIN));
    onSignOutFirebaseSession();
  };

  const onShowProfile = () => {
    setShowDrawer(true);
    fetchUserProfile();
  };

  const onCloseDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <>
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link justify-content-center align-items-center d-flex"
        >
          {avatar ? <Avatar src={avatar} /> : <Avatar size="xl" name={name} className="me-2" />}
          <span className="ps-2 text-truncate max-w-100 text-900">
            {(name || '').split(' ')[0] || 'Profile'}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Item as="span" onClick={onShowProfile} className="cursor-pointer">
              Profile settings
            </Dropdown.Item>
            <Dropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none text-900 d-block"
              href={process.env.REACT_APP_SUPPORT_REQUEST_LINK}
            >
              Help
            </Dropdown.Item>
            <Dropdown.Item onClick={onLogOut}>Logout</Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {showDrawer && (
        <UserDetails
          show={showDrawer}
          onHide={onCloseDrawer}
          data={userProfile}
          loading={loadingUserProfile}
          fetchData={fetchUserProfile}
          fetchUserList={fetchUserList}
        />
      )}
    </>
  );
};

export default ProfileDropdown;
