import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import authServices from 'services/auth.services';
// import { deleteUser } from 'firebase/auth';
// import { authFirebase } from 'firebase.config';
import { ToastMessage } from 'constants/index';
import { PATH_HOME } from 'routes/route.path';
import { setAuthStore } from 'stores/auth.store';
import LoginWithApple from 'components/common/button/LoginWithApple';
import LoginWithGG from 'components/common/button/LoginWithGG';
import { camelCaseObjNormalize } from 'helpers/utils';
import userServices from 'services/user.services';

const SocialAuthButtons = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);

  const onLoginWithToken = async (accessToken, type) => {
    try {
      setLoading(type);
      const responseData = await authServices.socialLogin(accessToken);
      const { access_token } = responseData.data;
      // REACT_APP_AUTH_SESSION_TIME = 60 * 60 * 1000;
      const sessionTime = process.env.REACT_APP_AUTH_SESSION_TIME || 3600000;
      const timeLogEnd = Date.now() + Number(sessionTime);
      const profileData = await userServices.userProfileByToken(access_token);
      const normalizeUserData = camelCaseObjNormalize(profileData?.data || {});
      const { name, email } = normalizeUserData;
      setAuthStore({
        accessToken: access_token,
        userData: normalizeUserData,
        timeLogEnd
      });

      toast.success(`Logged in as ${name || email}`, {
        theme: 'colored'
      });
      navigate(PATH_HOME);
    } catch (err) {
      console.error(err.message);
      toast.error(ToastMessage.USER_NOT_REGISTER);
      // const currentUser = authFirebase.currentUser;
      // await deleteUser(currentUser);
    } finally {
      setLoading(null);
    }
  };

  return (
    <Form.Group className="mb-0">
      <Row className="g-2 g-xl-0">
        <Col xs={12} xl={6}>
          <LoginWithGG onLoginWithToken={onLoginWithToken} loading={loading} />
        </Col>
        <Col xs={12} xl={6}>
          <LoginWithApple onLoginWithToken={onLoginWithToken} loading={loading} />
        </Col>
      </Row>
    </Form.Group>
  );
};

export default SocialAuthButtons;
