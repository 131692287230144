import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export default function StripeResendSuccess() {
  return (
    <div className="text-center">
      <FontAwesomeIcon icon={faCheckCircle} className="me-2" size="3x" color="#00d27a" />
      <p className="mt-4 text-800 font-sans-serif fw-semi-bold mx-4">
        Please return to the email from CabFare and request a new Stripe connection link. Thank you.
      </p>
    </div>
  );
}
