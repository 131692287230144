import React, { createContext, useContext } from 'react';

const UserProfileContext = createContext({});

export const UserProfileProvider = ({ value, children }) => {
  return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
};

export const useUserDataProfile = () => {
  const context = useContext(UserProfileContext);
  if (!context) {
    throw new Error('useUserData must be used within a UserProfileProvider');
  }

  return context;
};
