import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const query = useMemo(() => Object.fromEntries(searchParams.entries()), [search]);

  return {
    query,
    search
  };
}
