import React from 'react';
import { Card, Table, Badge } from 'react-bootstrap';
import dayjs from 'dayjs';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FORMAT_DATE_TIME_II } from 'constants/index';
import Skeleton from 'components/common/Skeleton';
import Flex from 'components/common/Flex';
import { useUserContext } from 'pages/Users/useUserContext';

export default function BasicInfo() {
  const { userDetail, loadingUserDetail } = useUserContext();
  const { name, status, created_at: createdAt, updated_at: updatedAt } = userDetail;
  return (
    <PageHeader titleTag="h5" className="mb-3" classNameBody="p-2" col={{ xs: 12 }}>
      <Skeleton loading={loadingUserDetail} xs={3}>
        <Flex alignItems="center">
          <h5 className="mb-0">{name}</h5>
        </Flex>
      </Skeleton>
      <div className="d-flex justify-content-between flex-wrap">
        <Skeleton loading={loadingUserDetail}>
          <div className="mt-2">
            <b className="me-2">Status: </b>
            <Badge bg={status === 1 ? 'success' : 'danger'}>
              {status === 1 ? 'Active' : 'Inactive'}
              <FontAwesomeIcon icon="check" className="ms-1" transform="shrink-2" />
            </Badge>
          </div>
        </Skeleton>
        <div style={{ width: 240 }}>
          <Card>
            <Card.Header className="p-2">
              <b>History</b>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive fs--1">
                <Table size="sm" className="border-bottom" bordered>
                  <tbody>
                    <Skeleton loading={loadingUserDetail} as="tr" childAs="td">
                      <tr>
                        <td className="bg-100 w-30 text-start">Created</td>
                        <td>{dayjs(createdAt).format(FORMAT_DATE_TIME_II)}</td>
                      </tr>
                    </Skeleton>
                    <Skeleton loading={loadingUserDetail} as="tr" childAs="td">
                      <tr>
                        <td className="bg-100 w-30 text-start">Updated</td>
                        <td>{dayjs(updatedAt).format(FORMAT_DATE_TIME_II)}</td>
                      </tr>
                    </Skeleton>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </PageHeader>
  );
}
