import React, { useState, useEffect } from 'react';
import { Card, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import PageHeader from 'components/common/PageHeader';
import { useAuth } from 'hooks/useAuth';
import { FORMAT_DATE_TIME_II, ROLES } from 'constants/index';
import Skeleton from 'components/common/Skeleton';
import Flex from 'components/common/Flex';
import AuthenticationWrapper from 'components/authentication/AuthenticationWrapper';
import IconButton from 'components/common/IconButton';
import userServices from 'services/user.services';
import { userStatusConfig } from 'constants/index';
import driverServices from 'services/driver.services';
import { UserStatusSelection } from 'components/common/status';
import { useDriversContext } from '../../DriversContext';

export default function BasicInfo() {
  const { data, loadingDetail, onUpdateStatusSuccess } = useDriversContext();
  const [userStatus, setUserStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const {
    first_name: firstName,
    last_name: lastName,
    created_at: createdAt,
    updated_at: updatedAt,
    fleet_id,
    company_id,
    id: driverId,
    status
  } = data;

  useEffect(() => {
    setUserStatus(status);
  }, [status]);

  const { viewAsRole } = useAuth();

  const onResendMail = async () => {
    try {
      setLoading(true);
      await userServices.resendValidationEmail(data.id);
      toast.success('The email has been successfully resent.');
    } catch (err) {
      console.error(err.message);
      toast.success('Could not resend the email. Please try later');
    } finally {
      setLoading(false);
    }
  };

  const onChangeStatus = async statusId => {
    try {
      setLoadingStatus(true);
      setUserStatus(statusId);
      await driverServices.updateDriver(driverId, { ...data, status: Number(statusId) });
      toast.success('The driver status was successfully updated.');
      onUpdateStatusSuccess({
        driver_id: driverId,
        fleet_id,
        company_id
      });
    } catch (err) {
      toast.error('Could not update the Driver status. Please try later');
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <PageHeader titleTag="h5" className="mb-3" classNameBody="p-2" col={{ xs: 12 }}>
      <Skeleton loading={loadingDetail} xs={3}>
        <h5>
          {firstName} {lastName}
        </h5>
      </Skeleton>
      <div className="d-flex justify-content-between flex-wrap">
        <Skeleton loading={loadingDetail} xs={8}>
          <div className="mt-2 d-flex flex-column gap-2 mb-2">
            <Flex alignItems="center">
              <b className="me-2">Status </b>
              <UserStatusSelection
                id={driverId}
                userStatus={userStatus}
                loading={loadingStatus}
                onChangeStatus={onChangeStatus}
                statusConfig={userStatusConfig}
                canModify={viewAsRole !== ROLES.DRIVER}
              />
            </Flex>
            <AuthenticationWrapper
              viewAsRole={viewAsRole}
              permissions={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL]}
            >
              {!data.status && (
                <IconButton
                  size="sm"
                  className="my-3"
                  icon="share"
                  loading={loading}
                  transform="shrink-3"
                  variant="primary"
                  onClick={onResendMail}
                >
                  <span className="ms-1">Resend Mail</span>
                </IconButton>
              )}
            </AuthenticationWrapper>
          </div>
        </Skeleton>
        <div style={{ width: 240 }}>
          <Card>
            <Card.Header className="p-2">
              <b>History</b>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive fs--1">
                <Table size="sm" className="border-bottom" bordered>
                  <tbody>
                    <Skeleton loading={loadingDetail} as="tr" childAs="td">
                      <tr>
                        <td className="bg-100 w-30 text-start">Created</td>
                        <td>{dayjs(createdAt).format(FORMAT_DATE_TIME_II)}</td>
                      </tr>
                    </Skeleton>
                    <Skeleton loading={loadingDetail} as="tr" childAs="td">
                      <tr>
                        <td className="bg-100 w-30 text-start">Updated</td>
                        <td>{dayjs(updatedAt).format(FORMAT_DATE_TIME_II)}</td>
                      </tr>
                    </Skeleton>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </PageHeader>
  );
}
