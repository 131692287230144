import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import yup from 'validation.yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'hooks/useAuth';
import { WizardInput } from 'components/wizard';
import { setAuthStore } from 'stores/auth.store';
import ActionsBtn from '../button/ActionsBtn';
import userServices from 'services/user.services';
import { useUserDataProfile } from './UserProfileProvider';
import Skeleton from '../Skeleton';

const schema = yup
  .object({
    note: yup.string().max(255)
  })
  .required();

const UserNote = () => {
  const { notes, id, refetchData, status, loading: loadingInformation } = useUserDataProfile();
  const { userData, viewAsRole } = useAuth();
  const isActiveUser = status === 1;
  const isLoggedInUser = userData.id === id;
  const hasPermission = viewAsRole < 6 && isActiveUser;
  const [viewMode, setviewMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const {
    register,
    trigger,
    getValues,
    resetField,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    values: {
      notes
    }
  });

  useEffect(() => {
    if (notes) {
      setData(notes);
    }
  }, [notes]);

  const onUpdate = async formValue => {
    try {
      setLoading(true);
      await userServices.updateUser(id, formValue);
      setviewMode(true);
      setData(formValue.notes);
      if (isLoggedInUser) {
        setAuthStore({
          userData: {
            ...userData,
            notes: formValue.notes
          }
        });
      }
      refetchData(id);
      toast.success('The information was successfully updated.', {
        theme: 'colored'
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onEdit = () => {
    if (viewMode) {
      setviewMode(false);
    } else {
      trigger();
      const formValue = getValues();
      const isValid = schema.isValidSync(formValue);
      if (isValid) {
        onUpdate(formValue);
      }
    }
  };

  const onReset = () => {
    resetField('notes', data || '');
    setviewMode(true);
  };

  return (
    <Card className="mb-3 align-self-stretch flex-1">
      <>
        <Card.Header>
          <h5 className="mb-0">Note</h5>
        </Card.Header>
        <Card.Body className="bg-body-tertiary border-top">
          <Skeleton loading={loadingInformation} rows={3}>
            {viewMode ? (
              <p>{data}</p>
            ) : (
              <WizardInput
                type="textarea"
                register={register}
                errors={errors}
                label=""
                name="notes"
                placeholder="Add your notes..."
                formControlProps={{
                  ...register('notes')
                }}
              />
            )}
          </Skeleton>
        </Card.Body>
        <Card.Footer className="border-top text-end">
          {hasPermission && (
            <ActionsBtn viewMode={viewMode} onEdit={onEdit} loading={loading} onCancel={onReset} />
          )}
        </Card.Footer>
      </>
    </Card>
  );
};

export default UserNote;
