import React, { memo, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { preconfigDateList } from './config';

const PreconfigDate = ({ onChange, value, endDate }) => {
  const [isIncludeToday, setIsIncludeToday] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState({});
  const [optionValue, setOptionValue] = useState('fixed');

  const onSelectConfig = e => {
    const config = preconfigDateList.find(item => item.id === e.target.value);
    const { getDate } = config;
    setSelectedConfig(config);
    setIsIncludeToday(false);
    setOptionValue(e.target.value);
    onChange(getDate(), e.target.value);
  };

  const onCheckIncludeToday = e => {
    const isIncludeToday = e.target.checked;
    const { getDate, getDateIncludeToday } = selectedConfig;
    const updatedDate = isIncludeToday ? getDateIncludeToday() : getDate();
    setIsIncludeToday(isIncludeToday);

    onChange(updatedDate, optionValue);
  };

  useEffect(() => {
    if (value) {
      const config = preconfigDateList.find(item => item.id === value) || {};
      setSelectedConfig(config);
      setOptionValue(value);
      const today = new Date();
      if (endDate && endDate?.toDateString() === today.toDateString() && config.includeToday) {
        setIsIncludeToday(true);
      }
    }
  }, [value, endDate]);

  return (
    <>
      <Form.Select
        size="sm"
        aria-label="Auto date range"
        onChange={onSelectConfig}
        value={optionValue}
      >
        {preconfigDateList.map(item => (
          <option value={item.id} key={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
      {selectedConfig.includeToday && (
        <Form.Check
          checked={isIncludeToday}
          onChange={onCheckIncludeToday}
          type="checkbox"
          className="form-check fs-0 mb-0 w-fit-content mt-1"
          name="includeToday"
          label="Include today"
          id="includeToday"
        />
      )}
    </>
  );
};

export default memo(PreconfigDate);
