import React, { useEffect, useState, useRef } from 'react';
import Flex from 'components/common/Flex';
import { useAuth } from 'hooks/useAuth';
import IconButton from 'components/common/IconButton';
import transactionServices from 'services/transaction.service';
import { camelCaseObjNormalize } from 'helpers/utils';
import { ROLES } from 'constants/index';
import BizIntelItems from './BizIntelItems';
import { DOWN, MONEY, UP, UPCOMINGVALUE } from './constants';

export default function BIReporting({ globalFilter }) {
  const initStatRef = useRef(true);
  const [stat, setStat] = useState({});
  const [loading, setLoading] = useState(false);
  const { rangeTime, dates, moreFilterData } = globalFilter;
  const { viewAsRole } = useAuth();

  const fetchStatistic = async () => {
    try {
      setLoading(true);
      const stat = await transactionServices.getStatistic({
        start_time: dates[0],
        end_time: dates[1],
        range_time: rangeTime,
        ...moreFilterData
      });
      setStat(camelCaseObjNormalize(stat));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatistic();
  }, []);

  const onSyncStatistic = () => {
    fetchStatistic();
    if (initStatRef.current) initStatRef.current = false;
  };

  const className = 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3';

  return (
    <Flex className="justify-content-center align-items-center align-items-md-stretch flex-wrap justify-content-md-start flex-column flex-sm-row">
      {[
        {
          title: 'Successfull Payments',
          amount: stat.successfulPayments || 0,
          subAmount: stat.comparedSuccessfulPayments || 0,
          type: stat.successfulPaymentsRate > 0 ? UP : DOWN,
          percent: Math.abs(stat.successfulPaymentsRate || 0),
          className: 'border-200 border-bottom border-end pb-4'
        },
        {
          unit: 'value',
          title: 'Amount Value',
          amount: stat.totalAmount,
          prefix: MONEY,
          subAmount: stat.comparedTotalAmount,
          type: stat.totalRate > 0 ? UP : DOWN,
          percent: Math.abs(stat.totalRate || 0),
          className
        },
        {
          unit: 'value',
          title: 'Face Value',
          amount: stat.faceValues,
          prefix: MONEY,
          subAmount: stat.comparedFaceValues,
          type: stat.faceValueRate > 0 ? UP : DOWN,
          percent: Math.abs(stat.faceValueRate || 0),
          className
        },
        ...(viewAsRole !== ROLES.CABFARE_INTERNAL
          ? [
              {
                unit: 'value',
                title: 'Payout Value',
                amount: stat.payoutValues,
                prefix: MONEY,
                subAmount: stat.comparedPayoutValues,
                type: stat.payoutValueRate > 0 ? UP : DOWN,
                percent: Math.abs(stat.payoutValueRate || 0),
                className
              }
            ]
          : []),
        ...([ROLES.GROUP, ROLES.COMPANY, ROLES.FLEET, ROLES.DRIVER].includes(viewAsRole)
          ? [
              {
                unit: 'value',
                title: 'Upcoming Payout Value',
                amount: stat.upcomingPayoutValue,
                prefix: MONEY,
                subAmount: stat.upcomingPayoutValue,
                type: UPCOMINGVALUE,
                className
              }
            ]
          : []),
        ...(viewAsRole === ROLES.ADMIN
          ? [
              {
                unit: 'value',
                title: 'Net Value',
                amount: stat.netValues,
                prefix: MONEY,
                subAmount: stat.comparedNetValues,
                type: stat.netValueRate > 0 ? UP : DOWN,
                percent: Math.abs(stat.netValueRate || 0),
                className
              }
            ]
          : [])
      ].map((stat, index) => (
        <BizIntelItems
          key={stat.title}
          stat={stat}
          index={index}
          lastIndex={1}
          showInitStat={initStatRef.current}
        />
      ))}
      <IconButton
        size="sm"
        loading={loading}
        icon="sync-alt"
        transform="shrink-3"
        iconClassName="ms-1 fs--2"
        className="align-self-start mb-1"
        variant="falcon-default"
        onClick={onSyncStatistic}
      >
        <span className="d-sm-inline-block ms-1">Update</span>
      </IconButton>
    </Flex>
  );
}
