import React from 'react';
import { Placeholder } from 'react-bootstrap';

export default function Skeleton({
  children,
  loading,
  wrapperStyles,
  xs = 12,
  rows = 1,
  as = 'p',
  childAs = 'span',
  size = 'md',
  bg = 'secondary',
  animation = 'glow'
}) {
  if (loading)
    return (
      <Placeholder
        as={as}
        animation={animation}
        style={{ marginBottom: 0, minWidth: 150, ...wrapperStyles }}
      >
        {Array.from({ length: rows }).map((_, idx) => (
          <Placeholder key={idx} size={size} xs={xs} bg={bg} as={childAs} />
        ))}
      </Placeholder>
    );
  return <>{children}</>;
}
