import {
  signOut,
  OAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
  getAuth
} from 'firebase/auth';
import { authFirebase } from 'firebase.config';

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

export const retrieveIdTokens = async () => {
  try {
    const currentUser = getAuth().currentUser;
    return await currentUser.getIdToken(true);
  } catch (err) {
    console.error(err);
  }
};

export const onSignOutFirebaseSession = async onSuccess => {
  try {
    await signOut(authFirebase);
    if (onSuccess) onSuccess();
  } catch (err) {
    console.error(err);
  }
};

export const signInWithGoogle = async onSuccess => {
  try {
    const responseData = await signInWithPopup(authFirebase, googleProvider);
    if (onSuccess) onSuccess(responseData.user.accessToken);
  } catch (err) {
    console.error(err);
  }
};

export const signInWithApple = async onSuccess => {
  try {
    const responseData = await signInWithPopup(authFirebase, appleProvider);
    if (onSuccess) onSuccess(responseData.user.accessToken);
  } catch (err) {
    console.error(err);
  }
};
