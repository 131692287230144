import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export default function StripeResendFail() {
  return (
    <div className="text-center">
      <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" size="4x" color="#f5803e" />
      <p className="mt-4 text-800 font-sans-serif fw-semi-bold mx-5">
        The attempt to resend the Stripe connect has failed!
      </p>
    </div>
  );
}
