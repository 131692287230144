import React from 'react';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './components/SocialAuthButtons';

const Login = () => (
  <>
    <Divider className="mb-4">
      <h5>Log in</h5>
    </Divider>
    <SocialAuthButtons />
  </>
);

export default Login;
