import { FILTER_NUMBER_OPTIONS } from 'constants/index';
import { removeEmptyProperties } from 'helpers/utils';

export const normalizeData = data => {
  const {
    state,
    country,
    card_type,
    group,
    company,
    fleet,
    payout_status,
    amount_type,
    amount,
    min_amount,
    max_amount,
    payment_method_type,
    ...restData
  } = data;

  const newData = {
    ...restData,
    state: state?.label,
    country: country?.label,
    group_id: group?.value,
    fleet_id: fleet?.value,
    card_type: card_type?.value,
    payment_method_type: payment_method_type?.value,
    company_id: company?.value,
    payout_status: payout_status?.value,
    amount_filter_type: amount_type?.label,
    ...(amount_type?.value === FILTER_NUMBER_OPTIONS[0].value
      ? { min_amount: amount, max_amount: amount }
      : {}),
    ...(amount_type?.value === FILTER_NUMBER_OPTIONS[1].value ? { min_amount, max_amount } : {}),
    ...(amount_type?.value === FILTER_NUMBER_OPTIONS[2].value ? { min_amount: amount } : {}),
    ...(amount_type?.value === FILTER_NUMBER_OPTIONS[3].value ? { max_amount: amount } : {})
  };

  return removeEmptyProperties(newData);
};
