import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'react-bootstrap';
import { userStatusConfig } from 'constants/index';

export default function UserStatus({ status, minWidth = 80 }) {
  const userStatus = status ?? 0;
  const currentUserStatus = userStatusConfig[userStatus] || {};
  const { type, label, icon } = currentUserStatus;

  return (
    <Badge
      bg={classNames({ [type]: type })}
      className="d-inline-flex flex-center py-1"
      style={{ minWidth: isNaN(minWidth) ? minWidth : `${minWidth}px` }}
    >
      <p className="mb-0">{label}</p>
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ms-1" />
    </Badge>
  );
}

UserStatus.propTypes = {
  status: PropTypes.oneOf([0, 1, 2, 3])
};
