import React, { memo, useEffect, useState } from 'react';
import { OverlayTrigger, Popover, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { BsFilter } from 'react-icons/bs';
import Divider from '../Divider';
import userServices from 'services/user.services';
import { useAuth } from 'hooks/useAuth';
import { setAuthStore } from 'stores/auth.store';
import { deCamelizeKeysObjNormalize } from 'helpers/utils';

const ColumnsFilterBox = ({
  columns,
  className,
  filterKey,
  setHiddenColumns,
  visibleColumnIds,
  defaultVisibleColumnIds
}) => {
  const { userSettings, userData } = useAuth();
  const [showTip, setShowTip] = useState(false);
  const [selected, setSelected] = useState([]);
  const listColumns = columns
    .filter(col => col.Header)
    .map(record => ({
      name: record.Header,
      accessor: record.accessor
    }));

  const generateSourceItems = columns => {
    const defaultSelected = columns.map(item => item.accessor);
    setSelected(defaultSelected);
  };

  const updateColumn = () => {
    const hiddenColumns = listColumns
      .filter(item => !selected.includes(item.accessor))
      .map(col => col.accessor);

    setHiddenColumns(hiddenColumns);
  };

  const onUpdateUserSettings = async () => {
    try {
      const setting = {
        ...userSettings,
        [filterKey]: { selectedColumns: selected }
      };

      await userServices.updateUserSetting({ setting: deCamelizeKeysObjNormalize(setting) });

      setAuthStore({
        userData: { ...userData, setting }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onToggleOverlay = nextShow => {
    setShowTip(nextShow);
    if (!nextShow) onUpdateUserSettings();
    updateColumn();
  };

  const onSubmit = () => {
    setShowTip(false);
    onUpdateUserSettings();
    updateColumn();
  };

  const onReset = () => {
    generateSourceItems(columns.filter(item => defaultVisibleColumnIds.includes(item.accessor)));
  };

  const handleChange = e => {
    let updatedSelectedItem = [...selected];
    if (!e.target.checked) {
      updatedSelectedItem = updatedSelectedItem.filter(item => !item.includes(e.target.name));
    } else {
      updatedSelectedItem.push(e.target.name);
    }
    setSelected(updatedSelectedItem);
  };

  useEffect(() => {
    generateSourceItems(columns.filter(item => visibleColumnIds.includes(item.accessor)));
  }, [columns, visibleColumnIds]);

  const popover = (
    <Popover id="popover-columns-filter">
      <Popover.Body>
        <SimpleBarReact style={{ maxHeight: 350 }}>
          <div className="px-2 pt-2">
            {listColumns.map((item, idx) => (
              <Form.Check
                type="checkbox"
                key={item.accessor}
                name={item.accessor}
                onChange={handleChange}
                id={`${item.accessor}-${idx}`}
                checked={selected.includes(item.accessor)}
                label={<span className="cursor-pointer">{item.name}</span>}
              />
            ))}
          </div>
        </SimpleBarReact>
        <Divider hrClassName="m-0" />
        <div className="d-flex py-1 px-2">
          <Button variant="falcon-primary" size="sm" onClick={onReset}>
            Reset
          </Button>
          <Button variant="primary" className="ml-auto" size="sm" onClick={onSubmit}>
            Apply
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      show={showTip}
      placement="bottom-end"
      overlay={popover}
      rootClose
      onToggle={onToggleOverlay}
    >
      <Button variant="falcon-default" size="sm" className={className}>
        <BsFilter className="me-1" />
        <span className="d-none d-sm-inline-block ms-1">Filter Columns</span>
      </Button>
    </OverlayTrigger>
  );
};

ColumnsFilterBox.propTypes = {
  columns: PropTypes.array,
  className: PropTypes.string,
  filterKey: PropTypes.string,
  visibleColumnIds: PropTypes.arrayOf(PropTypes.string),
  setHiddenColumns: PropTypes.func.isRequired
};

export default memo(ColumnsFilterBox);
