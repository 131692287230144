import React from 'react';
import { Placeholder } from 'react-bootstrap';

export default function SkeletonBlock() {
  return (
    <>
      <Placeholder as="p" animation="glow">
        <Placeholder xs={9} size="sm" bg="secondary" />
      </Placeholder>
      <Placeholder as="p" animation="glow">
        <Placeholder xs={9} size="sm" bg="secondary" />
      </Placeholder>
    </>
  );
}
