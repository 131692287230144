import React, { useState } from 'react';
import { Offcanvas, Tab } from 'react-bootstrap';
import { BasicInfo, GeneralInfo, Vehicle, DriverNotes } from './components';
import NoDataBlock from 'components/common/NoDataBlock';
import TabWraper from 'components/pages/TabWrapper';

export default function AddUpdateDriver({ show, onCloseModal, driverId, data = {} }) {
  const [activeTab, setActiveTab] = useState('general');

  const { status, id } = data;

  return (
    <>
      <Offcanvas
        show={show}
        placement={'end'}
        onHide={onCloseModal}
        backdropClassName="custom-drawer"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <span className="me-1">{driverId ? 'Viewing Driver' : 'Creating Driver'}</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {driverId && <BasicInfo />}
          <TabWraper
            id="controlled-tab"
            activeKey={activeTab}
            onSelect={k => setActiveTab(k)}
            visibleItems={driverId ? ['general', 'vehicle', 'note', 'history'] : ['general']}
          >
            <Tab
              eventKey="general"
              title="General"
              tabClassName="fs-1"
              className="border-bottom border-x p-3"
            >
              <GeneralInfo />
            </Tab>
            {id && status === 1 && (
              <Tab
                eventKey="vehicle"
                title="Vehicle"
                tabClassName="fs-1"
                className="border-bottom border-x p-3"
              >
                <Vehicle id={data.id} />
              </Tab>
            )}
            <Tab
              eventKey="note"
              tabClassName="fs-1"
              title="Notes"
              className="border-bottom border-x p-3"
            >
              <DriverNotes />
            </Tab>
            <Tab
              eventKey="history"
              title="History"
              tabClassName="fs-1"
              className="border-bottom border-x p-3"
            >
              <NoDataBlock />
            </Tab>
          </TabWraper>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
