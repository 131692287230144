import React, { useState } from 'react';
import { Offcanvas, Tab } from 'react-bootstrap';
import { BasicInfo, GeneralInfo, SupervisorAndNotes } from './components';
import NoDataBlock from 'components/common/NoDataBlock';
import TabWraper from 'components/pages/TabWrapper';

export default function AddUpdateCompany({ show, onCloseModal, companyId }) {
  const [activeTab, setActiveTab] = useState('general');

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onCloseModal}
        placement={'end'}
        backdropClassName="custom-drawer"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <span className="me-1">{companyId ? 'Viewing Company' : 'Creating Company'}</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {companyId && <BasicInfo />}
          <TabWraper
            id="controlled-tab"
            activeKey={activeTab}
            onSelect={k => setActiveTab(k)}
            visibleItems={companyId ? ['general', 'note', 'history'] : ['general']}
          >
            <Tab
              eventKey="general"
              title="General"
              tabClassName="fs-1"
              className="border-bottom border-x p-4"
            >
              <GeneralInfo />
            </Tab>
            <Tab
              eventKey="note"
              tabClassName="fs-1"
              title="Supervisors/Notes"
              className="border-bottom border-x p-3"
            >
              <SupervisorAndNotes />
            </Tab>
            <Tab
              eventKey="history"
              title="History"
              tabClassName="fs-1"
              className="border-bottom border-x p-3"
            >
              <NoDataBlock />
            </Tab>
          </TabWraper>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
