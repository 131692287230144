import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Card, Badge, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { FORMAT_DATE_TIME_II } from 'constants';
import { ROLES } from 'constants';
import IconButton from 'components/common/IconButton';
import { useAuth } from 'hooks/useAuth';
import Skeleton from '../Skeleton';
import { useUserDataProfile } from './UserProfileProvider';
import Avatar from '../Avatar';
import AuthenticationWrapper from 'components/authentication/AuthenticationWrapper';
import userServices from 'services/user.services';

const UsersDetailsHeader = () => {
  const data = useUserDataProfile();
  const { viewAsRole } = useAuth();
  const [loadingResendEmail, setLoadingResendEmail] = useState(false);
  const { loading, status, email, name, createdAt, updatedAt, avatar } = data;

  const onResendMail = async () => {
    try {
      setLoadingResendEmail(true);
      await userServices.resendValidationEmail(data.id);
      toast.success('The email was successfully resent.');
    } catch (err) {
      console.error(err.message);
      toast.success('Could not resent the email. Please try later');
    } finally {
      setLoadingResendEmail(false);
    }
  };

  return (
    <Card className="mb-3">
      <Card.Body className="bg-body-tertiary">
        <h5 className="mb-2 d-flex align-items-center flex-wrap">
          <Skeleton loading={loading} xs={4}>
            <>
              {avatar ? (
                <Avatar src={avatar} className="me-2" />
              ) : (
                <Avatar size="xl" name={name} className="me-2" />
              )}
              {name}
              <span className="ms-1">
                (<a href={`mailto:${email}`}>{email}</a>)
              </span>
            </>
          </Skeleton>
        </h5>
        <Flex justifyContent="between" className="flex-column flex-sm-row">
          <Skeleton loading={loading}>
            <div className="mb-2">
              <b className="me-2">Status: </b>
              <Badge bg={status === 1 ? 'success' : 'danger'}>
                {status === 1 ? 'Active' : 'Inactive'}
                <FontAwesomeIcon icon="check" className="ms-1" transform="shrink-2" />
              </Badge>
              <AuthenticationWrapper
                viewAsRole={viewAsRole}
                permissions={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL]}
              >
                {!data.status && (
                  <div>
                    <IconButton
                      size="sm"
                      className="my-3 w-100"
                      icon="share"
                      loading={loadingResendEmail}
                      transform="shrink-3"
                      variant="primary"
                      onClick={onResendMail}
                    >
                      <span className="ms-1">Resend Mail</span>
                    </IconButton>
                  </div>
                )}
              </AuthenticationWrapper>
            </div>
          </Skeleton>
          <Card style={{ minWidth: 250 }}>
            <Card.Header className="p-2">
              <b>History</b>
            </Card.Header>
            <Card.Body className="bg-body-tertiary p-2">
              <Skeleton loading={loading}>
                <Row className="g-1">
                  <Col xs={12} sm={5} lg={4}>
                    <p className="fw-semi-bold my-2">Created</p>
                  </Col>
                  <Col>
                    <p className="my-2">{dayjs(createdAt).format(FORMAT_DATE_TIME_II)}</p>
                  </Col>
                </Row>
              </Skeleton>
              <Skeleton loading={loading} as="span">
                <Row className="g-1">
                  <Col xs={12} sm={5} lg={4}>
                    <p className="fw-semi-bold my-2">Updated</p>
                  </Col>
                  <Col>
                    <p className="my-2">{dayjs(updatedAt).format(FORMAT_DATE_TIME_II)}</p>
                  </Col>
                </Row>
              </Skeleton>
            </Card.Body>
          </Card>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default UsersDetailsHeader;
