import { useState, useCallback, useEffect } from 'react';
import commonServices from 'services/common.services';

export default function useGetTimezones() {
  const [loading, setLoading] = useState(false);
  const [listTimezones, setListTimezones] = useState([]);

  const getTimezones = useCallback(async () => {
    try {
      setLoading(true);
      const data = await commonServices.getTimeZone();
      setListTimezones(data.items);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getTimezones();
  }, []);

  return {
    loading,
    listTimezones,
    getTimezones
  };
}
