import { getAuthStore } from 'stores/auth.store';
import { ROLES } from 'constants/index';

export const checkExpiredTime = () => {
  const { timeLogEnd } = getAuthStore();
  if (!timeLogEnd) return false;

  return timeLogEnd < new Date().getTime();
};

export const useAuth = () => {
  const { userData, accessToken } = getAuthStore();
  const expiredToken = checkExpiredTime();
  const userSettings = userData.setting || {};
  const userRoles = userData.roles || [];
  const viewAsRole = userData.viewAs;
  const hasSupervisorRole = userRoles.some(
    role => role > ROLES.CABFARE_INTERNAL && role < ROLES.DRIVER
  );

  return {
    userData,
    userSettings,
    userRoles,
    viewAsRole,
    hasSupervisorRole,
    isLogin: !!accessToken && !expiredToken
  };
};
