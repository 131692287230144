import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export default function StripeConnectFail() {
  return (
    <>
      <p className="text-center">
        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" size="3x" color="#e63757" />
      </p>
      <h5 className="mb-3 ">The Stripe connection link has expired or encountered an error!</h5>
      <p className="fs--09 mb-1">
        Please request for a new stripe connection link as noted in the email. Thank you.
      </p>
    </>
  );
}
