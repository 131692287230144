import React, { useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useAuth } from 'hooks/useAuth';
import { LIST_ROLES } from 'constants';
import { PATH_TRANSACTIONS } from 'routes/route.path';
import { setAuthStore } from 'stores/auth.store';
import userServices from 'services/user.services';

export default function SwitchRoles({ placeholder, className, wrapperClass }) {
  const { userRoles, viewAsRole, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const options = LIST_ROLES.filter(role => userRoles.includes(role.value));

  const onSwitchRole = async ({ value, label }) => {
    try {
      setLoading(true);
      await userServices.updateViewAsRole(value);
      window.location.replace(PATH_TRANSACTIONS);
      setAuthStore({
        userData: {
          ...userData,
          viewAs: value
        }
      });
      toast.success(`View as ${label}`);
    } catch (err) {
      console.error(err.message);
      toast.error(`Could not view as ${label} role`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`d-flex align-items-center ${wrapperClass}`}>
      <span className="text-900">View as:</span>
      <Select
        name="switch-role"
        options={options}
        isLoading={loading}
        placeholder={placeholder}
        className={className}
        classNamePrefix="select"
        onChange={onSwitchRole}
        styles={{
          container: baseStyles => ({
            ...baseStyles,
            marginLeft: 10
          }),
          control: baseStyles => ({
            ...baseStyles,
            width: 210
          })
        }}
        defaultValue={LIST_ROLES.find(role => role.value === viewAsRole)}
      />
    </div>
  );
}
