import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { WizardInpuHorizontal } from 'components/wizard';
import {
  DUPLICATE_EMAIL,
  PLATFORM_FEE_TYPE,
  ROLES,
  SERVICE_FEE_TYPE,
  YupMessage,
  GROUP
} from 'constants/index';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useGetCountry from 'hooks/customHooks/useGetCountry';
import { useAuth } from 'hooks/useAuth';
import { addOrUpdateGroupSchema } from '../../config';
import { getListOptions, getValueObj, isValidEmail as isValidEmailInput } from 'helpers/utils';
import { commonConfig, formLayout } from 'helpers/formLayoutConfig';
import groupServices from 'services/group.services';
import StripeConnectStatus from 'components/pages/StripeConnectStatus';
import PrincipalDetailForm from 'components/pages/PrincipalDetailForm';
import FooterActionBtn from 'components/pages/FooterActionBtn';
import useCheckDuplicateEmail from 'hooks/customHooks/useCheckDuplicateEmail';
import useCheckValidABN from 'hooks/customHooks/useCheckValidABN';
import { GROUP_PRINCIPAL } from 'components/pages/PrincipalDetailForm/useAuthorizePrincipal';
import { useGroupContext } from 'pages/Group/useGroupContext';
import LoadingButton from 'components/common/LoadingButton';

export default function GeneralInfo() {
  const {
    data,
    groupId,
    onCloseModal,
    loadingDetail,
    fetchGroupDetail,
    onAddOrUpdateGroupSuccess
  } = useGroupContext();
  const { userData, viewAsRole } = useAuth();
  const [disabledForm, setDisabledForm] = useState(true);
  const viewDetailMode = Boolean(groupId);
  const viewMode = viewDetailMode && disabledForm; // only view item
  const editMode = viewDetailMode && !disabledForm; // enable update item
  const [loading, setLoading] = useState(false);
  const [assigningStripeAccId, setAssigningStripeAccId] = useState(false);
  const [isAssignExistUser, setIsAssignExistPrincipal] = useState(false);
  const formMethods = useForm({
    resolver: yupResolver(addOrUpdateGroupSchema(isAssignExistUser)),
    values: data,
    mode: 'all'
  });
  const {
    register,
    watch,
    reset,
    setError,
    setValue,
    clearErrors,
    control,
    trigger,
    handleSubmit,
    formState
  } = formMethods;
  const { errors, isSubmitted } = formState;
  const countryCode = watch('address.country_id');
  const principalCountryCode = watch('user_principal.address.country_id');
  const serviceFeeType = watch('service_fee_type');
  const platformFeeType = watch('platform_fee_type');
  const emailInput = watch('user_principal.email');
  const principalAbnInput = watch('user_principal.tax_number');
  const abnInput = watch('tax_number');
  const existEmailInput = watch('existing_principal');
  const byPassCheckAbn = [ROLES.GROUP].includes(viewAsRole);

  const { pendingCheckAbn } = useCheckValidABN({
    abn: abnInput,
    name: 'tax_number',
    validateSpecificField: true,
    clearErrors,
    setError,
    bypass: byPassCheckAbn,
    formState
  });

  const { pendingCheckAbn: pendingCheckPrincipalAbn } = useCheckValidABN({
    abn: principalAbnInput,
    name: 'user_principal.tax_number',
    validateSpecificField: true,
    clearErrors,
    setError,
    bypass: byPassCheckAbn,
    formState
  });

  const { errMsg, isValidEmail, pendingCheckingEmail, resetState } = useCheckDuplicateEmail({
    email: emailInput,
    bypass: viewMode || (editMode && emailInput == data.user_principal?.email) || !emailInput
  });

  const { fetchCountries, listCountry, listState } = useGetCountry({ countryId: countryCode });
  const { listState: listPrincipalState } = useGetCountry({ countryId: principalCountryCode });

  const onChangePrincipalCountry = e => {
    const principalCountryId = Number(e.target.value) || undefined;
    setValue('user_principal.address.country_id', principalCountryId);
    setValue('user_principal.address.state_id', undefined);
  };

  // Sync error message between email form validation and api email validation
  const onValidatePrincipalEmail = () => {
    if (!emailInput) {
      setError('user_principal.email', {
        type: 'optionality',
        message: YupMessage.REQUIRED
      });
      resetState();
      return;
    }

    const errorMsgEmail = getValueObj(errors, 'user_principal.email'.split('.'));

    if (isValidEmailInput(emailInput) && isValidEmail && errMsg && errorMsgEmail?.type === 'custom')
      clearErrors('user_principal.email');

    // By pass if user revert principal email to current principal email
    if (errMsg && !isValidEmail && !(editMode && emailInput == data.user_principal?.email)) {
      setError('user_principal.email', {
        type: 'custom',
        message: errMsg
      });
    }

    if (!isValidEmailInput(emailInput)) {
      setError('user_principal.email', {
        type: 'custom',
        message: YupMessage.INVALID_EMAIL
      });
    }
  };

  useEffect(() => {
    if (viewMode) return;
    if (!emailInput) return;
    onValidatePrincipalEmail();
  }, [emailInput, isValidEmail, errMsg, viewMode]);

  useEffect(() => {
    if (serviceFeeType && isSubmitted) {
      trigger(['group_comm', 'service_fee']);
    }
  }, [serviceFeeType, isSubmitted]);

  useEffect(() => {
    if (platformFeeType && isSubmitted) {
      trigger('platform_fee');
    }
  }, [platformFeeType, isSubmitted]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const onSubmit = async dataSubmit => {
    try {
      setLoading(true);
      let { existing_principal, ...databody } = dataSubmit;
      databody.assign_flag = false;

      if (existing_principal?.email) {
        databody = {
          ...databody,
          assign_flag: true,
          user_principal: {
            email: existing_principal.email,
            id: existing_principal.id
          }
        };
      }
      if (groupId) {
        if (databody?.user_principal?.email !== data.user_principal?.email && !isAssignExistUser) {
          databody.user_principal.id = 0;
        }
        await groupServices.updateGroup(groupId, databody);
        onAddOrUpdateGroupSuccess();
        toast.success('The group was successfully updated.');
        return;
      }
      await groupServices.create(databody);
      onAddOrUpdateGroupSuccess();
      toast.success('The group was successfully created.');
    } catch (err) {
      if (err?.error?.type === DUPLICATE_EMAIL) {
        setError('user_principal.email', { type: 'optionality', message: err?.error?.message });
      }
      toast.error(err?.error?.message || 'The action has failed.');
    } finally {
      setLoading(false);
    }
  };

  const onReset = () => {
    reset(data);
    setDisabledForm(true);
    setAssigningStripeAccId(false);
  };

  const onEnableEditForm = () => {
    setDisabledForm(false);
  };

  const onSuccess = () => {
    fetchGroupDetail(data.id);
  };

  const onBlurEmailInput = () => {
    onValidatePrincipalEmail();
  };

  const onChangeTypePrincipal = e => {
    setIsAssignExistPrincipal(e.target.checked);
    if (!e.target.checked) {
      setValue('existing_principal', undefined);
    }
  };

  const fetchAvailablePrincipal = value => {
    if (!groupId) return;
    return groupServices.getAvailablePrincipal(groupId, {
      user_name: value
    });
  };

  const isPending =
    loadingDetail || pendingCheckAbn || pendingCheckPrincipalAbn || pendingCheckingEmail;

  const hasFormError = Object.keys(errors).filter(item => item !== 'existing_principal').length > 0;

  const disabledCreate = isAssignExistUser
    ? !existEmailInput
    : isPending || hasFormError || !isValidEmailInput(emailInput);

  return (
    <FormProvider {...formMethods}>
      <form id="add-update-group" onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <Card.Header className="d-flex justify-center align-center bg-primary-subtle">
            <h5 className="mb-0 fs-0 mr-auto">Details</h5>
          </Card.Header>
          <Card.Body className="border-top fs--1 position-relative">
            {loadingDetail && <LoadingOverlay />}
            <Row>
              <Col xs={12} lg={6}>
                <div className="pb-3 pt-1">
                  <span className="fs--1 fw-bolder">Group Information</span>
                </div>
                <WizardInpuHorizontal
                  label="Group Name"
                  name="name"
                  required
                  errors={errors}
                  placeholder="Group Name"
                  {...commonConfig({
                    register: register('name'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL]
                    }
                  })}
                />
                <Row className="my-2">
                  <Col {...formLayout.label}>
                    <span className="label">Enable</span>
                  </Col>
                  <Col
                    {...formLayout.col}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <Badge bg={data.status === 1 ? 'success' : 'danger'}>
                      {data.status === 1 ? 'Active' : 'Inactive'}
                      <FontAwesomeIcon icon="check" className="ms-1" transform="shrink-2" />
                    </Badge>
                  </Col>
                </Row>
                <WizardInpuHorizontal
                  type="select"
                  watch={watch}
                  required
                  options={getListOptions(listCountry)}
                  defaultOption={{
                    label: data?.address?.country_name,
                    value: data?.address?.country_id
                  }}
                  label="Country"
                  name="address.country_id"
                  errors={errors}
                  placeholder="Add country"
                  {...commonConfig({
                    register: register('address.country_id', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.CABFARE_INTERNAL, ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  type="select"
                  watch={watch}
                  options={getListOptions(listState)}
                  label="State"
                  name="address.state_id"
                  errors={errors}
                  placeholder="Add state"
                  {...commonConfig({
                    register: register('address.state_id', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Address"
                  name="address.detail_address"
                  errors={errors}
                  placeholder="Address"
                  {...commonConfig({
                    register: register('address.detail_address'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Locality"
                  name="address.city"
                  errors={errors}
                  placeholder="Locality"
                  {...commonConfig({
                    register: register('address.city'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Post Code/ Zipcode"
                  name="address.postcode"
                  errors={errors}
                  placeholder="Post Code/ Zipcode"
                  {...commonConfig({
                    register: register('address.postcode'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Phone number"
                  name="contact_number"
                  placeholder="Phone number"
                  type="phoneInput"
                  errors={errors}
                  control={control}
                  defaultCountry={userData?.address?.countryCode}
                  {...commonConfig({
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Email"
                  name="email"
                  errors={errors}
                  placeholder="Email"
                  {...commonConfig({
                    disabled: viewMode,
                    register: register('email'),
                    permissions: {
                      VIEW: [ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL]
                    }
                  })}
                />
              </Col>
              <Col xs={12} lg={6}>
                <div className="pb-3 pt-1">
                  <span className="fs--1 fw-bolder">Operations</span>
                </div>
                <WizardInpuHorizontal
                  label="Tax ID"
                  required
                  type="number"
                  name="tax_number"
                  errors={errors}
                  placeholder="Tax ID"
                  {...commonConfig({
                    register: register('tax_number'),
                    disabled: viewMode,
                    formGroupProps: {
                      className: 'mb-3'
                    },
                    permissions: {
                      VIEW: [ROLES.CABFARE_INTERNAL, ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Service Fee Type"
                  required
                  name="service_fee_type"
                  type="select"
                  watch={watch}
                  options={SERVICE_FEE_TYPE}
                  errors={errors}
                  placeholder="Select Service Fee Type"
                  {...commonConfig({
                    register: register('service_fee_type', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode,
                    formControlProps: {
                      style: {
                        paddingRight: '3rem'
                      }
                    },
                    permissions: {
                      VIEW: [ROLES.CABFARE_INTERNAL, ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Service Fee Rate"
                  required
                  type="number"
                  name="service_fee"
                  errors={errors}
                  placeholder="Service Fee"
                  {...commonConfig({
                    register: register('service_fee', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.CABFARE_INTERNAL, ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  required
                  type="select"
                  watch={watch}
                  options={PLATFORM_FEE_TYPE}
                  label="Platform Fee Type"
                  name="platform_fee_type"
                  errors={errors}
                  placeholder="Select Platform Fee Type"
                  {...commonConfig({
                    register: register('platform_fee_type', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode,
                    formControlProps: {
                      style: {
                        paddingRight: '3rem'
                      }
                    },
                    permissions: {
                      VIEW: [ROLES.CABFARE_INTERNAL, ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  required
                  label="Platform Fee Rate"
                  type="number"
                  name="platform_fee"
                  errors={errors}
                  placeholder="Platform Fee"
                  {...commonConfig({
                    register: register('platform_fee', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.CABFARE_INTERNAL, ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  type="number"
                  label="Warning amount"
                  name="face_value_warning"
                  errors={errors}
                  placeholder="Warning amount"
                  {...commonConfig({
                    register: register('face_value_warning', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode
                  })}
                />

                <WizardInpuHorizontal
                  required
                  label="Country Tax Rate"
                  type="number"
                  name="country_tax_rate"
                  errors={errors}
                  placeholder="Country Tax Rate"
                  {...commonConfig({
                    register: register('country_tax_rate', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode
                  })}
                />
                <WizardInpuHorizontal
                  required
                  label="State Tax Rate"
                  type="number"
                  name="state_tax_rate"
                  errors={errors}
                  placeholder="State Tax Rate"
                  {...commonConfig({
                    register: register('state_tax_rate', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode
                  })}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row className="flex-row-reverse g-2">
          <Col xs={12} lg={6} className="d-flex fs--1">
            <PrincipalDetailForm
              data={data}
              pageKey={GROUP}
              isNewPrincipal={true}
              loading={loadingDetail}
              viewMode={viewMode}
              editMode={editMode}
              listCountry={listCountry}
              typePrincipal={GROUP_PRINCIPAL}
              viewDetailMode={viewDetailMode}
              listState={listPrincipalState}
              onBlurEmailInput={onBlurEmailInput}
              isAssignExistUser={isAssignExistUser}
              onChangeTypePrincipal={onChangeTypePrincipal}
              onChangeCountry={onChangePrincipalCountry}
              fetchAvailablePrincipal={fetchAvailablePrincipal}
            />
          </Col>
          <Col xs={12} lg={6} className="d-flex">
            <Card className="mb-3 flex-1">
              <Card.Header className="d-flex justify-center align-center bg-primary-subtle">
                <h5 className="mb-0 fs-0 mr-auto">Payout Details</h5>
              </Card.Header>
              <Card.Body className="border-top fs--1 position-relative">
                {loadingDetail && <LoadingOverlay />}
                <Row className="mb-1">
                  <Col {...formLayout.label}>
                    <span className="label">Parent is the owner</span>
                  </Col>
                  <Col {...formLayout.col}>Yes</Col>
                </Row>
                <WizardInpuHorizontal
                  type="number"
                  label="Group Rate"
                  name="group_comm"
                  required
                  errors={errors}
                  placeholder="Group Rate"
                  {...commonConfig({
                    register: register('group_comm', {
                      valueAsNumber: true
                    }),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.CABFARE_INTERNAL, ROLES.GROUP],
                      MODIFY: [ROLES.ADMIN]
                    }
                  })}
                />
                <Row className="mb-2">
                  <Col {...formLayout.label}>
                    <span className="label">Stripe Connect</span>
                  </Col>
                  <Col {...formLayout.col} className="d-flex gap-3 align-items-center">
                    <StripeConnectStatus
                      id={data.id}
                      type="group"
                      stripeConnected={data.stripe_connect_enabled === 1}
                      canModify={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL].includes(viewAsRole)}
                      onSuccess={onSuccess}
                    />
                    {viewAsRole === ROLES.ADMIN && editMode && (
                      <LoadingButton
                        name="Assign Existing"
                        className="btn-info fs--2 justify-content-center d-flex"
                        btnProps={{
                          onClick: () => setAssigningStripeAccId(true),
                          size: 'sm'
                        }}
                      />
                    )}
                  </Col>
                </Row>
                {assigningStripeAccId ? (
                  <WizardInpuHorizontal
                    label="Stripe Account ID"
                    name="stripe_account_id"
                    errors={errors}
                    placeholder="Stripe Account ID"
                    {...commonConfig({
                      disabled: false,
                      register: register('stripe_account_id')
                    })}
                  />
                ) : (
                  <Row className="mb-1">
                    <Col {...formLayout.label}>
                      <span className="label">Stripe Account ID</span>
                    </Col>
                    <Col {...formLayout.col}>{data.stripe_account_id}</Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
      <div className="d-flex ml-auto px-4 py-2 position-absolute w-100 bottom-0 start-0 justify-content-end bg-body-quaternary border-top">
        <FooterActionBtn
          id={groupId}
          onClose={onCloseModal}
          loading={loading}
          onReset={onReset}
          viewMode={viewMode}
          disabled={disabledCreate}
          viewDetailMode={viewDetailMode}
          onEdit={onEnableEditForm}
          formName="add-update-group"
        />
      </div>
    </FormProvider>
  );
}
