import React from 'react';
import { Offcanvas, Row, Col } from 'react-bootstrap';
import { useAuth } from 'hooks/useAuth';
import { LIST_ROLES, ROLES } from 'constants/index';
import { TransactionDetails, GeneralInfo, PayoutDetails } from './components';
import { getValueObj } from 'helpers/utils';
import { NOT_AVAILABLE, authByEndpointType } from '../columnsConfig';

function TransactionDetail({
  show,
  onHide,
  data,
  loading,
  fetchTransactionDetail,
  fetchListTransaction,
  ...props
}) {
  const { viewAsRole } = useAuth();
  const endpointType = getValueObj(data, ['stripePayment', 'paymentPoint2Payto'], '');
  const shouldHidden =
    viewAsRole === ROLES.DRIVER && endpointType.toUpperCase() !== LIST_ROLES[5].label.toUpperCase();
  const canViewPayoutDetails = authByEndpointType({
    endpointType,
    viewAsRole
  });

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onHide}
        placement={'end'}
        backdropClassName="custom-drawer"
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <span className="me-1">Viewing transaction</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <GeneralInfo
            data={data}
            loading={loading}
            fetchTransactionDetail={fetchTransactionDetail}
            fetchListTransaction={fetchListTransaction}
          />
          <Row className="g-2">
            <Col xs={12} md={6}>
              <TransactionDetails
                data={data}
                loading={loading}
                fetchTransactionDetail={fetchTransactionDetail}
              />
            </Col>
            <Col xs={12} md={6}>
              {!shouldHidden && canViewPayoutDetails !== NOT_AVAILABLE && (
                <PayoutDetails data={data} loading={loading} viewAsRole={viewAsRole} />
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default TransactionDetail;
