import React from 'react';
import { Card } from 'react-bootstrap';
import ParticipalItems from './ParticipalItems';
import { COMPANY, GROUP, FLEET } from 'constants/index';

export default function PrincipalDetailForm(props) {
  const { isAssignExistUser, viewMode, onChangeTypePrincipal, pageKey, editMode } = props;

  const visibleSwitchBtn = {
    [GROUP]: Boolean(editMode),
    [COMPANY]: true,
    [FLEET]: true
  };

  return (
    <Card className="mb-3 flex-1 fs--1">
      <Card.Header className="d-flex justify-center align-center bg-primary-subtle">
        <h5 className="mb-0 fs-0 mr-auto">Principal Details</h5>
        {visibleSwitchBtn[pageKey] && (
          <div className="form-switch">
            <input
              role="switch"
              className="form-check-input cursor-pointer"
              type="checkbox"
              disabled={viewMode}
              checked={isAssignExistUser}
              onChange={onChangeTypePrincipal}
            />
            <span className="ms-2 text-900">Assign existing user</span>
          </div>
        )}
      </Card.Header>
      <Card.Body className="border-top position-relative">
        <ParticipalItems {...props} />
      </Card.Body>
    </Card>
  );
}
