import React, { useState, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import {
  AdvanceTable,
  AdvanceTableWrapper,
  AdvanceTableHeader,
  AdvanceTablePagination
} from 'components/common/advance-table';
import { TABLE_COLUMN_FILTER_KEY, DATA_PER_PAGE, ROLES, GROUP } from 'constants/index';
import useFetchGroup from './services/useFetchGroup';
import useGetTableColumns from './services/useGetTableColumn';
import AddUpdateGroup from './AddUpdateGroup';
import ScreenSearchPanel from 'components/common/search/ScreenSearchPanel';
import { useAuth } from 'hooks/useAuth';
import { GroupContext } from './useGroupContext';

export default function Group() {
  const { viewAsRole } = useAuth();

  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [isShowModal, setIsShowModal] = useState({ groupId: null, show: false });
  const { visibleColumnIds, availableColumns, defaultVisibleColumnIds } = useGetTableColumns(
    TABLE_COLUMN_FILTER_KEY.GROUP
  );
  const {
    loading,
    listGroup,
    fetchListGroup,
    groupDetail,
    loadingDetail,
    fetchGroupDetail,
    resetGroupDetail
  } = useFetchGroup();

  const { groupId, show } = isShowModal;
  const { items = [], total_count: totalCount = 0 } = listGroup || {};

  const onAddNew = () => {
    setIsShowModal({ groupId: null, show: true });
  };

  const onClickRow = row => {
    fetchGroupDetail(row.id);
    setIsShowModal({ groupId: row.id, show: true });
  };

  const onCloseModal = () => {
    resetGroupDetail();
    setIsShowModal({ groupId: null, show: false });
  };

  const onAddOrUpdateGroupSuccess = () => {
    onCloseModal();
    fetchListGroup({
      pageIndex: tablePageIndex
    });
  };

  const onChangePage = page => {
    setTablePageIndex(page);
  };

  const providerValues = useMemo(
    () => ({
      onCloseModal,
      groupId,
      fetchGroupDetail,
      onAddOrUpdateGroupSuccess,
      loadingDetail,
      fetchListGroup,
      data: groupId ? groupDetail : {}
    }),
    [
      groupId,
      groupDetail,
      onCloseModal,
      groupId,
      fetchListGroup,
      loadingDetail,
      fetchGroupDetail,
      onAddOrUpdateGroupSuccess
    ]
  );

  return (
    <GroupContext.Provider value={providerValues}>
      <AdvanceTableWrapper
        sortable
        pagination
        data={items}
        perPage={DATA_PER_PAGE}
        loading={loading}
        rowsPerPageSelection
        totalCount={totalCount}
        columns={availableColumns}
        fetchData={fetchListGroup}
        onChangePage={onChangePage}
        visibleColumnIds={visibleColumnIds}
        defaultVisibleColumnIds={defaultVisibleColumnIds}
      >
        <Card className="mb-3">
          <Card.Header>
            <AdvanceTableHeader
              table
              onAddNew={onAddNew}
              canSearch
              canFilter
              title="Groups"
              canFilterColumns
              filterKey={TABLE_COLUMN_FILTER_KEY.GROUP}
              canAddNew
              hasPermissionAdd={ROLES.ADMIN === viewAsRole}
            />
          </Card.Header>
          <Card.Body className="p-0 custom-table d-flex flex-wrap pb-md-3">
            <div className="flex-1 d-flex flex-column flex-1 scrollbar visible-scrollbar h-fit-content w-100 rounded-end-lg">
              <AdvanceTable
                table
                onClickRow={onClickRow}
                headerClassName="bg-200 text-nowrap"
                rowClassName="align-middle white-space-nowrap cursor-pointer"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </div>
            <ScreenSearchPanel table pageKey={GROUP} />
          </Card.Body>
        </Card>
      </AdvanceTableWrapper>
      {show && <AddUpdateGroup show={show} onCloseModal={onCloseModal} groupId={groupId} />}
    </GroupContext.Provider>
  );
}
