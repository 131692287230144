import { createWithEqualityFn } from 'zustand/traditional';
import { persist } from 'zustand/middleware';

const initialState = {
  userData: {},
  accessToken: null,
  timeLogEnd: 0
};

export const useAuthStore = createWithEqualityFn(
  persist(
    (set, get) => ({
      ...initialState,
      setAccessToken: (token, cb) => {
        set({ accessToken: token });
        if (cb) cb();
      },
      getAccessToken: () => get().accessToken,
      clearStore: cb => {
        set({ ...initialState });
        if (cb) cb();
      }
    }),
    {
      name: 'auth-storage'
    }
  ),
  Object.is
);

// Access state in a non-reactive way / outside of components
export const setAuthStore = data => {
  const existingState = useAuthStore.getState() || {};

  useAuthStore.setState({ ...existingState, ...data });
};

export const getAuthStore = key => {
  const existingState = useAuthStore.getState() || {};
  if (key) return existingState[key];
  return existingState;
};

export const clearAuthStore = () => {
  useAuthStore.setState({ ...initialState });
};
