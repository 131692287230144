import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { getValueObj } from 'helpers/utils';
import { Controller } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { FORMAT_DATE_TIME } from 'constants/index';
import DateOfBirth from '../common/DateOfBirth';
import { CustomDateInput, CustomPhoneInputControl } from './components';
import useFieldAuthorize from './useFieldAuthorize';

dayjs.extend(customParseFormat);

const WizardInpuHorizontal = ({
  label,
  errors,
  placeholder,
  formGroupProps,
  required,
  control,
  watch,
  defaultCountry,
  datepickerProps,
  defaultOption,
  dateTimeFormat = FORMAT_DATE_TIME,
  isClearable = false,
  isSearchable = false,
  autocomplete = 'off',
  name = '',
  onBlur,
  type = 'text',
  options = [],
  formControlProps = {
    size: 'sm'
  },
  formGroupLayout = {
    label: {
      xs: 12
    },
    col: {
      xs: 12
    }
  }
}) => {
  const errorMsg = getValueObj(errors, name.split('.'));

  if (type === 'date-picker') {
    const newDate = watch(name);
    const isValidDate = dayjs(newDate, dateTimeFormat, true).isValid() && newDate;
    return (
      <Form.Group {...formGroupProps} as={Row}>
        <Form.Label column {...formGroupLayout.label}>
          {label}
          {required && <span className="is-required">*</span>}
        </Form.Label>
        <Col {...formGroupLayout.col}>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText={dateTimeFormat}
                onChange={date => {
                  field.onChange(dayjs(date).format(dateTimeFormat));
                }}
                className={classNames({
                  'is-invalid': errorMsg?.message
                })}
                customInput={
                  <CustomDateInput
                    formControlProps={formControlProps}
                    errorMessage={errorMsg?.message}
                  />
                }
                selected={isValidDate ? dayjs(newDate, dateTimeFormat).toDate() : null}
                {...datepickerProps}
              />
            )}
          />
        </Col>
      </Form.Group>
    );
  }
  // New select component, support various case
  if (type === 'new-select') {
    return (
      <Form.Group {...formGroupProps} as={Row}>
        {label && (
          <Form.Label column {...formGroupLayout.label}>
            {label}
            {required && <span className="is-required">*</span>}
          </Form.Label>
        )}
        <Col {...formGroupLayout.col}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  placeholder={placeholder}
                  value={field.value}
                  className={classNames('custom-select-control size-sm', {
                    'is-invalid': !!errorMsg?.value?.message
                  })}
                  autoComplete="off"
                  classNamePrefix="select"
                  onChange={value => field.onChange(value)}
                  isDisabled={formControlProps?.disabled}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  name={name}
                  options={options}
                />
              );
            }}
          />
          <Form.Control.Feedback type="invalid">{errorMsg?.value?.message}</Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  }

  // Deprecated component (support by falcon theme)
  if (type === 'select') {
    // use watch(name) htmlFor async data
    return (
      <Form.Group {...formGroupProps} as={Row}>
        {label && (
          <Form.Label column {...formGroupLayout.label} htmlFor={name}>
            {label}
            {required && <span className="is-required">*</span>}
          </Form.Label>
        )}
        <Col {...formGroupLayout.col}>
          <Form.Select
            type={type}
            id={name}
            {...formControlProps}
            isInvalid={!!errorMsg}
            {...(watch ? { value: watch(name) } : {})}
          >
            <option value="" className="place-holder">
              {placeholder}
            </option>
            {defaultOption?.value && (
              <option value={defaultOption?.value} key={defaultOption?.value}>
                {defaultOption?.label}
              </option>
            )}
            {options
              .filter(item => item.value !== defaultOption?.value)
              .map(option => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errorMsg?.message}</Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  }

  if (type === 'textarea') {
    return (
      <Form.Group {...formGroupProps} as={Row}>
        <Form.Label column {...formGroupLayout.label} htmlFor={name}>
          {label}
          {required && <span className="is-required">*</span>}
        </Form.Label>
        <Col {...formGroupLayout.col}>
          <Form.Control
            rows={4}
            as="textarea"
            placeholder={placeholder}
            {...formControlProps}
            isInvalid={!!errorMsg}
            isValid={Object.keys(errors).length > 0 && !errorMsg}
          />
        </Col>
        <Form.Control.Feedback type="invalid" as={Row}>
          {errorMsg?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  if (type === 'phoneInput') {
    const { className, disabled, ...restProps } = formControlProps;
    return (
      <Form.Group {...formGroupProps} as={Row}>
        <Form.Label column {...formGroupLayout.label} htmlFor={name}>
          {label}
          {required && <span className="is-required">*</span>}
        </Form.Label>
        <Col {...formGroupLayout.col}>
          <PhoneInputWithCountry
            name={name}
            control={control}
            id={name}
            onBlur={() => onBlur && onBlur()}
            defaultCountry={defaultCountry}
            countryOptionsOrder={['AU', 'US', '...']}
            inputComponent={CustomPhoneInputControl}
            countrySelectProps={{ disabled }}
            className={classNames(className, {
              'is-invalid': errorMsg?.message,
              isDisabled: disabled
            })}
            {...{
              isInvalid: !!errorMsg,
              placeholder,
              isDisabled: disabled,
              ...restProps
            }} // pass props down CustomPhoneInputControl, do not add register react-hook-form prop
          />
          <Form.Control.Feedback type="invalid">{errorMsg?.message}</Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  }

  if (type === 'dob') {
    const newDoB = watch(name);
    return (
      <Form.Group {...formGroupProps} as={Row}>
        {label && (
          <Form.Label column {...formGroupLayout.label}>
            {label}
            {required && <span className="is-required">*</span>}
          </Form.Label>
        )}
        <Col
          {...formGroupLayout.col}
          className={classNames({
            'is-invalid': errorMsg,
            'flex-sm-column': errorMsg
          })}
        >
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <DateOfBirth
                name={name}
                errors={errors}
                formControlProps={formControlProps}
                onChange={date => {
                  field.onChange(date);
                }}
                value={newDoB}
                disabled={formControlProps.disabled}
              />
            )}
          />
        </Col>
      </Form.Group>
    );
  }

  if (type === 'checkbox') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Check {...formControlProps} name={name} type="checkbox" id={name} label={label} />
      </Form.Group>
    );
  }

  return (
    <Form.Group {...formGroupProps} as={Row}>
      {label && (
        <Form.Label column {...formGroupLayout.label} htmlFor={name}>
          {label}
          {required && <span className="is-required">*</span>}
        </Form.Label>
      )}
      <Col
        {...formGroupLayout.col}
        className={classNames({
          'is-invalid': errorMsg,
          'flex-sm-column': errorMsg
        })}
      >
        <Form.Control
          type={type}
          id={name}
          autoComplete={autocomplete}
          placeholder={placeholder}
          {...formControlProps}
          isInvalid={!!errorMsg}
        />
        <Form.Control.Feedback type="invalid">{errorMsg?.message}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
};

WizardInpuHorizontal.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  type: PropTypes.oneOf([
    'date',
    'select',
    'textarea',
    'text',
    'phoneInput',
    'number',
    'dob',
    'checkbox'
  ]),
  options: PropTypes.array,
  placeholder: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  formGroupLayout: PropTypes.object,
  datepickerProps: PropTypes.object
};

WizardInpuHorizontal.defaultProps = { required: false };

export default useFieldAuthorize(WizardInpuHorizontal);
