import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import LoadingOverlay from '../LoadingOverlay';
import NoDataBlock from '../NoDataBlock';

const AdvanceTable = ({
  page,
  tableProps,
  onClickRow,
  prepareRow,
  loading,
  totalCount,
  headerGroups,
  bodyClassName,
  getTableProps,
  headerClassName
}) => {
  if (!loading && !totalCount) return <NoDataBlock />;
  return (
    <div className="position-relative table-responsive scrollbar visible-scrollbar">
      {loading && <LoadingOverlay />}
      <Table {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          {headerGroups.map((headerGroup, idx) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps(column.getSortByToggleProps(column.headerProps))}
                >
                  {column.render('Header')}
                  {column.canSort ? (
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="sort desc" />
                      ) : (
                        <span className="sort asc" />
                      )
                    ) : (
                      <span className="sort" />
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={bodyClassName}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                onClick={() => {
                  if (onClickRow) onClickRow(row.original);
                }}
                className="tr cursor-pointer"
                {...row.getRowProps()}
              >
                {row.cells.map((cell, index) => {
                  let cellProps = cell.getCellProps(cell.column.cellProps);
                  cellProps = {
                    ...cellProps,
                    className: `${cellProps.className} d-flex align-items-center`
                  };
                  return (
                    <td key={index} {...cellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headerGroups: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default AdvanceTable;
