import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { ROLES } from 'constants/index';
import LoadingOverlay from 'components/common/LoadingOverlay';
import AuthenticationWrapper from 'components/authentication/AuthenticationWrapper';
import {
  renderRows,
  groupPayoutRows,
  groupPayoutRefundRows,
  parentPayoutRows,
  parentPayoutRefundRows,
  endpointPayoutRows,
  endpointPayoutRefundRows
} from '../rowsConfig';

export default function PayoutDetails({ data, loading, viewAsRole }) {
  const { transactionStatus = '' } = data;
  const isRefundProgress = transactionStatus.toUpperCase().includes('REFUND');

  const endpointPayout = isRefundProgress ? endpointPayoutRefundRows : endpointPayoutRows;
  const groupPayout = isRefundProgress ? groupPayoutRefundRows : groupPayoutRows;
  const parentPayout = isRefundProgress ? parentPayoutRefundRows : parentPayoutRows;

  return (
    <>
      <Card className="h-100">
        <Card.Header className="p-2 bg-primary-subtle">
          <h5>Payout Details</h5>
        </Card.Header>
        <Card.Body className="p-2">
          <Card className="mb-3">
            <Card.Header className="p-2">
              <b>Endpoint Payout (inc Tax)</b>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive fs--1 position-relative">
                {loading && <LoadingOverlay />}
                <Table size="sm" className="border-bottom" bordered>
                  <tbody>{renderRows(data, endpointPayout, viewAsRole)}</tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
          <AuthenticationWrapper
            viewAsRole={viewAsRole}
            permissions={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]}
          >
            <Card className="mb-3 mt-2">
              <Card.Header className="p-2">
                <b>Group Payout (inc Tax)</b>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="table-responsive fs--1 position-relative">
                  {loading && <LoadingOverlay />}
                  <Table size="sm" className="border-bottom" bordered>
                    <tbody>{renderRows(data, groupPayout, viewAsRole)}</tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </AuthenticationWrapper>
          <AuthenticationWrapper viewAsRole={viewAsRole} permissions={[ROLES.ADMIN]}>
            <Card className="mt-2">
              <Card.Header className="p-2">
                <b>Parent Payout (inc Tax)</b>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="table-responsive fs--1 position-relative">
                  {loading && <LoadingOverlay />}
                  <Table size="sm" className="border-bottom" bordered>
                    <tbody>{renderRows(data, parentPayout, viewAsRole)}</tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </AuthenticationWrapper>
        </Card.Body>
      </Card>
    </>
  );
}
