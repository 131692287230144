import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Badge, Button } from 'react-bootstrap';
import { components } from 'react-select';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { WizardInpuHorizontal } from 'components/wizard';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useGetCountry from 'hooks/customHooks/useGetCountry';
import {
  resetObjectValues,
  getListOptions,
  isValidAccountNumber,
  isValidRoutingNumber,
  getValueObj,
  isValidEmail as isValidEmailInput
} from 'helpers/utils';
import { useAuth } from 'hooks/useAuth';
import { commonConfig } from 'helpers/formLayoutConfig';
import useFetchGroup from 'pages/Group/services/useFetchGroup';
import useFetchCompany from 'pages/Company/services/useFetchCompany';
import useFetchFleet from 'pages/Fleet/services/useFetchFleet';
import driverServices from 'services/driver.services';
import FooterActionBtn from 'components/pages/FooterActionBtn';
import AsyncSelect from 'components/common/search/AsyncSelect';
import userServices from 'services/user.services';
import { DUPLICATE_EMAIL, ROLES, YupMessage } from 'constants/index';
import useCheckDuplicateEmail from 'hooks/customHooks/useCheckDuplicateEmail';
import AuthenticationWrapper from 'components/authentication/AuthenticationWrapper';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';
import useCheckValidABN from 'hooks/customHooks/useCheckValidABN';
import { useDriversContext } from '../../DriversContext';
import { addOrUpdateDriverSchema, formLayout } from '../../config';

export default function GeneralInfo() {
  const { data, driverId, loadingDetail, onUpdateOrCreateDriverSuccess, onCloseModal } =
    useDriversContext();
  const [excludeBankDetails, setExcludeBankDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [existDriver, setExistDriver] = useState({});
  const [disabledForm, setDisabledForm] = useState(true);
  const viewDetailMode = Boolean(driverId);
  const viewMode = viewDetailMode && disabledForm;
  const editMode = viewDetailMode && !disabledForm; // enable update item
  const { userData, viewAsRole } = useAuth();
  const byPassCheckAbn = [ROLES.COMPANY, ROLES.GROUP, ROLES.FLEET, ROLES.DRIVER].includes(
    viewAsRole
  );
  const { id, routing_number: routingNumber, account_number: accountNumber } = existDriver || {};
  const canSearchExistDriver =
    [ROLES.ADMIN, ROLES.CABFARE_INTERNAL].includes(viewAsRole) && !viewDetailMode;

  const {
    watch,
    reset,
    control,
    register,
    setValue,
    setError,
    trigger,
    getValues,
    clearErrors,
    formState,
    handleSubmit
  } = useForm({
    resolver: yupResolver(addOrUpdateDriverSchema),
    values: data,
    mode: 'all'
  });
  const { errors } = formState;
  const groupId = watch('group_id');
  const companyId = watch('company_id');
  const countryCode = watch('address.country_id');
  const emailInput = watch('email');
  const abnInput = watch('tax_number');

  const { errMsg, isValidEmail, pendingCheckingEmail, resetState } = useCheckDuplicateEmail({
    email: emailInput,
    initialValue: data.email,
    bypass: viewMode || !isValidEmailInput(emailInput)
  });

  const { isValidABN, pendingCheckAbn } = useCheckValidABN({
    abn: abnInput,
    name: 'tax_number',
    validateSpecificField: true,
    clearErrors,
    setError,
    formState,
    bypass: !abnInput || byPassCheckAbn
  });

  const { fetchCountries, listCountry, listState } = useGetCountry({ countryId: countryCode });
  const { companiesByGroup } = useFetchCompany({ groupId });
  const { listGroup, fetchListGroup } = useFetchGroup();
  const { listFleetByCompany } = useFetchFleet({ companyId });

  const onSubmit = async data => {
    try {
      setLoading(true);
      if (!excludeBankDetails && isOnboardingDriver && validAccountNumber && validRoutingNumber) {
        data.is_exclude = 'true';
      } else {
        data.is_exclude = 'false';
      }
      if (driverId) {
        await driverServices.updateDriver(driverId, data);
        onUpdateOrCreateDriverSuccess();
        toast.success('The driver was successfully updated.');
        return;
      }
      await driverServices.create(data);
      onUpdateOrCreateDriverSuccess();
      toast.success('The driver was successfully created.');
    } catch (err) {
      if (err?.error?.type === DUPLICATE_EMAIL) {
        setError('email', { type: 'optionality', message: err?.error?.message });
      }
      if (err?.error?.type === 'INVALID_UPDATE_PRINCIPAL') {
        toast.error('Could not update Principal User.');
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  const onReset = () => {
    reset(data);
    setDisabledForm(true);
  };

  const onEnableEditForm = () => {
    setDisabledForm(false);
  };

  // Sync error message between email form validation and api email validation
  const onValidateEmail = () => {
    if (!emailInput) {
      setError('email', {
        type: 'optionality',
        message: YupMessage.REQUIRED
      });
      resetState();
      return;
    }

    const errorMsgEmail = getValueObj(errors, 'email'.split('.'));

    if (isValidEmailInput(emailInput) && isValidEmail && errMsg && errorMsgEmail?.type === 'custom')
      clearErrors('email');

    if (errMsg && !isValidEmail) {
      setError('email', {
        type: 'custom',
        message: errMsg
      });
    }

    if (!isValidEmailInput(emailInput)) {
      setError('email', {
        type: 'custom',
        message: YupMessage.INVALID_EMAIL
      });
    }
  };

  useEffect(() => {
    if (viewMode) return;
    if (!emailInput) return;
    onValidateEmail();
  }, [emailInput, isValidEmail, errMsg, viewDetailMode]);

  useEffect(() => {
    if (companyId && companiesByGroup.length) {
      const selectedCompany = companiesByGroup.find(item => item.id === companyId);
      //const selectedStateId = selectedCompany?.state_id;
      setValue('driver_comm', selectedCompany?.company_comm);
      //const isExistSelectedState = listState.find(state => state.id === selectedStateId);
      // if (isExistSelectedState) {
      //   setValue('address.state_id', selectedStateId);
      // }
    }
  }, [companyId, companiesByGroup, listState]);

  useEffect(() => {
    fetchCountries();
    fetchListGroup({ pageSize: 1000 });
  }, []);

  const onChangeGroup = e => {
    const groupId = Number(e.target.value);
    setValue('group_id', Number(groupId) || undefined);
    setValue('company_id', undefined);
    setValue('fleet_id', undefined);

    // prefill country base on group
    const selectedGroup = listGroup?.items?.find(item => item.id === groupId);
    if (selectedGroup?.country_id) {
      setValue('address.country_id', selectedGroup?.country_id);
      trigger('address.country_id');
    }
  };

  const onChangeCompany = e => {
    const companyId = Number(e.target.value);
    const selectedCompany = companiesByGroup.find(item => item.id === companyId);
    const selectedStateId = selectedCompany?.state_id;
    const isExistSelectedState = listState.find(state => state.id === selectedStateId);
    if (isExistSelectedState) {
      setValue('address.state_id', selectedStateId);
    }
    setValue('company_id', companyId);
  };

  const renderCustomOptions = props => {
    const { data } = props;
    return (
      <components.Option {...props} className="break-all">
        <span className="mx-1">{data?.first_name}</span>
        <span>{data?.last_name}</span>
        <span className="mx-1">- {data?.dc_number}</span>
      </components.Option>
    );
  };

  const onSelectExistDriver = item => {
    setExcludeBankDetails(false);
    if (item === null) {
      const formValues = getValues();
      const initialForm = resetObjectValues(formValues);
      reset({ ...initialForm });
      setExistDriver({});
      return;
    }
    reset(item);
    setExistDriver(item);
  };

  const isOnboardingDriver = !!id;
  const validRoutingNumber = isValidRoutingNumber(routingNumber) && !excludeBankDetails;
  const validAccountNumber = isValidAccountNumber(accountNumber) && !excludeBankDetails;

  const disabledCreation =
    loadingDetail ||
    (editMode && !isValidEmail) ||
    (editMode && !isValidEmailInput(emailInput)) ||
    (!viewDetailMode && pendingCheckingEmail) ||
    (editMode && !byPassCheckAbn && pendingCheckAbn) ||
    (editMode && !byPassCheckAbn && !isValidABN);

  const visibleExclude = accountNumber && routingNumber;
  const isDisabledForGroupCompanyFleet =
    viewMode || (editMode && [ROLES.COMPANY, ROLES.GROUP, ROLES.FLEET].includes(viewAsRole));
  const isDisabledEmailField = viewMode || (editMode && !!data?.verified_at);

  return (
    <>
      <form id="add-update-driver" name="add-update-driver" onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <Card.Header className="d-flex justify-center align-center bg-primary-subtle">
            <h5 className="mb-0 fs-0 mr-auto">Details</h5>
          </Card.Header>
          <Card.Body className="border-top fs--1 position-relative">
            {loadingDetail && <LoadingOverlay />}
            {canSearchExistDriver && (
              <div style={{ width: 280 }}>
                <AsyncSelect
                  isClearable
                  blurInputOnSelect
                  mapkey="dc_number"
                  onChange={onSelectExistDriver}
                  CustomOptions={renderCustomOptions}
                  fetchFn={userServices.getOnBoardingUser}
                  placeholder="Search exist driver..."
                  wrapperClassname="mb-2 custom-select async-form-control"
                />
              </div>
            )}
            <Row>
              <Col xs={12} lg={6}>
                <div className="pb-3 pt-1">
                  <span className="fs--1 fw-bolder">Driver Information</span>
                </div>
                <WizardInpuHorizontal
                  required
                  label="Driver Accreditation"
                  name="dc_number"
                  errors={errors}
                  placeholder="Driver Accreditation"
                  {...commonConfig({
                    register: register('dc_number'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Email"
                  name="email"
                  required
                  errors={errors}
                  placeholder="Email"
                  {...commonConfig({
                    formControlProps: {
                      onBlur: onValidateEmail
                    },
                    register: register('email'),
                    disabled: isDisabledEmailField,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  required
                  label="Phone number"
                  name="contact_number"
                  errors={errors}
                  onBlur={() => {
                    trigger('contact_number');
                  }}
                  placeholder="Phone number"
                  type="phoneInput"
                  control={control}
                  defaultCountry={userData?.address?.countryCode}
                  {...commonConfig({
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Tax ID"
                  type="number"
                  name="tax_number"
                  errors={errors}
                  placeholder="Tax ID"
                  {...commonConfig({
                    register: register('tax_number'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="First Name"
                  name="first_name"
                  required
                  errors={errors}
                  placeholder="First Name"
                  {...commonConfig({
                    register: register('first_name'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Last Name"
                  name="last_name"
                  required
                  errors={errors}
                  placeholder="Last Name"
                  {...commonConfig({
                    register: register('last_name'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  name="date_of_birth"
                  type="date"
                  label="Date Of Birth"
                  control={control}
                  watch={watch}
                  disabled={viewMode}
                  errors={errors}
                  {...commonConfig({
                    disabled: viewMode,
                    register: register('date_of_birth'),
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
              </Col>
              <Col xs={12} lg={6}>
                <div className="pb-3 pt-1">
                  <span className="fs--1 fw-bolder">Licensing & Operations</span>
                </div>
                <WizardInpuHorizontal
                  label="Group"
                  name="group_id"
                  required
                  type="select"
                  watch={watch}
                  errors={errors}
                  placeholder="Group"
                  options={getListOptions(listGroup.items || [])}
                  defaultOption={{
                    label: data?.group_name,
                    value: data?.group_id
                  }}
                  {...commonConfig({
                    formControlProps: {
                      onChange: onChangeGroup
                    },
                    register: register('group_id', { valueAsNumber: true }),
                    disabled: isDisabledForGroupCompanyFleet,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Company"
                  name="company_id"
                  type="select"
                  watch={watch}
                  required
                  errors={errors}
                  placeholder="Company"
                  options={getListOptions(companiesByGroup)}
                  {...commonConfig({
                    register: register('company_id', { valueAsNumber: true }),
                    formControlProps: {
                      onChange: onChangeCompany
                    },
                    disabled:
                      viewMode || (editMode && [ROLES.COMPANY, ROLES.FLEET].includes(viewAsRole)),
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Fleet"
                  name="fleet_id"
                  errors={errors}
                  type="select"
                  watch={watch}
                  placeholder="Fleet"
                  options={getListOptions(listFleetByCompany)}
                  {...commonConfig({
                    register: register('fleet_id', { valueAsNumber: true }),
                    disabled: viewMode || (editMode && [ROLES.FLEET].includes(viewAsRole)),
                    formGroupProps: {
                      className: 'mb-4'
                    },
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Address"
                  name="address.detail_address"
                  placeholder="Address"
                  errors={errors}
                  {...commonConfig({
                    register: register('address.detail_address'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Locality"
                  name="address.city"
                  errors={errors}
                  placeholder="Locality"
                  {...commonConfig({
                    register: register('address.city'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Post Code/ Zipcode"
                  name="address.postcode"
                  errors={errors}
                  placeholder="Post Code/ Zipcode"
                  {...commonConfig({
                    register: register('address.postcode'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  type="select"
                  watch={watch}
                  options={getListOptions(listState)}
                  label="State"
                  name="address.state_id"
                  errors={errors}
                  placeholder="Add state"
                  {...commonConfig({
                    register: register('address.state_id', { valueAsNumber: true }),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  type="select"
                  watch={watch}
                  required
                  options={getListOptions(listCountry)}
                  defaultOption={{
                    label: data?.address?.country_name,
                    value: data?.address?.country_id
                  }}
                  label="Country"
                  name="address.country_id"
                  errors={errors}
                  placeholder="Add country"
                  {...commonConfig({
                    register: register('address.country_id', { valueAsNumber: true }),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.DRIVER],
                      MODIFY: [
                        ROLES.ADMIN,
                        ROLES.CABFARE_INTERNAL,
                        ROLES.COMPANY,
                        ROLES.GROUP,
                        ROLES.FLEET
                      ]
                    }
                  })}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <AuthenticationWrapper
          byPass={(data?.is_driver_payment_point && viewDetailMode) || !viewDetailMode}
        >
          <Row className="g-2 mb-3">
            <Col xs={12} lg={6} className="d-flex">
              <Card className="mb-3 flex-1">
                <Card.Header className="d-flex justify-center align-center bg-primary-subtle">
                  <h5 className="mb-0 fs-0 mr-auto">Payout Details</h5>
                </Card.Header>
                <Card.Body className="border-top fs--1 position-relative">
                  {loadingDetail && <LoadingOverlay />}
                  <WizardInpuHorizontal
                    type="number"
                    label="Driver Rate"
                    name="driver_comm"
                    errors={errors}
                    placeholder="Driver Rate"
                    {...commonConfig({
                      register: register('driver_comm', { valueAsNumber: true }),
                      disabled: true,
                      formGroupProps: {
                        className: 'mb-4'
                      },
                      permissions: {
                        VIEW: [ROLES.DRIVER],
                        MODIFY: [
                          ROLES.ADMIN,
                          ROLES.CABFARE_INTERNAL,
                          ROLES.GROUP,
                          ROLES.COMPANY,
                          ROLES.FLEET
                        ]
                      }
                    })}
                  />
                  <Row className="mb-1">
                    <Col {...formLayout.label}>
                      <p className="label col-form-label mb-0">Stripe connected</p>
                    </Col>
                    <Col
                      {...formLayout.col}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <Badge
                        bg={data.stripe_connect_enabled === 1 ? 'success' : 'danger'}
                        style={{ minWidth: 65 }}
                      >
                        {data.stripe_connect_enabled === 1 ? 'Yes' : 'No'}
                        <FontAwesomeIcon icon="check" className="ms-1" transform="shrink-2" />
                      </Badge>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col {...formLayout.label}>
                      <p className="label col-form-label mb-0">Tap-to-Pay Enable</p>
                    </Col>
                    <Col {...formLayout.col}>{data.tap_to_pay_enabled ? 'Yes' : 'No'}</Col>
                  </Row>
                  <Row className="mb-1">
                    <Col {...formLayout.label}>
                      <p className="label col-form-label mb-0">Stripe Account ID</p>
                    </Col>
                    <Col {...formLayout.col}>{data.stripe_account_id}</Col>
                  </Row>
                  {isOnboardingDriver && validAccountNumber && validRoutingNumber && (
                    <>
                      {visibleExclude && (
                        <>
                          <Flex
                            alignItems="center"
                            justifyContent="between"
                            className="m-0 mb-2 p-0 mt-4 card-header"
                          >
                            <h6 className="mb-0 label">Bank details</h6>
                            <Button
                              size="sm"
                              className="btn-danger fs--1"
                              onClick={() => setExcludeBankDetails(true)}
                            >
                              <FontAwesomeIcon icon="trash-alt" className="fs--2 me-1" />
                              <span className="fs--1">Exclude</span>
                            </Button>
                          </Flex>
                          <Divider />
                        </>
                      )}
                      <Row className="mb-1">
                        <Col {...formLayout.label}>
                          <label>Bank Account Country</label>
                        </Col>
                        <Col {...formLayout.col} className="text-capitalize">
                          {existDriver?.bank_account_country}
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col {...formLayout.label}>
                          <label>Currency</label>
                        </Col>
                        <Col {...formLayout.col} className="text-uppercase">
                          {existDriver?.currency}
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col {...formLayout.label}>
                          <label>Routing Number</label>
                        </Col>
                        <Col {...formLayout.col}>
                          {routingNumber}
                          <>
                            {!validRoutingNumber && (
                              <p className="d-block invalid-feedback">Invalid routing number</p>
                            )}
                          </>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col {...formLayout.label}>
                          <label>Account Number</label>
                        </Col>
                        <Col {...formLayout.col}>
                          {accountNumber}
                          <>
                            {!validAccountNumber && (
                              <p className="d-block invalid-feedback">
                                Invalid bank account number: Must be between 5 and 9 digits long.
                              </p>
                            )}
                          </>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </AuthenticationWrapper>
      </form>
      <AuthenticationWrapper
        viewAsRole={viewAsRole}
        permissions={Object.values(ROLES).filter(role => role !== ROLES.DRIVER)}
      >
        <div className="d-flex ml-auto px-4 py-2 position-absolute w-100 bottom-0 start-0 justify-content-end bg-body-quaternary border-top">
          <FooterActionBtn
            id={driverId}
            onClose={onCloseModal}
            loading={loading}
            onReset={onReset}
            disabled={disabledCreation}
            viewMode={viewMode}
            viewDetailMode={viewDetailMode}
            onEdit={onEnableEditForm}
            formName="add-update-driver"
          />
        </div>
      </AuthenticationWrapper>
    </>
  );
}
