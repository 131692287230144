import React, { Children, cloneElement } from 'react';
import { Tabs } from 'react-bootstrap';

export default function TabWraper({ children, visibleItems, ...restProps }) {
  return (
    <Tabs {...restProps}>
      {Children.map(children, child => {
        if (!visibleItems.includes((child?.props || {}).eventKey)) return null;
        return cloneElement(child);
      })}
    </Tabs>
  );
}
