import Flex from 'components/common/Flex';
import React from 'react';
import visaImg from 'assets/img/icons/payments/visa.png';
import discoverImg from 'assets/img/icons/payments/discover.png';
import masterImg from 'assets/img/icons/payments/master.png';
import unionpayImg from 'assets/img/icons/payments/unionpay.png';
import jcbImg from 'assets/img/icons/payments/jcb.png';
import amexImg from 'assets/img/icons/payments/amex.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

const cardConfig = [
  {
    name: 'visa',
    source: visaImg
  },
  {
    name: 'amex',
    source: amexImg
  },
  {
    name: 'mastercard',
    source: masterImg
  },
  {
    name: 'discover',
    source: discoverImg
  },
  {
    name: 'unionpay',
    source: unionpayImg
  },
  {
    name: 'jcb',
    source: jcbImg
  }
];

const genCardThumbnail = brand => {
  const currentBrand = cardConfig.find(item => item.name === brand);

  if (!currentBrand) return <FontAwesomeIcon icon={faCreditCard} />;
  return <img width={30} height={18} src={currentBrand.source} />;
};

export default function PaymentMethod({ stripeCardBrand, stripePartialCardNo }) {
  return (
    <Flex alignItems="center">
      {genCardThumbnail(stripeCardBrand)}
      <span className="mx-2">**** {stripePartialCardNo}</span>
      <FontAwesomeIcon icon={faCreditCard} />
    </Flex>
  );
}
