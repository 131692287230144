import { axiosClient } from './request';

const groupServices = {
  create(body) {
    return axiosClient.post('/groups', body);
  },
  list({
    pageSize = 20,
    pageIndex = 0,
    sortBy = [],
    globalFilter = '',
    dates = [null, null],
    moreFilterData = {}
  } = {}) {
    const _sortBy = sortBy.map(item => `${item.id}.${item.desc ? 'desc' : 'asc'}`).toString();
    return axiosClient.get('/groups', {
      params: {
        limit: pageSize,
        page: pageIndex + 1,
        group_name: globalFilter,
        sort_by: _sortBy,
        start_date: dates[0],
        end_date: dates[1],
        ...moreFilterData
      }
    });
  },
  updateGroup(id, body) {
    return axiosClient.put(`groups/${id}`, body);
  },
  detail(id) {
    return axiosClient.get(`/groups/${id}`);
  },
  deleteGroupSupervisor(userId, supervisorId) {
    return axiosClient.delete(`groups/${userId}/users/${supervisorId}`);
  },
  getGroupSupervisors(id) {
    return axiosClient.get(`/groups/${id}/supervisors`);
  },
  getListAvailableToSupervisor(id, params) {
    return axiosClient.get(`/groups/${id}/assign-users`, { params });
  },
  assignToGroupSupervisor(id, uid) {
    return axiosClient.post(`groups/${id}/users/${uid}`);
  },
  connectStripe(groupId) {
    return axiosClient.post(`groups/${groupId}/invite`);
  },
  getAvailablePrincipal(groupId, params) {
    return axiosClient.get(`groups/${groupId}/principals`, { params });
  }
};

export default groupServices;
