import React, { useContext } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwitchRoles from 'components/authentication/SwitchRole';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import AppContext from 'context/Context';
import { useAuth } from 'hooks/useAuth';

const TopNavRightSideNavItem = () => {
  const { hasSupervisorRole } = useAuth();
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  return (
    <Nav navbar className="navbar-nav-icons w-100 flex-row align-items-center" as="ul">
      <Nav.Item as={'li'} className="d-none d-sm-flex ms-auto">
        {hasSupervisorRole && <SwitchRoles />}
      </Nav.Item>
      <Nav.Item as={'li'} className="ms-auto ms-sm-1">
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon icon={isDark ? 'sun' : 'moon'} className="fs-0" />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
