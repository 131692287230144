import { useState, useCallback, useEffect } from 'react';
import companyServices from 'services/company.services';
import fleetServices from 'services/fleet.services';

export default function useFetchFleet({ companyId } = {}) {
  const [listFleet, setListFleet] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [detailFleet, setDetailFleet] = useState(false);
  const [loadingFleetSupervisor, setLoadingFleetSupervisor] = useState(false);
  const [fleetSupervisor, setFleetSupervisor] = useState([]);
  const [listFleetByCompany, setListFleetByCompany] = useState([]);
  const [loadingListFleetByCompany, setLoadingListFleetByCompany] = useState(false);

  const fetchListFleet = useCallback(async body => {
    try {
      setLoading(true);
      const data = await fleetServices.list(body);
      setListFleet(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDetailFleet = useCallback(async id => {
    try {
      setLoadingDetail(true);
      const data = await fleetServices.detail(id);
      setDetailFleet(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingDetail(false);
    }
  }, []);

  const fetchFleetSupervisor = useCallback(async id => {
    try {
      setLoadingFleetSupervisor(true);
      const data = await fleetServices.getFleetSupervisors(id);
      setFleetSupervisor(data.items || []);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingFleetSupervisor(false);
    }
  }, []);

  const fetchListFleetByCompany = async id => {
    try {
      setLoadingListFleetByCompany(true);
      const data = await companyServices.listFleetByCompany(id);
      setListFleetByCompany(data.items || []);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingListFleetByCompany(false);
    }
  };

  const onResetFleetDetail = useCallback(() => {
    setDetailFleet({});
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchListFleetByCompany(companyId);
    }

    if (isNaN(companyId)) {
      setListFleetByCompany([]);
    }
  }, [companyId]);

  return {
    loading,
    loadingDetail,
    loadingFleetSupervisor,
    listFleet,
    fleetSupervisor,
    detailFleet,
    fetchListFleet,
    onResetFleetDetail,
    fetchDetailFleet,
    fetchFleetSupervisor,
    listFleetByCompany,
    loadingListFleetByCompany,
    fetchListFleetByCompany
  };
}
