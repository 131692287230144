import React, { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
  AdvanceTable,
  AdvanceTableWrapper,
  AdvanceTableHeader,
  AdvanceTablePagination
} from 'components/common/advance-table';
import { DATA_PER_PAGE, TABLE_COLUMN_FILTER_KEY, ROLES, FLEET } from 'constants/index';
import useFetchFleet from './services/useFetchFleet';
import useGetTableColumns from './services/useGetTableColumn';
import AddUpdateFleet from './AddUpdateFleet';
import ScreenSearchPanel from 'components/common/search/ScreenSearchPanel';
import { FleetContext } from './FleetContext';
import { useAuth } from 'hooks/useAuth';

export default function Fleet() {
  const { viewAsRole } = useAuth();
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const { availableColumns, visibleColumnIds, defaultVisibleColumnIds } = useGetTableColumns(
    TABLE_COLUMN_FILTER_KEY.FLEET
  );
  const [modalInfo, setModalInfo] = useState({ fleetId: null, show: false });
  const { fleetId, show } = modalInfo;

  const {
    loading,
    listFleet,
    loadingDetail,
    detailFleet,
    fetchDetailFleet,
    fetchListFleet,
    onResetFleetDetail
  } = useFetchFleet();

  const { items = [], total_count: totalCount = 0 } = listFleet || {};

  const onAddNew = () => {
    setModalInfo({ fleetId: null, show: true });
  };

  const onClickRow = row => {
    fetchDetailFleet(row.id);
    setModalInfo({ fleetId: row.id, show: true });
  };

  const onCloseModal = () => {
    setModalInfo({ fleetId: null, show: false });
    onResetFleetDetail();
  };

  const onCreateOrEditFleetSuccess = () => {
    onCloseModal();
    fetchListFleet({
      pageIndex: tablePageIndex
    });
  };

  const onChangePage = page => {
    setTablePageIndex(page);
  };

  const providerValues = useMemo(
    () => ({
      fleetId,
      onCloseModal,
      fetchListFleet,
      fetchDetailFleet,
      loadingDetail,
      onCreateOrEditFleetSuccess,
      data: fleetId ? detailFleet : {}
    }),
    [
      fleetId,
      onCloseModal,
      detailFleet,
      fetchDetailFleet,
      fetchListFleet,
      loadingDetail,
      onCreateOrEditFleetSuccess
    ]
  );

  return (
    <FleetContext.Provider value={providerValues}>
      <AdvanceTableWrapper
        sortable
        pagination
        data={items}
        perPage={DATA_PER_PAGE}
        loading={loading}
        rowsPerPageSelection
        totalCount={totalCount}
        onChangePage={onChangePage}
        columns={availableColumns}
        fetchData={fetchListFleet}
        visibleColumnIds={visibleColumnIds}
        defaultVisibleColumnIds={defaultVisibleColumnIds}
      >
        <Card className="mb-3">
          <Card.Header>
            <AdvanceTableHeader
              table
              canAddNew
              onAddNew={onAddNew}
              hasPermissionAdd={[
                ROLES.ADMIN,
                ROLES.CABFARE_INTERNAL,
                ROLES.GROUP,
                ROLES.COMPANY
              ].includes(viewAsRole)}
              canFilter
              canFilterColumns
              title="Fleets"
              filterKey={TABLE_COLUMN_FILTER_KEY.FLEET}
            />
          </Card.Header>
          <Card.Body className="p-0 custom-table d-flex flex-wrap pb-md-3">
            <div className="flex-1 d-flex flex-column flex-1 scrollbar visible-scrollbar h-fit-content w-100 rounded-end-lg">
              <AdvanceTable
                table
                onClickRow={onClickRow}
                headerClassName="bg-200 text-nowrap"
                rowClassName="align-middle white-space-nowrap cursor-pointer"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </div>
            <ScreenSearchPanel table pageKey={FLEET} />
          </Card.Body>
        </Card>
      </AdvanceTableWrapper>
      {show && <AddUpdateFleet show={show} fleetId={fleetId} onCloseModal={onCloseModal} />}
    </FleetContext.Provider>
  );
}
