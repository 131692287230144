import React from 'react';
import PropTypes from 'prop-types';
import { isIterableArray } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';
import classNames from 'classnames';

const Avatar = ({ size, rounded, src, name, className, mediaClass, isExact, icon, fallback }) => {
  const classNames = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [rounded ? `rounded-${rounded}` : 'rounded', mediaClass].join(' ');

  const getAvatar = () => {
    if (src) {
      if (isIterableArray(src)) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className="w-50 border-right">
              <img src={src[0]} alt="" referrerPolicy="no-referrer" />
            </div>
            <div className="w-50 d-flex flex-column">
              <img
                alt=""
                src={src[1]}
                className="h-50 border-bottom"
                referrerPolicy="no-referrer"
              />
              <img src={src[2]} alt="" className="h-50" referrerPolicy="no-referrer" />
            </div>
          </div>
        );
      } else {
        return <img className={mediaClasses} src={src} alt="" referrerPolicy="no-referrer" />;
      }
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : name.match(/\b\w/g)?.join('')}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    if (fallback) {
      return <img className={mediaClasses} src={fallback} alt="" />;
    }

    return null;
  };

  return <div className={classNames}>{getAvatar()}</div>;
};

export const AvatarGroup = ({ children, dense, className }) => {
  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense
      })}
    >
      {children}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  icon: PropTypes.string
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  isExact: false
};

AvatarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool
};

export default Avatar;
