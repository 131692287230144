import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SingleNote from 'components/SingleNote';
import userServices from 'services/user.services';
import { useUserContext } from 'pages/Users/useUserContext';

export default function UserNotes() {
  const { userDetail, userId, loadingDetail } = useUserContext();

  const onUpdateNote = async formValue => {
    try {
      const { roles, ...dataBody } = userDetail;
      await userServices.updateUser(userId, {
        ...dataBody,
        ...formValue,
        role: roles[0]
      });
    } catch (err) {
      throw new Error(err?.error?.message);
    }
  };

  return (
    <Row className="g-3 supervisor-note">
      <Col xs={12} lg={6}>
        <SingleNote
          name="notes"
          titleClass="fs-0"
          title="User Note"
          value={userDetail?.notes}
          handleUpdate={onUpdateNote}
          loadingInformation={loadingDetail}
        />
      </Col>
    </Row>
  );
}
