import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const listType = [
  {
    id: 'excel',
    label: 'Excel',
    ext: 'xlsx'
  },
  {
    id: 'csv',
    label: 'CSV',
    ext: 'csv'
  },
  {
    id: 'pdf',
    label: 'PDF',
    ext: 'pdf'
  },
  {
    id: 'ggSheet',
    label: 'Google Sheets',
    ext: 'gsheet'
  }
];

export default function ExportBtn({ onExport, exportType, downloading }) {
  return (
    <Dropdown className="font-sans-serif btn-reveal-trigger d-inline-block" align="end">
      <Dropdown.Toggle variant="falcon-default" size="sm" data-boundary="viewport">
        <FontAwesomeIcon
          icon={'external-link-alt'}
          className={'ms-1 me-1'}
          transform={'shrink-3'}
        />
        <span className="d-none d-sm-inline-block ms-1">Export </span>
        {downloading && <FontAwesomeIcon icon={faSpinner} className="ms-1 fs--2 fa-spin" />}
      </Dropdown.Toggle>
      <Dropdown.Menu className="border py-0">
        <div className="py-2">
          {listType
            .filter(item => exportType.includes(item.id))
            .map(record => (
              <Dropdown.Item
                as="li"
                key={record.id}
                className="cursor-pointer"
                onClick={() => onExport(record.id, record.ext)}
              >
                {record.label}
              </Dropdown.Item>
            ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

ExportBtn.propTypes = {
  downloading: PropTypes.bool,
  onExport: PropTypes.func,
  exportType: PropTypes.arrayOf(PropTypes.oneOf(['excel', 'csv', 'pdf', 'ggSheet']))
};
