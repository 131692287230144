import React from 'react';
import { useAuth } from 'hooks/useAuth';
import { checkPermission } from 'helpers/utils';
import Error404 from 'components/errors/Error404';

export default function AuthenticationRoute({ children, permission, roles, isPrivate }) {
  const { viewAsRole } = useAuth();

  if (!isPrivate) return <>{children}</>;
  if (checkPermission(true, permission, roles, viewAsRole)) return <>{children}</>;
  return <Error404 />;
}
