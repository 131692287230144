import { YupMessage } from 'constants';
import * as yup from 'yup';

yup.setLocale({
  // use constant translation keys for messages without values
  mixed: {
    required: YupMessage.REQUIRED
  }
});

export default yup;
