import axios from 'axios';
import { toast } from 'react-toastify';
import { ACCESS_TOKEN } from 'constants/index';
import { clearAuthStore, getAuthStore } from 'stores/auth.store';
import { PATH_LOGIN } from 'routes/route.path';
import { setAuthStore } from 'stores/auth.store';
import { retrieveIdTokens } from './firebase.service';
import { checkExpiredTime } from 'hooks/useAuth';
import { onSignOutFirebaseSession } from 'services/firebase.service';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const onAccessTokenFetched = accessToken => {
  subscribers = subscribers.filter(callback => callback(accessToken));
};

const addSubscriber = callback => {
  subscribers.push(callback);
  // exception case
  if (subscribers.length > 20) {
    clearAuthStore();
    onSignOutFirebaseSession();
    window.location.href = PATH_LOGIN;
  }
};

instance.interceptors.request.use(
  config => {
    const accessToken = getAuthStore(ACCESS_TOKEN);
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => response.data,
  async error => {
    const regex = /(5)/g;
    const status = error?.response?.status;
    const data = error?.response?.data;
    const originalRequest = error?.config;

    if (!status || regex.test(status)) {
      toast.error('Something went wrong please try again!');
    }
    if (error.message === 'Network Error' && error.response) {
      toast.error('Please check your internet connection and try again');
    }

    if (status === 401 || data?.message === 'Unauthorized') {
      const isExpired = checkExpiredTime();
      if (isExpired) {
        clearAuthStore();
        onSignOutFirebaseSession();
        window.location.href = PATH_LOGIN;

        return Promise.reject(error?.config);
      }

      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(accessToken => {
          originalRequest.headers.Authorization = 'Bearer ' + accessToken;
          resolve(instance(originalRequest));
        });
      });

      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        const newToken = await retrieveIdTokens();
        setAuthStore({
          accessToken: newToken
        });
        isAlreadyFetchingAccessToken = false;
        onAccessTokenFetched(newToken);
      }

      return retryOriginalRequest;
    }

    return Promise.reject(error?.response?.data);
  }
);

const noAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export { noAuthInstance as axiosPublicClient, instance as axiosClient };
