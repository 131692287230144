import React, { useState, forwardRef, useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import { Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PreconfigDate from './PreconfigDate';

const CustomDateInput = forwardRef(({ value, onClick, onChange, pickerRef }, ref) => {
  return (
    <div className="position-relative">
      <FontAwesomeIcon
        icon="calendar-alt"
        className="text-primary position-absolute top-50 translate-middle-y ms-2"
      />
      <Form.Control
        size="sm"
        ref={ref}
        onClick={e => {
          onClick(e);
          pickerRef.current.setOpen(true);
        }}
        name="cf-datepicker"
        value={value}
        className="ps-4"
        onChange={onChange}
        autoComplete="off"
        placeholder="Filter by date range"
      />
    </div>
  );
});

const CFDatePicker = ({ defaultValue, defaultPreConfig, onSelect, className }) => {
  const initialDatepicker = useRef(null);
  const initialPreConfigValue = useRef(null);
  const pickerRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [preConfigValue, setPreConfigValue] = useState(defaultPreConfig);

  useEffect(() => {
    if (defaultValue[0] && defaultValue[1]) {
      const start = dayjs.unix(defaultValue[0]).toDate();
      const end = dayjs.unix(defaultValue[1]).toDate();
      setStartDate(start);
      setEndDate(end);
      initialDatepicker.current = [start, end];
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultPreConfig) {
      initialPreConfigValue.current = defaultPreConfig;
      setPreConfigValue(defaultPreConfig);
    }
  }, [defaultPreConfig]);

  const onChange = (dates, id, isManualSelect) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setPreConfigValue(id || null);
    if (isManualSelect) {
      setPreConfigValue('fixed');
    }
  };
  const onCancel = () => {
    const [start, end] = initialDatepicker.current || [];

    setStartDate(start || null);
    setEndDate(end || null);
    setPreConfigValue(initialPreConfigValue.current);
    pickerRef.current.setOpen(false);
  };

  const onReset = () => {
    if (!startDate && !endDate) return;
    setStartDate(null);
    setEndDate(null);
    initialDatepicker.current = [null, null];
    if (onSelect) onSelect([null, null]);
    setPreConfigValue('fixed');
    initialPreConfigValue.current = 'fixed';
  };

  const onApply = () => {
    const newStartDate = startDate ? dayjs(startDate).startOf('day').unix() : null;
    const newEndDate = endDate ? dayjs(endDate).endOf('day').unix() : null;

    initialPreConfigValue.current = preConfigValue;
    initialDatepicker.current = [startDate, endDate];
    if (onSelect) onSelect([newStartDate, newEndDate], preConfigValue);
    pickerRef.current.setOpen(false);
  };

  return (
    <DatePicker
      ref={pickerRef}
      renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth, customHeaderCount }) => (
        <div className="">
          <div
            className={classNames('cabfare-react-datepicker__preconfig', {
              'cabfare-react-datepicker__hidden-block': customHeaderCount === 1
            })}
          >
            <PreconfigDate onChange={onChange} value={preConfigValue} endDate={endDate} />
          </div>
          <div className="cabfare-react-datepicker__calendar">
            <button
              aria-label="Previous Month"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              onClick={decreaseMonth}
              style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
            >
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                {'<'}
              </span>
            </button>
            <span className="react-datepicker__current-month">
              {monthDate.toLocaleString('en-US', {
                month: 'long',
                year: 'numeric'
              })}
            </span>
            <button
              aria-label="Next Month"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              onClick={increaseMonth}
              style={customHeaderCount === 0 ? { visibility: 'hidden' } : null}
            >
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                {'>'}
              </span>
            </button>
          </div>
        </div>
      )}
      selected={startDate}
      onClickOutside={onCancel}
      shouldCloseOnSelect={false}
      startDate={startDate}
      endDate={endDate}
      onChange={e => onChange(e, null, true)}
      formatWeekDay={day => day.slice(0, 3)}
      selectsRange
      monthsShown={2}
      dateFormat="MMM dd yyyy"
      customInput={<CustomDateInput pickerRef={pickerRef} />}
      calendarClassName="cabfare-react-datepicker"
      wrapperClassName={`cabfare-datepicker-input ${className}`}
    >
      <div className="d-inline-flex w-100 justify-content-end pt-2">
        <Button variant="falcon-primary" size="sm" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="mx-2" variant="falcon-primary" size="sm" onClick={onReset}>
          Reset
        </Button>
        <Button variant="primary" size="sm" onClick={onApply}>
          Apply
        </Button>
      </div>
    </DatePicker>
  );
};

export default CFDatePicker;
