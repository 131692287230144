import React from 'react';
import { Offcanvas, Row, Col } from 'react-bootstrap';
import { UserInfo, UsersDetailsHeader } from 'components/common/user-profile';
import { UserProfileProvider } from 'components/common/user-profile/UserProfileProvider';
import SingleNote from 'components/SingleNote';
import userServices from 'services/user.services';
import { setAuthStore } from 'stores/auth.store';
import { useAuth } from 'hooks/useAuth';

export default function UserDetails({
  show,
  onHide,
  data,
  loading,
  fetchData,
  fetchUserList,
  ...props
}) {
  const { notes, id, status } = data;
  const { userData, viewAsRole } = useAuth();
  const isLoggedInUser = userData.id === id;
  const isActiveUser = status === 1;
  const hasPermission = viewAsRole < 6 && isActiveUser;

  const onUpdateNote = async formValue => {
    try {
      const body = {
        ...formValue,
        contact_number: userData.contactNumber
      };

      if (isLoggedInUser) {
        await userServices.updateUserProfile(body);
        setAuthStore({
          userData: {
            ...userData,
            notes: formValue.notes
          }
        });
      } else {
        await userServices.updateUser(id, body);
      }
    } catch (err) {
      throw new Error(err?.error?.message);
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      {...props}
      placement={'end'}
      backdropClassName="custom-drawer"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="d-flex align-items-center">
          <span className="me-1">User Profile</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <UserProfileProvider value={{ ...data, fetchData, fetchUserList, loading }}>
          <UsersDetailsHeader />
          <Row className="g-3">
            <Col xs={12} lg={6}>
              <UserInfo />
            </Col>
            <Col xs={12} lg={6} className="d-flex">
              <SingleNote
                name="notes"
                title="Note"
                value={notes}
                hasPermission={hasPermission}
                handleUpdate={onUpdateNote}
                loadingInformation={loading}
              />
            </Col>
          </Row>
        </UserProfileProvider>
        {/* <UserLog /> */}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
