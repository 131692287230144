import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { FORMAT_DATE_TIME_II, ROLES, userBasicStatusConfig } from 'constants/index';
import PageHeader from 'components/common/PageHeader';
import Skeleton from 'components/common/Skeleton';
import Flex from 'components/common/Flex';
import { useCompanyContext } from 'pages/Company/useCompanyContext';
import { UserStatusSelection } from 'components/common/status';
import companyServices from 'services/company.services';
import { useAuth } from 'hooks/useAuth';

export default function BasicInfo() {
  const { viewAsRole } = useAuth();
  const [userStatus, setUserStatus] = useState();
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { data, loadingDetail, onCreateOrEditCompanySuccess } = useCompanyContext();
  const { name, status, created_at: createdAt, updated_at: updatedAt, id: companyId } = data;

  const onChangeStatus = async statusId => {
    try {
      setLoadingStatus(true);
      setUserStatus(statusId);
      await companyServices.updateCompany(companyId, { ...data, status: Number(statusId) });
      toast.success('The company status was successfully updated.');
      onCreateOrEditCompanySuccess();
    } catch (err) {
      toast.error('Could not update the Company status. Please try later');
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    setUserStatus(status);
  }, [status]);

  return (
    <PageHeader titleTag="h5" className="mb-3" classNameBody="p-2" col={{ xs: 12 }}>
      <Skeleton loading={loadingDetail} xs={3}>
        <Flex alignItems="center">
          <h5 className="mb-0">{name}</h5>
        </Flex>
      </Skeleton>
      <div className="d-flex justify-content-between flex-wrap">
        <Skeleton loading={loadingDetail}>
          <div className="mt-2">
            <Flex alignItems="center">
              <b className="me-2">Status: </b>
              <UserStatusSelection
                id={companyId}
                userStatus={userStatus}
                loading={loadingStatus}
                onChangeStatus={onChangeStatus}
                statusConfig={userBasicStatusConfig}
                canModify={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL].includes(viewAsRole)}
              />
            </Flex>
          </div>
        </Skeleton>
        <div style={{ width: 220 }}>
          <Card>
            <Card.Header className="p-2">
              <b>History</b>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive fs--1">
                <Table size="sm" className="border-bottom" bordered>
                  <tbody>
                    <Skeleton loading={loadingDetail} as="tr" childAs="td">
                      <tr>
                        <td className="bg-100 w-30 text-start">Created</td>
                        <td>{dayjs(createdAt).format(FORMAT_DATE_TIME_II)}</td>
                      </tr>
                    </Skeleton>
                    <Skeleton loading={loadingDetail} as="tr" childAs="td">
                      <tr>
                        <td className="bg-100 w-30 text-start">Updated</td>
                        <td>{dayjs(updatedAt).format(FORMAT_DATE_TIME_II)}</td>
                      </tr>
                    </Skeleton>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </PageHeader>
  );
}
