import React from 'react';
import { Outlet } from 'react-router-dom';
import MainLayout from './MainLayout';
import PublicLayout from './PublicLayout';

export default function useLayOut({ isPrivate, withoutLayout, isFunc }) {
  if (isFunc) {
    if (withoutLayout) return <Outlet />;
    return <PublicLayout byPassAuth />;
  }
  if (isPrivate) return <MainLayout />;
  return <PublicLayout />;
}
