import { useState, useCallback } from 'react';
// import { camelCaseObjNormalize } from 'helpers/utils';
import vehicleServices from 'services/vehicle.services';

export default function useFetchVehicle({ userId } = {}) {
  const [vehicleList, setVehicleList] = useState({});
  const [loadingList, setLoadingList] = useState(false);

  const fetchListVehicle = useCallback(
    async body => {
      try {
        setLoadingList(true);
        if (!userId) return;
        const data = await vehicleServices.list({ ...body, userId, pageSize: 1000 });
        setVehicleList(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingList(false);
      }
    },
    [userId]
  );

  return {
    loadingList,
    vehicleList,
    fetchListVehicle
  };
}
