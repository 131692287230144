import { ROLES } from 'constants/index';

export const GROUP_PRINCIPAL = 'GROUP_PRINCIPAL';
export const COMPANY_PRINCIPAL = 'COMPANY_PRINCIPAL';
export const FLEET_PRINCIPAL = 'FLEET_PRINCIPAL';

const baseKeys = [
  'first_name',
  'last_name',
  'tax_number',
  'date_of_birth',
  'country_id',
  'state_id',
  'detail_address',
  'city',
  'postcode',
  'contact_number',
  'email'
];

const commbinePermissions = (keys, permission) =>
  keys.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: permission
    }),
    {}
  );

export const useAuthorizePrincipal = type => {
  switch (type) {
    case GROUP_PRINCIPAL:
      return commbinePermissions(baseKeys, {
        VIEW: [ROLES.GROUP],
        MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL]
      });
    case COMPANY_PRINCIPAL:
      return commbinePermissions(baseKeys, {
        VIEW: [ROLES.COMPANY],
        MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
      });
    case FLEET_PRINCIPAL:
      return commbinePermissions(baseKeys, {
        VIEW: [ROLES.FLEET],
        MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP, ROLES.COMPANY]
      });
    default:
      return {};
  }
};
