import React, { useState } from 'react';
import yup from 'validation.yup';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { WizardInpuHorizontal } from 'components/wizard';
import ActionsBtn from '../button/ActionsBtn';
import { LIST_ROLES, YupMessage } from 'constants/index';
import { isContactNumber } from 'helpers/utils';
import userServices from 'services/user.services';
import { setAuthStore } from 'stores/auth.store';
import { useAuth } from 'hooks/useAuth';
import { PATH_USER } from 'routes/route.path';
import Skeleton from '../Skeleton';
import { useUserDataProfile } from './UserProfileProvider';

const schema = yup
  .object({
    contactNumber: yup.lazy(value => {
      if (!value) return yup.string().default('').nullable();
      return yup.string().matches(isContactNumber, YupMessage.INVALID_FORMAT);
    })
  })
  .required();

const UserInfo = () => {
  const data = useUserDataProfile();
  const location = useLocation();
  const {
    id,
    status,
    roles,
    contactNumber,
    loading: loadingInformation,
    fetchData,
    fetchUserList
  } = data;
  const { userData, viewAsRole } = useAuth();
  const [viewMode, setviewMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const isLoggedInUser = userData.id === id;
  const isActiveUser = status === 1;
  const hasPermission = viewAsRole < 6 && isActiveUser;

  const {
    register,
    trigger,
    getValues,
    control,
    resetField,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    values: {
      contactNumber,
      role: roles?.[0]
    },
    resetOptions: {
      keepDirtyValues: true
    },
    mode: 'onChange'
  });

  const onUpdate = async ({ contactNumber, role }) => {
    try {
      setLoading(true);
      const body = {
        contact_number: contactNumber,
        notes: userData?.notes,
        role
      };

      if (isLoggedInUser) {
        await userServices.updateUserProfile(body);
        fetchData();
        setAuthStore({
          userData: {
            ...userData,
            contactNumber
          }
        });
      } else {
        await userServices.updateUser(id, body);
        fetchData(id);
      }
      setviewMode(true);

      if (location.pathname === PATH_USER) {
        fetchUserList();
      }
      toast.success('The information was successfully updated.', {
        theme: 'colored'
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onEdit = async () => {
    if (viewMode) {
      setviewMode(false);
    } else {
      trigger();
      const formValue = getValues();
      const isValid = schema.isValidSync(formValue);
      if (isValid) {
        onUpdate(formValue);
      }
    }
  };

  const onReset = () => {
    resetField('contactNumber', contactNumber || '');
    setviewMode(true);
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0">Account Information</h5>
      </Card.Header>
      <Card.Body className="bg-body-tertiary border-top">
        <Row className="g-2">
          <Col xs={12}>
            <Skeleton loading={loadingInformation}>
              <Row>
                <Col xs={12} sm={5} lg={5}>
                  <span className="fw-semi-bold my-2">Email</span>
                </Col>
                <Col>
                  <span className="my-2">{data.email}</span>
                </Col>
              </Row>
            </Skeleton>
          </Col>
          <Col xs={12}>
            {viewMode || !hasPermission || isLoggedInUser ? (
              <Skeleton loading={loadingInformation}>
                <Row>
                  <Col xs={12} sm={5} lg={5}>
                    <span className="fw-semi-bold my-2">Roles</span>
                  </Col>
                  <Col>
                    <span className="my-2">
                      {LIST_ROLES.find(item => watch('role') === item.value)?.label}
                    </span>
                  </Col>
                </Row>
              </Skeleton>
            ) : (
              <WizardInpuHorizontal
                type="select"
                watch={watch}
                label="Roles"
                name="role"
                placeholder="Select roles"
                errors={errors}
                formControlProps={{
                  ...register('role', { setValueAs: value => parseInt(value, 10) }),
                  size: 'sm'
                }}
                options={LIST_ROLES.filter(role => role.value <= 2)}
                formGroupLayout={{
                  label: {
                    xs: 12,
                    sm: 5,
                    lg: 5
                  },
                  col: {
                    xs: 12,
                    sm: 7,
                    lg: 7
                  }
                }}
              />
            )}
          </Col>
          <Col xs={12}>
            {viewMode ? (
              <Skeleton loading={loadingInformation}>
                <Row>
                  <Col xs={12} sm={5} lg={5}>
                    <span className="fw-semi-bold my-2">Phone number</span>
                  </Col>
                  <Col>
                    <span className="my-2">{data.contactNumber}</span>
                  </Col>
                </Row>
              </Skeleton>
            ) : (
              <WizardInpuHorizontal
                label="Phone number"
                name="contactNumber"
                errors={errors}
                placeholder="Eg: +61412345678"
                type="phoneInput"
                control={control}
                defaultCountry={data.countryCode}
                formControlProps={{
                  size: 'sm'
                }}
                formGroupLayout={{
                  label: {
                    xs: 12,
                    sm: 5,
                    lg: 5
                  },
                  col: {
                    xs: 12,
                    sm: 7,
                    lg: 7
                  }
                }}
              />
            )}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="border-top text-end">
        {hasPermission && (
          <ActionsBtn
            onEdit={onEdit}
            loading={loading}
            onCancel={onReset}
            viewMode={viewMode}
            btnName="Edit information"
          />
        )}
      </Card.Footer>
    </Card>
  );
};

export default UserInfo;
