import React from 'react';
import yup from 'validation.yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { YupMessage } from 'constants/index';
import { WizardInput } from 'components/wizard';
import { isValidPassword } from 'helpers/utils';

const PasswordResetForm = () => {
  const schema = yup
    .object({
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], YupMessage.PASSWORD_NOT_MATCH),
      password: yup.string().required().matches(isValidPassword, YupMessage.PASSWORD_REQUIREMENT)
    })
    .required();

  const onSubmit = data => {
    console.log('data', data); // eslint-disable-line no-console
  };

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <Form className="mt-3 text-start" onSubmit={handleSubmit(onSubmit)}>
      <WizardInput
        register={register}
        errors={errors}
        name="password"
        placeholder="New Password"
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('password'),
          type: 'password'
        }}
      />
      <WizardInput
        register={register}
        errors={errors}
        name="confirmPassword"
        placeholder="Confirm Password"
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('confirmPassword'),
          type: 'password'
        }}
      />

      <Button type="submit" className="w-100">
        Set password
      </Button>
    </Form>
  );
};

export default PasswordResetForm;
