import React from 'react';
import { Card } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import dayjs from 'dayjs';
import { FORMAT_DATE_TIME_II, ROLES } from 'constants/index';
import Skeleton from 'components/common/Skeleton';
import { useAuth } from 'hooks/useAuth';
import StripeStatus from '../../common/StripeStatus';
import Flex from 'components/common/Flex';
import CurrencyAmount from 'pages/Transactions/common/CurrencyAmount';
import PaymentMethod from 'pages/Transactions/common/PaymentMethod';
import { useBreakpoints } from 'hooks/useBreakpoints';
import classNames from 'classnames';
import Refunds from './subcomponents/Refunds';
import AuthenticationWrapper from 'components/authentication/AuthenticationWrapper';

export default function GeneralInfo({
  data,
  loading,
  fetchListTransaction,
  fetchTransactionDetail
}) {
  const { breakpoints } = useBreakpoints();
  const { viewAsRole } = useAuth();
  const {
    id,
    amount,
    updatedAt,
    receiptUrl,
    stripeInfo,
    receiptNumber,
    transactionTime,
    transactionStatus
  } = data;
  return (
    <PageHeader titleTag="h5" className="mb-3" classNameBody="p-2" col={{ xs: 12 }}>
      <div className="d-flex justify-content-between flex-wrap gap-2">
        <Card
          className="ms-1 fs--1 ps-3"
          style={breakpoints.up('sm') ? { minWidth: 530 } : { minWidth: 205 }}
        >
          <Card.Header className="p-2 d-flex align-items-center ps-0">
            <Skeleton loading={loading}>
              <CurrencyAmount amount={data.amount} classNames="fw-bold fs-1 text-900" />
              <span className="fs-1 text-700 text-uppercase ms-1 me-2">
                {stripeInfo?.stripeCurrency}
              </span>
              <StripeStatus status={transactionStatus} minWidth="135px" />
            </Skeleton>
          </Card.Header>
          <Card.Body className="p-0 border-top">
            <Skeleton loading={loading} rows={2}>
              <Flex
                className={classNames('pt-2 pb-2 flex-wrap', {
                  'flex-column': breakpoints.down('sm')
                })}
              >
                <div className={classNames('ps-0 ', { 'border-end px-2': breakpoints.up('sm') })}>
                  <p className="mb-0 text-900 fw-semi-bold">Date/time</p>
                  <p className=" mb-0">{dayjs(transactionTime).format(FORMAT_DATE_TIME_II)}</p>
                </div>
                <div
                  style={{ minWidth: 120 }}
                  className={classNames({ 'border-end px-2': breakpoints.up('sm') })}
                >
                  <p className="mb-0 text-900 fw-semi-bold"> Receipt #</p>
                  <p className="mb-0">
                    <a href={receiptUrl} target="_blank" rel="noopener noreferrer">
                      {receiptNumber}
                    </a>
                  </p>
                </div>
                <div className={classNames({ 'border-end px-2': breakpoints.up('sm') })}>
                  <p className="mb-0 text-900 fw-semi-bold">Payment method</p>
                  <PaymentMethod
                    stripeCardBrand={stripeInfo?.stripeCardBrand}
                    stripePartialCardNo={stripeInfo?.stripePartialCardNo}
                  />
                </div>
                <div className={classNames({ 'px-2': breakpoints.up('sm') })}>
                  <p className="mb-0 text-900 fw-semi-bold">Updated</p>
                  <span>{dayjs(updatedAt).format(FORMAT_DATE_TIME_II)}</span>
                </div>
              </Flex>
            </Skeleton>
          </Card.Body>
        </Card>
        {transactionStatus === 'succeeded' && (
          <AuthenticationWrapper
            viewAsRole={viewAsRole}
            permissions={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL]}
          >
            <Refunds
              amount={amount}
              id={id}
              status={transactionStatus}
              fetchListTransaction={fetchListTransaction}
              fetchTransactionDetail={fetchTransactionDetail}
            />
          </AuthenticationWrapper>
        )}
      </div>
    </PageHeader>
  );
}
