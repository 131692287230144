export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const USER_DATA = 'userData';

export const ROLES = {
  ADMIN: 1,
  CABFARE_INTERNAL: 2,
  GROUP: 3,
  COMPANY: 4,
  FLEET: 5,
  DRIVER: 6
};

export const LIST_ROLES = [
  {
    value: 1,
    label: 'Portal Admin'
  },
  {
    value: 2,
    label: 'CabFare Internal'
  },
  {
    value: 3,
    label: 'Group Supervisor'
  },
  {
    value: 4,
    label: 'Company Supervisor'
  },
  {
    value: 5,
    label: 'Fleet Supervisor'
  },
  {
    value: 6,
    label: 'Driver'
  }
];
