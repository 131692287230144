export const PATH_HOME = '/';
export const PATH_TRANSACTIONS = '/transactions';
export const PATH_QUERIED_TRANSACTION = '/queried-transaction';
export const PATH_FEES_AND_REFUND = '/fees-and-refunds';
export const PATH_GROUP = '/group';
export const PATH_COMPANIES = '/companies';
export const PATH_FLEETS = '/fleets';
export const PATH_DRIVERS = '/drivers';
export const PATH_DASHBOARD = '/dashboard';
export const PATH_USER = '/users';
export const PATH_SETTINGS = '/settings';
export const PATH_AUTH = '/auth';
export const PATH_LOGIN = '/auth/login';
export const PATH_FORGOT_PASS = '/auth/forgot-password';
export const PATH_RESET_PASS = '/auth/reset-password';
export const PATH_LOCK_SCREEN = '/auth/lock-screen';
export const PATH_VERIFY = '/auth/verify';
export const PATH_CHECK_STRIPE_CONNECT = '/auth/stripe-express/connect';
export const PATH_CHECK_STRIPE_CONNECT_ONBOARD = '/auth/stripe-express/connect-return';
export const PATH_STRIPE_CONNECT_FAIL = '/auth/stripe-express/fail';
export const PATH_STRIPE_CONNECT_ERROR = '/auth/stripe-express/error';
export const PATH_STRIPE_CONNECT_SUCESS = '/auth/stripe-express/success';
export const PATH_RESEND_ACTIVATION_EMAIL = '/auth/resend';
export const PATH_STRIPE_RESEND_FAIL = '/auth/stripe-express/resend-error';
export const PATH_STRIPE_RESEND_SUCESS = '/auth/stripe-express/resend-success';
export const PATH_MAGIC_LINK = '/auth/magic-link';
export const PATH_AUTH_ACTION = '/__/auth/action';
