import React, { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import classNames from 'classnames';
import { WizardInput } from 'components/wizard';
import { searchPanelCommonConfig } from 'helpers/formLayoutConfig';
import { useForm, FormProvider } from 'react-hook-form';
import useGetCountry from 'hooks/customHooks/useGetCountry';
import { resetObjectValues, getListOptions } from 'helpers/utils';
import {
  CARD_TYPE,
  PAYMENT_METHOD_TYPE,
  PAYOUT_STATUS,
  FILTER_NUMBER_OPTIONS,
  ROLES
} from 'constants/index';
import useFetchGroup from 'pages/Group/services/useFetchGroup';
import useFetchCompany from 'pages/Company/services/useFetchCompany';
import useFetchFleet from 'pages/Fleet/services/useFetchFleet';
import { useAuth } from 'hooks/useAuth';
import AmountFilter from './AmountFilter';
import { normalizeData } from './helper';
import LoadingButton from 'components/common/LoadingButton';
import AuthenticationWrapper from 'components/authentication/AuthenticationWrapper';
import { CloseIcon } from 'assets/img/icons';

export default function TransactionSearchPanel({
  loadingList,
  activeFilter,
  onSubmitFilter,
  setFilterStatus
}) {
  const { viewAsRole } = useAuth();

  const formMethods = useForm({
    defaultValues: {
      amount_type: FILTER_NUMBER_OPTIONS[0]
    }
  });
  const { register, watch, setValue, handleSubmit, control, reset, getValues } = formMethods;
  const countryCode = watch('country')?.value;
  const groupId = watch('group')?.value ?? watch('group');
  const companyId = watch('company')?.value ?? watch('company');

  const { listCountry, listState, fetchCountries, loadingState } = useGetCountry({
    countryId: countryCode
  });
  const { listGroup, fetchListGroup } = useFetchGroup();
  const { companiesByGroup, loadingCompanyByGroup } = useFetchCompany({ groupId });
  const { listFleetByCompany, loadingListFleetByCompany } = useFetchFleet({ companyId });

  useEffect(() => {
    if (!activeFilter) {
      onResetFilter();
    }
  }, [activeFilter]);

  useEffect(() => {
    if (activeFilter) {
      fetchCountries();
      fetchListGroup({ pageSize: 1000 });
    }
  }, [activeFilter]);

  useEffect(() => {
    if (groupId && listGroup?.items?.length) {
      const selectedGroup = listGroup.items.find(item => item.id === groupId);
      setValue('country', selectedGroup?.country_id);
    }
  }, [groupId, listGroup]);

  useEffect(() => {
    if ((viewAsRole === 3 || viewAsRole === 4) && listGroup?.items?.length) {
      const selectedGroup = listGroup.items[0];
      setValue('group', selectedGroup?.id);
    }
  }, [viewAsRole, listGroup, setValue]);

  useEffect(() => {
    if (viewAsRole === 4 && companiesByGroup?.length) {
      const selectedCompany = companiesByGroup[0];
      setValue('company', selectedCompany?.id);
    }
  }, [viewAsRole, companiesByGroup, setValue, groupId]);

  useEffect(() => {
    if (companyId && companiesByGroup.length) {
      const selectedCompany = companiesByGroup.find(item => item.id === companyId);
      setValue('state', selectedCompany?.state_id);
    }
  }, [companyId, companiesByGroup]);

  useEffect(() => {
    if (!groupId) {
      setValue('company', null);
      setValue('fleet', null);
    }
  }, [groupId, setValue]);

  useEffect(() => {
    if (!companyId) {
      setValue('fleet', null);
    }
  }, [companyId, setValue]);

  useEffect(() => {
    if (!countryCode) {
      setValue('state', null);
    }
  }, [countryCode, setValue]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const onSubmit = data => {
    const formattedData = normalizeData(data);
    onSubmitFilter(formattedData);
    scrollToTop();
  };

  const onResetFilter = () => {
    const initialForm = resetObjectValues(getValues());
    reset({ ...initialForm, amount_type: FILTER_NUMBER_OPTIONS[0] });
  };

  const handleOnReset = () => {
    onResetFilter();
    onSubmitFilter({});
    scrollToTop();
  };

  return (
    <div
      className={classNames('filter-content', {
        hidden: !activeFilter,
        'overflow-visible': activeFilter
      })}
      style={{ width: activeFilter ? 220 : 'auto' }}
    >
      <Card className="border">
        <Card.Header className="d-flex align-items-center bg-primary-subtle p-2">
          <h5 className="mb-0 fs-0 mr-auto fw-bold">Filters</h5>
          <CloseIcon onClick={() => setFilterStatus(false)} width="12" height="12" />
        </Card.Header>
        <Card.Body className="border-top fs--1 position-relative p-2 pb-3">
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <h6 className="fw-bolder text-nowrap">Transaction</h6>
              <WizardInput
                type="number"
                label="Receipt number"
                name="receipt_number"
                {...searchPanelCommonConfig({
                  register: register('receipt_number')
                })}
              />
              <WizardInput
                type="new-select"
                label="Payment Method Type"
                name="payment_method_type"
                control={control}
                isClearable
                options={PAYMENT_METHOD_TYPE}
                placeholder="Choose..."
                {...searchPanelCommonConfig({
                  register: register('payment_method_type')
                })}
              />
              <WizardInput
                type="new-select"
                label="Card Type"
                name="card_type"
                control={control}
                isClearable
                options={CARD_TYPE}
                placeholder="Choose..."
                {...searchPanelCommonConfig({
                  register: register('card_type')
                })}
              />
              <WizardInput
                type="number"
                label="Card Last 4 digits"
                name="card_number"
                {...searchPanelCommonConfig({
                  register: register('card_number')
                })}
              />
              <AmountFilter />
              <AuthenticationWrapper
                viewAsRole={viewAsRole}
                permissions={[
                  ROLES.ADMIN,
                  ROLES.CABFARE_INTERNAL,
                  ROLES.GROUP,
                  ROLES.COMPANY,
                  ROLES.FLEET
                ]}
              >
                <h6 className="fw-bolder mt-3">Partner</h6>
              </AuthenticationWrapper>

              <AuthenticationWrapper
                viewAsRole={viewAsRole}
                permissions={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL]}
              >
                <WizardInput
                  type="new-select"
                  label="Group"
                  name="group"
                  isClearable
                  control={control}
                  options={getListOptions(listGroup.items || [])}
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('group')
                  })}
                />
              </AuthenticationWrapper>

              <AuthenticationWrapper
                viewAsRole={viewAsRole}
                permissions={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]}
              >
                <WizardInput
                  type="new-select"
                  label="Company"
                  name="company"
                  isClearable
                  control={control}
                  isLoading={loadingCompanyByGroup}
                  options={getListOptions(companiesByGroup)}
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('company')
                  })}
                />
              </AuthenticationWrapper>

              <AuthenticationWrapper
                viewAsRole={viewAsRole}
                permissions={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP, ROLES.COMPANY]}
              >
                <WizardInput
                  type="new-select"
                  label="Fleet"
                  name="fleet"
                  isClearable
                  isLoading={loadingListFleetByCompany}
                  options={getListOptions(listFleetByCompany)}
                  control={control}
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('fleet')
                  })}
                />
              </AuthenticationWrapper>

              <AuthenticationWrapper
                viewAsRole={viewAsRole}
                permissions={[
                  ROLES.ADMIN,
                  ROLES.CABFARE_INTERNAL,
                  ROLES.GROUP,
                  ROLES.COMPANY,
                  ROLES.FLEET
                ]}
              >
                <WizardInput
                  label="Driver Accreditation"
                  name="driver_number"
                  {...searchPanelCommonConfig({
                    register: register('driver_number')
                  })}
                />
                <WizardInput
                  label="Driver Name"
                  name="driver_name"
                  {...searchPanelCommonConfig({
                    register: register('driver_name')
                  })}
                />
              </AuthenticationWrapper>

              <AuthenticationWrapper
                viewAsRole={viewAsRole}
                permissions={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL]}
              >
                <h6 className="fw-bolder mt-3">Location</h6>
                <WizardInput
                  type="new-select"
                  control={control}
                  isClearable
                  options={getListOptions(listCountry)}
                  label="Country"
                  name="country"
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('country')
                  })}
                />
                <WizardInput
                  type="new-select"
                  control={control}
                  isClearable
                  options={getListOptions(listState)}
                  label="State"
                  name="state"
                  isLoading={loadingState}
                  placeholder="Choose..."
                  {...searchPanelCommonConfig({
                    register: register('state')
                  })}
                />
              </AuthenticationWrapper>

              <AuthenticationWrapper
                viewAsRole={viewAsRole}
                permissions={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL]}
              >
                <h6 className="fw-bolder mt-3">Payout Status</h6>
                <WizardInput
                  type="new-select"
                  isClearable
                  control={control}
                  label="Status"
                  name="payout_status"
                  placeholder="Choose..."
                  options={PAYOUT_STATUS}
                  {...searchPanelCommonConfig({
                    register: register('payout_status')
                  })}
                />
              </AuthenticationWrapper>
              <div className="d-flex gap-3 justify-content-center mt-4">
                <Button
                  className="default-btn"
                  variant="falcon-default"
                  size="sm"
                  onClick={handleOnReset}
                >
                  Reset
                </Button>
                <LoadingButton
                  isLoading={loadingList}
                  className="default-btn d-flex justify-content-center"
                  btnProps={{
                    type: 'submit',
                    size: 'sm'
                  }}
                  name="Apply"
                />
              </div>
            </form>
          </FormProvider>
        </Card.Body>
      </Card>
    </div>
  );
}
