import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';

export default function StripeStatus({ status, minWidth = 'auto', textClass = '' }) {
  if (!status) return null;
  const stripeStatus = status?.toUpperCase();

  return (
    <SoftBadge
      pill
      minWidth={minWidth}
      bg={classNames({
        success: stripeStatus === 'SUCCEEDED',
        warning: ['REFUND-COLLECTED', 'REFUNDED', 'REFUND-PENDING', 'REFUND-PARTIAL'].includes(
          stripeStatus
        ),
        danger: stripeStatus === 'FAILED',
        secondary: stripeStatus === 'INCOMPLETE'
      })}
      className="d-inline-flex flex-center py-1"
    >
      <p className={`mb-0 ${textClass}`}>
        {stripeStatus === 'SUCCEEDED' && 'Succeeded'}
        {stripeStatus === 'INCOMPLETE' && 'Incomplete'}
        {stripeStatus === 'REFUND-PENDING' && 'Refund Pending'}
        {stripeStatus === 'REFUND-PARTIAL' && 'Partial Refund'}
        {stripeStatus === 'REFUND-COLLECTED' && 'Refund Collected'}
        {stripeStatus === 'REFUNDED' && 'Refunded'}
        {stripeStatus === 'FAILED' && 'Failed'}
      </p>
      <FontAwesomeIcon
        icon={classNames({
          check: stripeStatus === 'SUCCEEDED',
          spinner: stripeStatus === 'REFUND-PENDING',
          hashtag: stripeStatus === 'REFUND-PARTIAL',
          exclamation: stripeStatus === 'FAILED',
          wrench: stripeStatus === 'INCOMPLETE',
          truck: stripeStatus === 'REFUND-COLLECTED',
          receipt: stripeStatus === 'REFUNDED'
        })}
        transform="shrink-2"
        className="ms-1"
      />
    </SoftBadge>
  );
}

StripeStatus.propTypes = {
  status: PropTypes.oneOf([
    'succeeded',
    'incomplete',
    'refund-pending',
    'refunded',
    'failed',
    'refund-collected',
    'refund-partial'
  ])
};
