import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  AdvanceTable,
  AdvanceTableHeader,
  AdvanceTableWrapper,
  AdvanceTablePagination
} from 'components/common/advance-table';
import { DATA_PER_PAGE } from 'constants';
import TransactionDetail from './TransactionDetail';
import useGetTableColumns from './services/useGetTableColumns';
import useFetchTransactionData from './services/useFetchTransactionData';
import transactionServices from 'services/transaction.service';
import TransactionSearchPanel from 'components/common/search/TransactionSearchPanel/TransactionSearchPanel';
import { TABLE_COLUMN_FILTER_KEY } from 'constants/index';
import BIReporting from './BIReporting';
import {
  LAST7DAYS_RANGE_TIME,
  getLastXdayUnix,
  todayUnix
} from 'components/common/CFDatePicker/config';

const Transactions = () => {
  const {
    transactionDetail,
    transactionList,
    loadingList,
    loadingDetail,
    fetchListTransaction,
    fetchTransactionDetail,
    resetTransactionDetail
  } = useFetchTransactionData();

  const [globalFilter, setGlobalFilter] = useState({
    dates: [getLastXdayUnix(6), todayUnix],
    rangeTime: LAST7DAYS_RANGE_TIME
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const { visibleColumnIds, availableColumns, defaultVisibleColumnIds } = useGetTableColumns();
  const onClickRow = row => {
    fetchTransactionDetail(row.id);
    setSelectedRow(row);
  };

  useEffect(() => {
    setShowDetail(!!selectedRow.id);
  }, [selectedRow.id]);

  const onCloseTransactionDetail = () => {
    setShowDetail(false);
    setSelectedRow({});
    resetTransactionDetail();
  };
  const { items = [], total_count: totalCount = 0 } = transactionList || {};

  const onExport = async (type, ext) => {
    try {
      setDownloading(true);
      const { dates = [], moreFilterData } = globalFilter;
      const exportParams = {
        start_time: dates[0],
        end_time: dates[1],
        ...moreFilterData
      };

      const respData = await transactionServices.exportTransaction({ type, ...exportParams });
      if (!respData?.file_url) {
        toast.error('Could not download file. Please try later');
        return;
      }

      const link = document.createElement('a');
      link.href = respData.file_url;
      link.setAttribute('download', `list_transaction_${Date.now()}.${ext}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error('Could not download file. Please try later');
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };

  const handleClick = () => {
    setShowPanel(!showPanel);
  };

  const handleChangeFilter = ({ rangeTime, dates, moreFilterData }) => {
    setGlobalFilter({ rangeTime, dates, moreFilterData });
  };

  return (
    <>
      <BIReporting globalFilter={globalFilter} />
      <AdvanceTableWrapper
        sortable
        pagination
        data={items}
        perPage={DATA_PER_PAGE}
        loading={loadingList}
        fetchData={fetchListTransaction}
        hasDatePicker
        rowsPerPageSelection
        columns={availableColumns}
        initialDatePicker={globalFilter.dates}
        initialRangeTime={globalFilter.rangeTime}
        onChangeGlobalFilter={handleChangeFilter}
        totalCount={totalCount}
        visibleColumnIds={visibleColumnIds}
        defaultVisibleColumnIds={defaultVisibleColumnIds}
      >
        <div className="d-flex gap-1 bg-emphasis p-2 rounded-end-lg flex-column flex-md-row">
          <Card className="mb-3 mb-sm-0 w-100">
            {/* min height of the searchbar ~ 412 */}
            <div className="flex-1" style={{ minHeight: 412 }}>
              <Card.Header>
                <AdvanceTableHeader
                  table
                  title="Transactions"
                  canFilter
                  filterKey={TABLE_COLUMN_FILTER_KEY.TRANSACTION}
                  onExport={onExport}
                  canFilterColumns
                  showPanel={showPanel}
                  handleClick={handleClick}
                  downloading={downloading}
                  exportType={['excel', 'csv']}
                />
              </Card.Header>
              <Card.Body className="p-0 custom-table pb-md-3">
                <div className="flex-1 d-flex flex-column flex-1 scrollbar visible-scrollbar h-fit-content w-100 rounded-end-lg">
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap"
                    rowClassName="align-middle white-space-nowrap cursor-pointer"
                    onClickRow={onClickRow}
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                  <Card.Footer>
                    <AdvanceTablePagination table />
                  </Card.Footer>
                </div>
              </Card.Body>
            </div>
          </Card>
          <TransactionSearchPanel table loadingList={loadingList} />
        </div>
      </AdvanceTableWrapper>
      <TransactionDetail
        loading={loadingDetail}
        data={transactionDetail}
        show={showDetail}
        onHide={onCloseTransactionDetail}
        fetchListTransaction={fetchListTransaction}
        fetchTransactionDetail={fetchTransactionDetail}
      />
    </>
  );
};

export default Transactions;
