import { createContext, useContext } from 'react';

export const FleetContext = createContext({
  data: {},
  fleetId: null,
  onCloseModal: () => {},
  loadingDetail: false,
  fetchListFleet: () => {},
  onCreateOrEditFleetSuccess: () => {},
  fetchDetailFleet: () => {}
});
FleetContext.displayName = 'FleetContext';

export const useFleetContext = () => useContext(FleetContext);
