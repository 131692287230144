import { useState, useCallback } from 'react';
import transactionServices from 'services/transaction.service';
import { camelCaseObjNormalize } from 'helpers/utils';
import { useAuth } from 'hooks/useAuth';

export default function useFetchTransactionData() {
  const { viewAsRole } = useAuth();
  const [transactionList, setTransactionList] = useState({});
  const [transactionDetail, setTransactionDetail] = useState({});
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [currentListParams, setCurrentListParams] = useState(null);

  const fetchListTransaction = useCallback(async body => {
    try {
      setLoadingList(true);
      if (body) setCurrentListParams(body);
      const data = await transactionServices.list(body || currentListParams);
      const normalizeItems = (data?.items || []).map(record => {
        const { StripeInfo, StripePayment, ...rest } = record;
        delete StripeInfo['id'];
        delete StripePayment['id'];
        return {
          ...rest,
          ...StripeInfo,
          ...StripePayment,
          viewAsRole
        };
      });
      const normalizeData = {
        ...data,
        items: normalizeItems
      };
      setTransactionList(normalizeData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingList(false);
    }
  }, []);

  const fetchTransactionDetail = useCallback(async id => {
    try {
      setLoadingDetail(true);
      const data = await transactionServices.detail(id);
      setTransactionDetail(camelCaseObjNormalize(data));
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingDetail(false);
    }
  }, []);

  const resetTransactionDetail = () => {
    setTransactionDetail({});
  };

  return {
    loadingDetail,
    loadingList,
    transactionList,
    fetchListTransaction,
    transactionDetail,
    resetTransactionDetail,
    fetchTransactionDetail
  };
}
