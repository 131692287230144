import { YupMessage } from 'constants';
import { isContactNumber, isEmail, userPrincipalSchema } from 'helpers/utils';
import yup from 'validation.yup';

const transformFn = value => (Number.isNaN(value) ? null : value);

export const genFleetFormSchema = isAssignExistUser =>
  yup
    .object({
      name: yup.string().required(),
      tax_number: yup.string().required(),
      email: yup.lazy(value => {
        if (!value) return yup.string().default('').nullable();
        return yup.string().matches(isEmail, YupMessage.INVALID_EMAIL);
      }),
      contact_number: yup.lazy(value => {
        if (!value) return yup.string().default('').nullable();
        return yup.string().matches(isContactNumber, YupMessage.INVALID_FORMAT);
      }),
      address: yup.object().shape({
        postcode: yup.string().required(),
        country_id: yup.number().transform(transformFn).required(),
        detail_address: yup.string().required()
      }),
      group_id: yup.number().transform(transformFn).required(),
      company_id: yup.number().transform(transformFn).required(),
      payment_point: yup.string().required(),
      ...(isAssignExistUser
        ? { existing_principal: yup.object().required() }
        : { user_principal: yup.object().concat(userPrincipalSchema) })
    })
    .required();
