import React from 'react';
import { Button } from 'react-bootstrap';
import LoadingButton from 'components/common/LoadingButton';

export default function FooterActionBtn({
  onEdit,
  viewMode,
  loading,
  onClose,
  onReset,
  formName,
  disabled,
  viewDetailMode
}) {
  if (viewMode)
    return (
      <Button
        size="sm"
        disabled={disabled}
        variant="falcon-default"
        className=" ms-2 mb-1"
        onClick={onEdit}
      >
        Update details
      </Button>
    );
  if (viewDetailMode) {
    return (
      <>
        <LoadingButton
          isLoading={loading}
          size="sm"
          btnProps={{
            form: formName,
            type: 'submit',
            disabled
          }}
          name="Update"
          loadingBtnName="Update"
        />
        <Button size="sm" variant="falcon-default" className=" ms-2 mb-1" onClick={onReset}>
          Cancel
        </Button>
      </>
    );
  }
  return (
    <>
      <LoadingButton
        isLoading={loading}
        size="sm"
        btnProps={{
          form: formName,
          type: 'submit',
          disabled
        }}
        name="Create"
        loadingBtnName="Create"
      />
      <Button size="sm" variant="falcon-default" className=" ms-2 mb-1" onClick={onClose}>
        Close
      </Button>
    </>
  );
}
