import { useAuth } from 'hooks/useAuth';
import { visibleColumnIds, columnsConfig, availableColumns } from './columnsConfig';

export default function useGetTableColumns(key) {
  const { userSettings } = useAuth();
  const selectedColumnIds = userSettings[key]?.selectedColumns || [];

  return {
    defaultVisibleColumnIds: visibleColumnIds,
    visibleColumnIds: selectedColumnIds.length > 0 ? selectedColumnIds : visibleColumnIds,
    availableColumns: columnsConfig.filter(item => availableColumns.includes(item.accessor))
  };
}
