import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, className, ...rest }, ref) => {
    const defaultRef = React.useRef();

    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check type="checkbox" className={classNames('form-check fs-0 mb-0', className)}>
        <Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
      </Form.Check>
    );
  }
);

export const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id, canEdit, inputWidth = '100%' },
  updateMyData, // This is a custom function that we supplied to our table instance
  editableRowIndex
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = e => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return index === editableRowIndex && canEdit ? (
    <input
      style={{ maxWidth: inputWidth, width: inputWidth }}
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      className="form-control form-control-sm fs--1 w-fit-content"
    />
  ) : (
    <p className="mb-0" style={{ maxWidth: inputWidth }}>
      {value}
    </p>
  );
};
