import React from 'react';
import IconButton from 'components/common/IconButton';

export default function ActionsBtn({
  onEdit,
  loading,
  viewMode,
  onCancel,
  btnName = 'Edit notes'
}) {
  return (
    <>
      {!viewMode && (
        <IconButton
          size="sm"
          icon="times"
          className="ms-2"
          onClick={onCancel}
          variant="falcon-default"
          iconClassName="fs--2 me-1"
        >
          Cancel
        </IconButton>
      )}
      <IconButton
        size="sm"
        className="ms-2"
        onClick={onEdit}
        loading={loading}
        variant="falcon-default"
        iconClassName="fs--2 me-1"
        icon={viewMode ? 'pencil-alt' : 'check'}
      >
        {viewMode ? btnName : 'Save changes'}
      </IconButton>
    </>
  );
}
