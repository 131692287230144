import { createContext, useContext } from 'react';

export const UserContext = createContext({
  userDetail: {},
  userId: null,
  onCloseModal: () => {},
  fetchUserList: () => {},
  loadingUserDetail: false
});

UserContext.displayName = 'UserContext';

export const useUserContext = () => useContext(UserContext);
