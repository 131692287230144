import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from 'hooks/useAuth';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, Table } from 'react-bootstrap';
import useFetchGroup from 'pages/Group/services/useFetchGroup';
import groupServices from 'services/group.services';
import AsyncSelect from 'components/common/search/AsyncSelect';
import SingleNote from 'components/SingleNote';
import { ROLES } from 'constants/index';
import AuthenticationWrapper from 'components/authentication/AuthenticationWrapper';
import { useGroupContext } from 'pages/Group/useGroupContext';

export default function SupervisorAndNotes() {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { groupId, loadingDetail, data } = useGroupContext();
  const { viewAsRole } = useAuth();
  const { groupSupervisor, fetchGroupSupervisor } = useFetchGroup();
  const [dataUser, setDataUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const isAdmin = viewAsRole === ROLES.ADMIN;

  const onSelectItem = async item => {
    try {
      const newVal = { id: item.value, name: item.label, email: item?.email };
      await groupServices.assignToGroupSupervisor(groupId, item.value);
      setDataUser(dataUser.filter(record => record.id !== item.value));
      setSelectedUser([...selectedUser, newVal]);
      toast.success('Assign supervisors successfully!', {
        theme: 'colored'
      });
    } catch (err) {
      console.error(err.message);
      toast.error('Assign supervisors failed!', {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    setSelectedUser(groupSupervisor);
  }, [groupSupervisor]);

  useEffect(() => {
    if (groupId) {
      fetchGroupSupervisor(groupId);
    }
  }, [groupId]);

  const fetchSupervisor = inputValue => {
    return groupServices.getListAvailableToSupervisor(groupId, {
      user_name: inputValue
    });
  };

  const onUpdateNote = async formValue => {
    try {
      await groupServices.updateGroup(groupId, { ...data, ...formValue });
    } catch (err) {
      console.error(err);
    }
  };

  const onDeleteSuperVisor = async supervisorId => {
    try {
      if (loadingDelete) return;
      setLoadingDelete(true);
      await groupServices.deleteGroupSupervisor(groupId, supervisorId);
      toast.success('The supervisor was successfully deleted!');
      await fetchGroupSupervisor(groupId);
    } catch (err) {
      toast.error(err?.error?.message || 'Could not delete the supervisor.');
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <Row className="g-3 supervisor-note">
      <Col xs={12} lg={6}>
        <Card className="mb-3 align-self-stretch flex-1 single-note">
          <>
            <Card.Header className="d-flex justify-content-between">
              <h5 className="fs-0 d-inline-flex align-items-center mb-0">Supervisor</h5>
              <AuthenticationWrapper
                viewAsRole={viewAsRole}
                permissions={[ROLES.ADMIN, ROLES.GROUP]}
              >
                <AsyncSelect
                  value={null}
                  placeholder="Search..."
                  fetchFn={fetchSupervisor}
                  onChange={onSelectItem}
                  wrapperClassname="ms-2"
                  listFilteredItem={selectedUser.map(user => user.id)}
                />
              </AuthenticationWrapper>
            </Card.Header>
            <Card.Body className="border-top p-0">
              <Table responsive size="sm" className="fs--1 table-responsive visible-scrollbar">
                <thead>
                  <tr>
                    <th className="text-900">Name</th>
                    <th className="text-900">Email</th>
                    <th className="text-900 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUser.map(item => (
                    <tr key={item.id} className="fs--1">
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td className="text-center">
                        {isAdmin && (
                          <FontAwesomeIcon
                            icon="trash"
                            className={classNames('ms-1 fs--1', {
                              'opacity-25': loadingDelete,
                              'cursor-pointer': !loadingDelete
                            })}
                            onClick={() => onDeleteSuperVisor(item.id)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer className="border-top text-end"></Card.Footer>
          </>
        </Card>
      </Col>
      <Col xs={12} lg={6}>
        <SingleNote
          name="notes"
          titleClass="fs-0"
          title="Group Note"
          value={data?.notes}
          handleUpdate={onUpdateNote}
          loadingInformation={loadingDetail}
          hasPermission={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL].includes(viewAsRole)}
        />
      </Col>
    </Row>
  );
}
