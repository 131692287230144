import React, { useContext, useEffect } from 'react';
// import { useIdleTimer } from 'react-idle-timer';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import AppContext from 'context/Context';
import { useAuth } from 'hooks/useAuth';
import Footer from 'components/footer/Footer';
import NavbarTop from 'components/navbar/top/NavbarTop';
// import { setAuthStore } from 'stores/auth.store';
import { PATH_LOGIN, PATH_LOCK_SCREEN } from 'routes/route.path';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
// import { onSignOutFirebaseSession } from 'services/firebase.service';
import SwitchRoles from 'components/authentication/SwitchRole';

const MainLayout = () => {
  // const navigate = useNavigate();
  const { isLogin, userData, hasSupervisorRole } = useAuth();
  const { hash, pathname } = useLocation();

  // const onIdle = () => {
  //   setAuthStore({
  //     accessToken: null,
  //     userData
  //   });

  //   navigate(PATH_LOCK_SCREEN);
  //   onSignOutFirebaseSession();
  // };

  // useIdleTimer({
  //   onIdle,
  //   timeout: process.env.REACT_APP_IDLE_TIME_OUT,
  //   throttle: 500,
  //   crossTab: true
  // });

  const {
    config: { isFluid }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!isLogin) {
    if (userData?.email) return <Navigate to={PATH_LOCK_SCREEN} />;
    return <Navigate to={PATH_LOGIN} />;
  }

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <NavbarVertical />
      <div className="content overflow-x-hidden">
        <NavbarTop />
        <div className="d-block d-sm-none mt-2 mb-4">{hasSupervisorRole && <SwitchRoles />}</div>
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
