import React, { useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { isValidEmail } from 'helpers/utils';
import LoadingButton from 'components/common/LoadingButton';
import transactionServices from 'services/transaction.service';

export default function SendingReceipt({
  transactionId,
  fetchTransactionDetail,
  isRefundReceiptSent
}) {
  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);

  const onValidateEmail = () => {
    const invalidEmail = !isValidEmail(email);
    if (invalidEmail) {
      toast.error('Invalid email');
    }
    setInvalidEmail(invalidEmail);
    return !invalidEmail;
  };

  const onSendEmailReceipt = async () => {
    try {
      if (!onValidateEmail()) return;
      setLoadingSendEmail(true);
      await transactionServices.sendingReceipt(transactionId, {
        destination: email,
        type: 'email',
        receipt_type: 'refund-receipt'
      });
      fetchTransactionDetail(transactionId);
      toast.success('The receipt was successfully sent.');
    } catch (err) {
      toast.error(err?.error?.message || 'Could not send receipt. Please try later');
    } finally {
      setLoadingSendEmail(false);
    }
  };
  return (
    <>
      <tr className="d-flex align-items-center">
        <td className="ml-auto">
          {!isRefundReceiptSent && (
            <>
              <input
                placeholder="input email..."
                className={classNames('form-control form-control-sm fs--1 w-fit-content', {
                  'is-invalid': invalidEmail
                })}
                onChange={e => setEmail(e.target.value)}
              />
            </>
          )}
        </td>
        <td className="bg-100 text-start">
          <LoadingButton
            isLoading={loadingSendEmail}
            size="sm"
            btnProps={{
              onClick: onSendEmailReceipt,
              type: 'button',
              style: {
                width: 'fit-content',
                margin: '10px 0'
              },
              disabled: isRefundReceiptSent
            }}
            name={isRefundReceiptSent ? 'Sent' : 'Email Receipt'}
            loadingBtnName="Email Receipt"
          />
        </td>
      </tr>
    </>
  );
}
