import React, { useEffect, useState } from 'react';
import useQuery from 'hooks/useQuery';
import { Spinner } from 'react-bootstrap';
import authServices from 'services/auth.services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export default function ResendActivationMail() {
  const { query } = useQuery();
  const [loading, setLoading] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState(false);

  const resendEmail = async token => {
    try {
      setLoading(true);
      await authServices.resendActivationEmail(token);
      setStatusSuccess(true);
    } catch (err) {
      console.error(err.message);
      setStatusSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query?.token) {
      resendEmail(query.token);
    }
  }, [query?.token]);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="grow" />
      </div>
    );
  }

  return (
    <>
      {statusSuccess ? (
        <div className="text-center">
          <FontAwesomeIcon icon={faCheckCircle} className="me-2" size="3x" color="#00d27a" />
          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold mx-5">
            Verification email has been resent. Please check your email.
          </p>
        </div>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="me-2"
            size="4x"
            color="#f5803e"
          />
          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold mx-5">
            The attempt to resend the email has failed!
          </p>
        </div>
      )}
    </>
  );
}
