import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import yup from 'validation.yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { WizardInput } from 'components/wizard';
import ActionsBtn from '../common/button/ActionsBtn';
import Skeleton from '../common/Skeleton';

const SingleNote = ({
  name,
  value,
  handleUpdate,
  headerClass,
  titleClass,
  loadingInformation,
  title = 'Note',
  hasPermission = true,
  placeholder = 'Add your notes...'
}) => {
  const [viewMode, setviewMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const schema = yup
    .object({
      [name]: yup.string().max(255)
    })
    .required();

  const {
    register,
    trigger,
    getValues,
    resetField,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    values: {
      [name]: value
    }
  });

  useEffect(() => {
    if (value) {
      setData(value);
    }
  }, [value]);

  const onUpdate = async formValue => {
    try {
      setLoading(true);
      await handleUpdate(formValue);
      setviewMode(true);
      setData(formValue[name]);
      toast.success('The information was successfully updated.');
    } catch (err) {
      console.error(err);
      toast.error('Could not update the information.');
    } finally {
      setLoading(false);
    }
  };

  const onEdit = () => {
    if (viewMode) {
      setviewMode(false);
    } else {
      trigger();
      const formValue = getValues();
      const isValid = schema.isValidSync(formValue);
      if (isValid) {
        onUpdate(formValue);
      }
    }
  };

  const onReset = () => {
    resetField(name, data || '');
    setviewMode(true);
  };

  return (
    <Card className="mb-3 align-self-stretch flex-1 single-note">
      <>
        <Card.Header className={headerClass}>
          <h5 className={classNames(titleClass, 'mb-0')}>{title}</h5>
        </Card.Header>
        <Card.Body className="bg-body-tertiary border-top">
          <Skeleton loading={loadingInformation} rows={3}>
            {viewMode ? (
              <p>{data}</p>
            ) : (
              <WizardInput
                type="textarea"
                register={register}
                errors={errors}
                label=""
                name={name}
                placeholder={placeholder}
                formControlProps={{
                  ...register(name)
                }}
              />
            )}
          </Skeleton>
        </Card.Body>
        <Card.Footer className="border-top text-end">
          {hasPermission && (
            <ActionsBtn viewMode={viewMode} onEdit={onEdit} loading={loading} onCancel={onReset} />
          )}
        </Card.Footer>
      </>
    </Card>
  );
};

export default SingleNote;
