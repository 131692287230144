import React from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'helpers/utils';
import AvatarImg from 'assets/img/avatar.png';
import Avatar from '../Avatar';

export default ({
  value,
  fetchFn,
  defaultOptions,
  wrapperClassname,
  selectClass = '',
  mapkey = 'name',
  mapValue = 'id',
  cacheOptions = true,
  prefixIcon = 'search',
  hasMedia = true,
  CustomOptions,
  isClearable = false,
  listFilteredItem = [],
  ...props
}) => {
  const loadOptions = debounce((inputValue, callback) => {
    fetchFn(inputValue)
      .then(res => {
        const data = (res.items || []).filter(item => !listFilteredItem.includes(item[mapValue]));
        callback([
          {
            label: 'Members',
            options: data.map(item => ({
              label: item[mapkey],
              value: item[mapValue],
              ...item
            }))
          }
        ]);
      })
      .catch(err => {
        console.error(err.message);
        callback([]);
      });
  }, 400);

  const Option = props => {
    if (CustomOptions) return <CustomOptions {...props} />;
    return (
      <components.Option {...props} className="d-flex align-items-center">
        {hasMedia && (
          <Avatar src={props.data.avatar} size="l" fallback={AvatarImg} className="me-1" />
        )}
        {props.data.label}
      </components.Option>
    );
  };

  const GroupHeading = () => (
    <div className="px-x1 pt-0 pb-2 fw-medium">
      <h6 className="mb-0">Members</h6>
    </div>
  );

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <FontAwesomeIcon icon={prefixIcon} className="text-400 ms-2" />
      {children}
    </components.Control>
  );

  return (
    <div className={classNames('w-100 fs--1', wrapperClassname)}>
      <AsyncSelect
        value={value}
        hideSelectedOptions
        classNamePrefix="async"
        className={`custom-select ${selectClass}`}
        loadOptions={loadOptions}
        isClearable={isClearable}
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        components={{ Option, GroupHeading, Control }}
        {...props}
      />
    </div>
  );
};
