import React, { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
  AdvanceTable,
  AdvanceTableWrapper,
  AdvanceTableHeader,
  AdvanceTablePagination
} from 'components/common/advance-table';
import { DATA_PER_PAGE, TABLE_COLUMN_FILTER_KEY, ROLES, DRIVER } from 'constants/index';
import useGetTableColumns from './services/useGetTableColumns';
import useFetchDriver from './services/useFetchDriver';
import AddUpdateDriver from './AddUpdateDriver';
import ScreenSearchPanel from 'components/common/search/ScreenSearchPanel';
import { useAuth } from 'hooks/useAuth';
import { DriversContext } from './DriversContext';

const Drivers = () => {
  const { viewAsRole } = useAuth();
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const { availableColumns, visibleColumnIds, defaultVisibleColumnIds } = useMemo(
    () => useGetTableColumns(TABLE_COLUMN_FILTER_KEY.DRIVER),
    [TABLE_COLUMN_FILTER_KEY.DRIVER]
  );
  const [isShowModal, setIsShowModal] = useState({ show: false, driverId: null });
  const {
    loading,
    driverList,
    driverDetail,
    fetchDriverList,
    fetchDriverDetail,
    resetDriverDetail,
    loadingDetail
  } = useFetchDriver();
  const { driverId, show } = isShowModal;
  const { items = [], total_count: totalCount = 0 } = driverList || {};

  const onAddNew = () => {
    setIsShowModal({ driverId: null, show: true });
  };

  const onClickRow = row => {
    const { id, fleet_id, company_id } = row;
    fetchDriverDetail({
      driver_id: id,
      fleet_id,
      company_id
    });

    setIsShowModal({
      driverId: id,
      show: true
    });
  };

  const onCloseModal = () => {
    resetDriverDetail();
    setIsShowModal({ driverId: null, show: false });
  };

  const fetchDriverListByPage = () => {
    fetchDriverList({
      pageIndex: tablePageIndex
    });
  };

  const onUpdateStatusSuccess = driverInfo => {
    fetchDriverListByPage();
    fetchDriverDetail(driverInfo);
  };

  const onUpdateOrCreateDriverSuccess = () => {
    onCloseModal();
    fetchDriverListByPage();
  };

  const onChangePage = page => {
    setTablePageIndex(page);
  };

  const providerValues = useMemo(
    () => ({
      driverId,
      loadingDetail,
      onCloseModal,
      fetchDriverDetail,
      onUpdateStatusSuccess,
      onUpdateOrCreateDriverSuccess,
      data: driverId ? driverDetail : {}
    }),
    [
      driverId,
      driverDetail,
      onCloseModal,
      loadingDetail,
      fetchDriverDetail,
      onUpdateOrCreateDriverSuccess,
      onUpdateStatusSuccess
    ]
  );

  return (
    <DriversContext.Provider value={providerValues}>
      <AdvanceTableWrapper
        sortable
        pagination
        data={items}
        loading={loading}
        perPage={DATA_PER_PAGE}
        totalCount={totalCount}
        rowsPerPageSelection
        onChangePage={onChangePage}
        columns={availableColumns}
        fetchData={fetchDriverList}
        visibleColumnIds={visibleColumnIds}
        defaultVisibleColumnIds={defaultVisibleColumnIds}
      >
        <Card className="mb-3">
          <Card.Header>
            <AdvanceTableHeader
              table
              canAddNew
              title="Drivers"
              canFilter
              onAddNew={onAddNew}
              hasPermissionAdd={ROLES.DRIVER !== viewAsRole}
              canFilterColumns
              filterKey={TABLE_COLUMN_FILTER_KEY.DRIVER}
            />
          </Card.Header>
          <Card.Body className="p-0 custom-table d-flex flex-wrap pb-md-3">
            <div className="flex-1 d-flex flex-column flex-1 scrollbar visible-scrollbar h-fit-content w-100 rounded-end-lg">
              <AdvanceTable
                table
                onClickRow={onClickRow}
                headerClassName="bg-200 text-nowrap"
                rowClassName="align-middle white-space-nowrap cursor-pointer"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </div>
            <ScreenSearchPanel table pageKey={DRIVER} />
          </Card.Body>
        </Card>
      </AdvanceTableWrapper>
      {show && (
        <AddUpdateDriver
          show={show}
          driverId={driverId}
          onCloseModal={onCloseModal}
          data={driverId ? driverDetail : {}}
        />
      )}
    </DriversContext.Provider>
  );
};

export default Drivers;
