import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function LoadingOverlay() {
  return (
    <div className="loading-overlay position-absolute d-flex justify-content-center align-items-center w-100 h-100 top-0 left-0">
      <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-light" size="2x" />
    </div>
  );
}
