import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import CustomPhoneInputControl from './components/CustomPhoneInputControl';
import CustomDateInput from './components/CustomDateInput';
import 'react-phone-number-input/style.css';
import { getValueObj } from 'helpers/utils';
import { Controller } from 'react-hook-form';

const WizardInput = forwardRef(
  (
    {
      label,
      errors,
      control,
      setValue,
      required,
      placeholder,
      formGroupProps,
      datepickerProps,
      formControlProps,
      labelClassName,
      defaultCountry,
      name = '',
      type = 'text',
      rows = 3,
      isLoading = false,
      isClearable = false,
      isSearchable = false,
      options = []
    },
    ref
  ) => {
    const [date, setDate] = useState(null);
    const errorMsg = getValueObj(errors, name.split('.'));

    if (type === 'date') {
      return (
        <Form.Group {...formGroupProps}>
          {!!label && (
            <Form.Label htmlFor={name} className={labelClassName}>
              {label}
              {required && <span className="is-required">*</span>}
            </Form.Label>
          )}

          <DatePicker
            selected={date}
            onChange={date => {
              setDate(date);
              setValue(name, date);
            }}
            customInput={
              <CustomDateInput
                formControlProps={formControlProps}
                errorMessage={errorMsg?.message}
                isInvalid={errorMsg}
                isValid={Object.keys(errors).length > 0 && !errorMsg}
              />
            }
            {...datepickerProps}
          />
        </Form.Group>
      );
    }

    if (type === 'checkbox' || type === 'switch' || type === 'radio') {
      return (
        <Form.Check type={type} id={name + Math.floor(Math.random() * 100)}>
          <Form.Check.Input
            type={type}
            {...formControlProps}
            isInvalid={errorMsg}
            isValid={Object.keys(errors).length > 0 && !errorMsg}
          />
          <Form.Check.Label className="ms-2">
            {label}
            {required && <span className="is-required">*</span>}
          </Form.Check.Label>
          <Form.Control.Feedback type="invalid" className="mt-0">
            {errorMsg?.message}
          </Form.Control.Feedback>
        </Form.Check>
      );
    }
    // Deprecated component (support by falcon theme)
    if (type === 'select') {
      return (
        <Form.Group {...formGroupProps}>
          {label && (
            <Form.Label htmlFor={name} className={labelClassName}>
              {label}
              {required && <span className="is-required">*</span>}
            </Form.Label>
          )}
          <Form.Select
            type={type}
            id={name}
            {...formControlProps}
            isInvalid={errorMsg}
            placeholder={placeholder}
            defaultValue=""
          >
            <option value="">{placeholder}</option>
            {options.map(option => (
              <option value={option.value} key={option.label}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errorMsg?.message}</Form.Control.Feedback>
        </Form.Group>
      );
    }

    // New select component, support various case
    if (type === 'new-select') {
      return (
        <Form.Group {...formGroupProps}>
          {label && (
            <span className={`${labelClassName} label d-inline-block`}>
              {label}
              {required && <span className="is-required">*</span>}
            </span>
          )}
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  placeholder={placeholder}
                  value={field.value}
                  className={classNames('custom-select-control size-sm', {
                    'is-invalid': !!errorMsg?.value?.message
                  })}
                  classNamePrefix="select"
                  onChange={value => field.onChange(value)}
                  isDisabled={formControlProps?.disabled}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  name={name}
                  options={options}
                  isLoading={isLoading}
                  {...field}
                  ref={ref}
                />
              );
            }}
          />
          <Form.Control.Feedback type="invalid">{errorMsg?.value?.message}</Form.Control.Feedback>
        </Form.Group>
      );
    }

    if (type === 'textarea') {
      return (
        <Form.Group {...formGroupProps}>
          {label && (
            <Form.Label htmlFor={name} className={labelClassName}>
              {label}
              {required && <span className="is-required">*</span>}
            </Form.Label>
          )}
          <Form.Control
            as="textarea"
            placeholder={placeholder}
            id={name}
            {...formControlProps}
            isValid={Object.keys(errors).length > 0 && !errorMsg}
            isInvalid={errorMsg}
            rows={rows}
          />
          <Form.Control.Feedback type="invalid">{errorMsg?.message}</Form.Control.Feedback>
        </Form.Group>
      );
    }
    // do not pass props formControlProps={{ ...register('phone') }}
    if (type === 'phoneInput') {
      return (
        <Form.Group {...formGroupProps}>
          {label && (
            <Form.Label htmlFor={name} className={labelClassName}>
              {label}
              {required && <span className="is-required">*</span>}
            </Form.Label>
          )}
          <PhoneInputWithCountry
            name={name}
            control={control}
            defaultCountry={defaultCountry}
            countryOptionsOrder={['AU', 'US', '...']}
            inputComponent={CustomPhoneInputControl}
            {...{ isInvalid: errorMsg, placeholder, id: name, ...formControlProps }} // pass props down CustomPhoneInputControl, do not add register react-hook-form prop
            className={classNames({
              'is-invalid': errorMsg?.message
            })}
          />
          <Form.Control.Feedback type="invalid">{errorMsg?.message}</Form.Control.Feedback>
        </Form.Group>
      );
    }
    return (
      <Form.Group {...formGroupProps}>
        {label && (
          <Form.Label htmlFor={name} className={labelClassName}>
            {label}
            {required && <span className="is-required">*</span>}
          </Form.Label>
        )}
        <Form.Control type={type} {...formControlProps} isInvalid={errorMsg} id={name} />
        <Form.Control.Feedback type="invalid">{errorMsg?.message}</Form.Control.Feedback>
      </Form.Group>
    );
  }
);

WizardInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  setValue: PropTypes.func,
  datepickerProps: PropTypes.object
};

WizardInput.defaultProps = { required: false };

export default WizardInput;
