import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import transactionServices from 'services/transaction.service';
import { LIST_REFUND_OPTIONS, ToastMessage, YupMessage } from 'constants/index';

export default function Refunds({
  id,
  amount,
  status,
  fetchListTransaction,
  fetchTransactionDetail
}) {
  const [loading, setLoading] = useState(false);
  const [currentRefund, setCurrentRefund] = useState({});
  const [partialRefundAmount, setPartialRefundAmount] = useState(null);
  const [errorPartialRefund, setErrorPartialRefund] = useState(null);
  const isPartialRefund = currentRefund.value === LIST_REFUND_OPTIONS()[1].value;
  const listRefundOpts = LIST_REFUND_OPTIONS(isPartialRefund);

  const onValidatePartialRefund = () => {
    if (Number(partialRefundAmount) > Number(amount)) {
      setErrorPartialRefund(YupMessage.PARTIAL_REFUND_LOWER_THAN_AMOUNT);
      return false;
    }
    return true;
  };

  const onActionUpdateSuccess = () => {
    fetchListTransaction();
    fetchTransactionDetail(id);
  };

  const handlePartialRefund = async () => {
    try {
      if (loading) return;
      setLoading(true);
      if (!onValidatePartialRefund()) return;
      await transactionServices.onTransactionRefund(id, {
        type: currentRefund.value,
        amount: partialRefundAmount
      });
      onActionUpdateSuccess();
      toast.success(ToastMessage.REFUND_SUCCESS);
    } catch (err) {
      console.error(err);
      toast.error(ToastMessage.REFUND_FAIL);
    } finally {
      setLoading(false);
    }
  };

  const handleFullRefund = async () => {
    try {
      setLoading(true);
      await transactionServices.onTransactionRefund(id, {
        amount,
        type: listRefundOpts[0].value
      });
      onActionUpdateSuccess();
      toast.success(ToastMessage.REFUND_SUCCESS);
    } catch (err) {
      toast.error(ToastMessage.REFUND_FAIL);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRefundType = opt => {
    setCurrentRefund(opt);

    if (opt.value === listRefundOpts[0].value) handleFullRefund();
    if (opt.value === listRefundOpts[2]?.value) {
      setPartialRefundAmount(null);
      setCurrentRefund({});
      setErrorPartialRefund(null);
    }
  };

  if (!status || status.includes('refund')) return null;

  // if (status.includes('refund')) {
  //   return (
  //     <div className="flex-1">
  //       <Button variant="warning" className="ml-auto" size="sm">
  //         Cancel Refund
  //       </Button>
  //     </div>
  //   );
  // }

  return (
    <div className="flex-1" style={{ minWidth: 280 }}>
      <Dropdown className="font-sans-serif btn-reveal-trigger d-inline-block" align="start">
        <Dropdown.Toggle
          size="sm"
          disabled={loading}
          variant="falcon-default"
          data-boundary="viewport"
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} className="ms-1 fs--2 fa-spin" />
          ) : (
            <FontAwesomeIcon icon={faRecycle} className="ms-1 fs--2" transform="shrink-3" />
          )}
          <span className="ms-1">{currentRefund.label || 'Refund'} </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="border py-0">
          <div className="py-2">
            {listRefundOpts.map(record => (
              <Dropdown.Item
                as="li"
                key={record.value}
                className="cursor-pointer"
                onClick={() => handleRefundType(record)}
              >
                {record.label}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {isPartialRefund && (
        <>
          <div
            className={`input-group input-group-sm my-2 ${!!errorPartialRefund && 'is-invalid'}`}
            style={{ width: 250 }}
          >
            <input
              type="number"
              className={`form-control ${!!errorPartialRefund && 'is-invalid'}`}
              aria-label="refund input"
              aria-describedby="refund-sizing-sm"
              onChange={e => setPartialRefundAmount(e.target.value)}
            />
            <span
              id="refund-sizing-sm"
              onClick={handlePartialRefund}
              className="input-group-text cursor-pointer"
            >
              Refund
            </span>
          </div>
          {errorPartialRefund && <div className="invalid-feedback">{errorPartialRefund}</div>}
        </>
      )}
    </div>
  );
}
