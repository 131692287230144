import { axiosClient } from './request';

const commonServices = {
  listCountry() {
    return axiosClient.get('/countries');
  },
  listStateByCountry(countryCode) {
    return axiosClient.get(`/countries/${countryCode}/states`);
  },
  getTimeZone() {
    return axiosClient.get('/timezones');
  },
  checkABN(params) {
    return axiosClient.get('/users/checking', { params });
  }
};

export default commonServices;
