import {
  PATH_USER,
  PATH_GROUP,
  PATH_FLEETS,
  PATH_DRIVERS,
  PATH_COMPANIES,
  PATH_TRANSACTIONS
} from './route.path';

export const pagesRoutes = {
  label: 'Home',
  children: [
    {
      name: 'Transactions',
      icon: 'flag',
      to: PATH_TRANSACTIONS,
      exact: true,
      active: true,
      permission: 'read',
      roles: [1, 2, 3, 4, 5, 6]
    },
    {
      name: 'Groups',
      icon: 'share-alt',
      to: PATH_GROUP,
      active: true,
      permission: 'read',
      roles: [1, 2, 3]
    },
    {
      name: 'Companies',
      icon: 'puzzle-piece',
      to: PATH_COMPANIES,
      active: true,
      permission: 'read',
      roles: [1, 2, 3, 4]
    },
    {
      name: 'Fleets',
      icon: 'poll',
      to: PATH_FLEETS,
      active: true,
      permission: 'read',
      roles: [1, 2, 3, 4, 5]
    },
    {
      name: 'Drivers',
      icon: 'ticket-alt',
      to: PATH_DRIVERS,
      active: true,
      permission: 'read',
      roles: [1, 2, 3, 4, 5, 6]
    },
    {
      name: 'Users',
      icon: 'user',
      to: PATH_USER,
      active: true,
      permission: 'read',
      roles: [1]
    }
  ]
};

export default [pagesRoutes];
