import { useState, useCallback } from 'react';
import groupServices from 'services/group.services';

export default function useFetchGroup() {
  const [listGroup, setListGroup] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [groupDetail, setGroupDetail] = useState({});
  const [loadingGroupSupervisor, setLoadingGroupSupervisor] = useState(false);
  const [groupSupervisor, setSupervisorsByGroup] = useState([]);

  const fetchGroupSupervisor = useCallback(async id => {
    try {
      setLoadingGroupSupervisor(true);
      const data = await groupServices.getGroupSupervisors(id);
      setSupervisorsByGroup(data.items || []);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingGroupSupervisor(false);
    }
  }, []);

  const fetchListGroup = useCallback(async body => {
    try {
      setLoading(true);
      const data = await groupServices.list(body);
      setListGroup(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchGroupDetail = useCallback(async id => {
    try {
      setLoadingDetail(true);
      const data = await groupServices.detail(id);
      setGroupDetail(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingDetail(false);
    }
  }, []);

  const resetGroupDetail = useCallback(() => {
    setGroupDetail({});
  }, []);

  return {
    loading,
    listGroup,
    fetchListGroup,
    loadingDetail,
    groupDetail,
    resetGroupDetail,
    fetchGroupDetail,
    groupSupervisor,
    fetchGroupSupervisor,
    loadingGroupSupervisor
  };
}
