/* eslint-disable react/prop-types */
import React from 'react';
import { Pagination } from 'react-bootstrap';
import Flex from '../Flex';
import { DOTS, usePaginationRange } from './usePaginationRange';

export const AdvanceTablePagination = ({
  page,
  gotoPage,
  pageSize,
  totalCount,
  siblingCount,
  pageIndex: currentPage
}) => {
  const totalPageCount = Math.ceil(totalCount / pageSize) || 0;

  if (totalPageCount < 2) return <div />;

  const paginationRange = usePaginationRange({
    pageSize,
    totalCount,
    currentPage,
    siblingCount,
    totalPageCount
  });

  return (
    <Flex alignItems="center" justifyContent="between" wrap="wrap">
      <div className="d-flex justify-content-between align-items-center fs--1">
        <p className="mb-0 mx-2">
          Showing {pageSize * currentPage + 1} to {pageSize * currentPage + page.length} of{' '}
          {totalCount} entries
        </p>
      </div>
      <Flex alignItems="center" justifyContent="center" className="mt-2">
        <Pagination size="sm" className="mb-0">
          <Pagination.Prev disabled={currentPage === 0} onClick={() => gotoPage(currentPage - 1)}>
            Previous
          </Pagination.Prev>
          {paginationRange.map((pageNumber, idx) => {
            if (pageNumber === DOTS) return <Pagination.Ellipsis key={`${pageNumber}-${idx}`} />;
            return (
              <Pagination.Item
                key={pageNumber}
                onClick={() => gotoPage(pageNumber - 1)}
                active={currentPage + 1 === pageNumber}
              >
                {pageNumber}
              </Pagination.Item>
            );
          })}
          <Pagination.Next
            disabled={currentPage === totalPageCount - 1}
            onClick={() => gotoPage(currentPage + 1)}
          >
            Next
          </Pagination.Next>
        </Pagination>
      </Flex>
    </Flex>
  );
};

export default AdvanceTablePagination;
