import React from 'react';
import dayjs from 'dayjs';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FORMAT_DATE_TIME_II } from 'constants';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserStatus } from 'components/common/status';
import { SERVICE_FEE_TYPE } from 'constants';
import VehicleStatus from '../AddUpdateDriver/components/VehicleStatus';
import { DRIVER_STATUS } from '../AddUpdateDriver/driver.constant';

export const visibleColumnIds = [
  'dc_number',
  'name',
  'email',
  'status',
  'stripe_connect_enabled',
  'currency',
  'state',
  'country',
  'group_name',
  'service_fee',
  'company_name',
  'fleet_name',
  'payment_point',
  'driver_comm'
];

export const availableColumns = [
  'dc_number',
  'name',
  'email',
  'status',
  'stripe_connect_enabled',
  'currency',
  'state',
  'country',
  'group_name',
  'group_comm',
  'service_fee_type',
  'service_fee',
  'company_name',
  'fleet_name',
  'payment_point',
  'driver_comm'
];

export const columnsConfig = [
  {
    accessor: 'dc_number',
    Header: 'Driver Accreditation',
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'py-2 d-flex align-items-center'
    }
  },
  {
    accessor: 'name',
    Header: 'Driver Name',
    disableSortBy: true,
    width: 200,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, avatar } = rowData.row.original;
      return (
        <Flex alignItems="center">
          {avatar ? (
            <Avatar src={avatar} size="xl" className="me-2" />
          ) : (
            <Avatar size="xl" name={name} className="me-2" />
          )}
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{name}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Driver Email',
    width: 200,
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'py-2 d-flex align-items-center'
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    width: 100,
    disableSortBy: true,
    headerProps: { className: 'text-900 text-center' },
    cellProps: {
      className: 'justify-content-center'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return <UserStatus status={status} />;
    }
  },
  {
    accessor: 'stripe_connect_enabled',
    Header: 'Stripe Connect',
    width: 100,
    disableSortBy: true,
    headerProps: { className: 'text-900 text-center' },
    cellProps: {
      className: 'justify-content-center'
    },
    Cell: rowData => {
      const { stripe_connect_enabled: item } = rowData.row.original;
      return (
        <Badge bg={item === 1 ? 'success' : 'danger'} className="badge-min-w">
          {item === 1 ? 'Yes' : 'No'}
          <FontAwesomeIcon icon="check" className="ms-1" transform="shrink-2" />
        </Badge>
      );
    }
  },
  {
    accessor: 'currency',
    Header: 'Currency',
    width: 80,
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'state',
    Header: 'State',
    width: 200,
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'country',
    Header: 'Country',
    width: 80,
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'group_name',
    Header: 'Group',
    width: 180,
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'group_comm',
    Header: 'Group Rate (inc Tax)',
    disableSortBy: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'justify-content-end'
    },
    Cell: rowData => {
      const { group_comm: groupComm } = rowData.row.original;
      return <span>{groupComm}%</span>;
    }
  },
  {
    accessor: 'service_fee_type',
    Header: 'Service Fee Type',
    disableSortBy: true,
    headerProps: { className: 'text-900 text-end' },
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { service_fee_type: type } = rowData.row.original;
      return <span>{SERVICE_FEE_TYPE.find(item => item.value === type)?.label}</span>;
    }
  },
  {
    accessor: 'service_fee',
    Header: 'Service Fee',
    width: 80,
    disableSortBy: true,
    headerProps: { className: 'text-900 text-end' },
    cellProps: {
      className: 'justify-content-end'
    },
    Cell: rowData => {
      const { service_fee: serviceFee, service_fee_type: serviceFeeType } = rowData.row.original;
      if (serviceFeeType === SERVICE_FEE_TYPE[0].value) {
        return <span>{serviceFee}%</span>;
      }
      return <span>${serviceFee} </span>;
    }
  },
  {
    accessor: 'company_name',
    Header: 'Company',
    disableSortBy: true,
    width: 180,
    headerProps: { className: 'text-900 text-start' }
  },
  {
    accessor: 'fleet_name',
    Header: 'Fleet',
    disableSortBy: true,
    width: 180,
    headerProps: { className: 'text-900 text-start' },
    cellProps: {
      className: 'text-end'
    }
  },
  {
    accessor: 'payment_point',
    Header: 'Payment Point',
    disableSortBy: true,
    width: 100,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'driver_comm',
    Header: 'Endpoint Rate (inc Tax)',
    disableSortBy: true,
    width: 180,
    headerProps: { className: 'text-900 text-end me-2' },
    cellProps: {
      className: 'justify-content-end me-2'
    },
    Cell: rowData => {
      const { driver_comm: driverComm } = rowData.row.original;
      return <>{driverComm}%</>;
    }
  }
];

export const vehicleColumnsConfig = [
  {
    accessor: 'no',
    Header: 'No.',
    disableSortBy: false,
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'plate_number',
    Header: 'Plate Name',
    disableSortBy: false,
    canEdit: true,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'py-2'
    }
  },
  {
    accessor: 'created_at',
    Header: 'Created At',
    disableSortBy: false,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { created_at: createdAt } = rowData.row.original;
      return <>{dayjs(createdAt).format(FORMAT_DATE_TIME_II)}</>;
    }
  },
  {
    accessor: 'updated_at',
    Header: 'Updated At',
    disableSortBy: false,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { created_at: createdAt } = rowData.row.original;
      return <>{dayjs(createdAt).format(FORMAT_DATE_TIME_II)}</>;
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    disableSortBy: false,
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'py-2'
    },
    canEdit: true,
    Cell: rowData => {
      const { editableRowIndex, row, updateMyData } = rowData;
      const { status } = row.original;
      if (editableRowIndex === row.index) {
        return (
          <select
            className="form-select w-fit-content fs--1"
            aria-label="select"
            size="sm"
            style={{ width: 120 }}
            defaultValue={status}
            onChange={e => {
              updateMyData(row.index, 'status', Number(e.target.value));
            }}
          >
            {DRIVER_STATUS.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        );
      }

      return <VehicleStatus status={status} />;
    }
  }
];
