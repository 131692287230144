import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ActionButton = ({ placement = 'top', title, icon, type, onClick, ...rest }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip style={{ position: 'fixed' }}>{title}</Tooltip>}
    >
      {type === 'btn' ? (
        <Button {...rest} onClick={onClick}>
          <FontAwesomeIcon icon={icon} />
        </Button>
      ) : (
        <span onClick={onClick} className="cursor-pointer">
          <FontAwesomeIcon icon={icon} {...rest} />
        </span>
      )}
    </OverlayTrigger>
  );
};

export default ActionButton;
