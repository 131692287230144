import React from 'react';
import PasswordResetForm from './PasswordResetForm';

const ResetPassword = () => (
  <div className="text-center">
    <h5>Reset new password</h5>
    <PasswordResetForm />
  </div>
);

export default ResetPassword;
