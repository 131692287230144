import React from 'react';
import { useAuth } from 'hooks/useAuth';
// import Avatar from 'components/common/Avatar';
import SocialAuthButtons from 'pages/Login/components/SocialAuthButtons';

const LockScreen = () => {
  const { userData } = useAuth();
  const { email, name } = userData || {};
  return (
    <div className="text-center">
      {/* {avatar ? <Avatar size="3xl" src={avatar} /> : <Avatar size="3xl" name={name} />} */}
      <h5 className="mt-3 mb-0">Hi! {name || email}</h5>
      <small>You've been logged out. Please login again to access the portal!</small>
      <div className="mt-4">
        <SocialAuthButtons />
      </div>
    </div>
  );
};

export default LockScreen;
