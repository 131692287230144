import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);

export const today = new Date();
export const todayStart = dayjs(today).startOf('day').toDate();
export const getLastXday = day => dayjs(today).subtract(day, 'day').startOf('day').toDate();
export const getLastXdayEnd = day => dayjs(today).subtract(day, 'day').endOf('day').toDate();
export const todayUnix = dayjs(today).endOf('day').unix();
export const getLastXdayUnix = day => dayjs(today).subtract(day, 'day').startOf('day').unix();
export const FIXED_RANGE_TIME = 'fixed';
export const LAST7DAYS_RANGE_TIME = 'last7days';

export const preconfigDateList = [
  {
    id: FIXED_RANGE_TIME,
    name: 'Fixed',
    includeToday: false,
    getDate: () => []
  },
  {
    id: 'today',
    name: 'Today',
    includeToday: false,
    getDate: () => [todayStart, today]
  },
  {
    id: 'yesterday',
    name: 'Yesterday',
    includeToday: false,
    getDate: () => [getLastXday(1), getLastXdayEnd(1)]
  },
  {
    id: LAST7DAYS_RANGE_TIME,
    name: 'Last 7 days',
    includeToday: true,
    getDate: () => [getLastXday(7), getLastXdayEnd(1)],
    getDateIncludeToday: () => [getLastXday(6), today]
  },
  {
    id: 'last28days',
    name: 'Last 28 days',
    includeToday: true,
    getDateIncludeToday: () => [getLastXday(27), today],
    getDate: () => [getLastXday(28), getLastXday(1)]
  },
  {
    id: 'last30days',
    name: 'Last 30 days',
    includeToday: true,
    getDateIncludeToday: () => [getLastXday(29), today],
    getDate: () => [getLastXday(30), getLastXday(1)]
  },
  {
    id: 'thisweek(startsmonday)',
    name: 'This week (starts Monday)',
    includeToday: false,
    getDate: () => [dayjs().day(1).startOf('day').toDate(), dayjs().day(7).endOf('day').toDate()]
  },
  {
    id: 'thisweektodate(startsmonday)',
    name: 'This week to date (starts Monday)',
    includeToday: true,
    getDateIncludeToday: () => [dayjs().day(1).toDate(), today],
    getDate: () => [dayjs().day(1).toDate(), getLastXdayEnd(1)]
  },
  {
    id: 'lastweek(startsmonday)',
    name: 'Last week (starts Monday)',
    getDate: () => [
      dayjs(today).subtract(1, 'week').startOf('week').add(1, 'day').toDate(),
      dayjs(today).subtract(1, 'week').endOf('week').add(1, 'day').toDate()
    ]
  },
  {
    id: 'thismonth',
    name: 'This month',
    includeToday: false,
    getDate: () => [dayjs().date(1).toDate(), dayjs().endOf('month').toDate()]
  },
  {
    id: 'thismonthtodate',
    name: 'This month to date',
    includeToday: true,
    getDateIncludeToday: () => [dayjs().date(1).toDate(), today],
    getDate: () => [dayjs().date(1).startOf('day').toDate(), getLastXdayEnd(1)]
  },
  {
    id: 'lastmonth',
    name: 'Last month',
    getDate: () => [
      dayjs().subtract(1, 'month').startOf('month').toDate(),
      dayjs().subtract(1, 'month').endOf('month').toDate()
    ]
  },
  {
    id: 'thisquarter',
    name: 'This quarter',
    includeToday: false,
    getDate: () => [
      dayjs(today).startOf('quarter').toDate(),
      dayjs(today).endOf('quarter').toDate()
    ]
  },
  {
    id: 'thisquartertodate',
    name: 'This quarter to date',
    includeToday: true,
    getDateIncludeToday: () => [dayjs(today).startOf('quarter').toDate(), today],
    getDate: () => [dayjs(today).startOf('quarter').toDate(), getLastXdayEnd(1)]
  },
  {
    id: 'lastquarter',
    name: 'Last quarter',
    getDate: () => [
      dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
      dayjs().subtract(1, 'quarter').endOf('quarter').toDate()
    ]
  },
  {
    id: 'thisyear',
    name: 'This year',
    getDate: () => [dayjs().startOf('year').toDate(), dayjs().endOf('year').toDate()]
  },
  {
    id: 'thisyeartodate',
    name: 'This year to date',
    includeToday: true,
    getDateIncludeToday: () => [dayjs().startOf('year').toDate(), today],
    getDate: () => [dayjs().startOf('year').toDate(), getLastXdayEnd(1)]
  },
  {
    id: 'lastyear',
    name: 'Last year',
    getDate: () => [
      dayjs().subtract(1, 'year').startOf('year').toDate(),
      dayjs().subtract(1, 'year').endOf('year').toDate()
    ]
  }
];
