import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useQuery from 'hooks/useQuery';
import authServices from 'services/auth.services';
import appStore from 'assets/img/icons/AppStore.png';
import googlePlay from 'assets/img/icons/GooglePlay.png';
import SkeletonBlock from './SkeletonBlock';

export default function VerifyUser() {
  const [isActivated, setIsActivated] = useState(false);
  const [loading, setLoading] = useState(false);
  const { query } = useQuery();

  const verifyUser = async token => {
    try {
      setLoading(true);
      await authServices.verifyUser(token);
      setIsActivated(true);
    } catch (err) {
      console.error('Could not verify user!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query?.token) {
      verifyUser(query.token);
    }
  }, [query?.token]);

  return (
    <>
      {loading ? (
        <SkeletonBlock />
      ) : (
        <>
          {isActivated ? (
            <>
              <h4 className="mb-3">Your account is activated!</h4>
              <p className="fs--09 mb-1">
                Please download the CabFare app and sign in with your Apple ID or Google ID.
              </p>
              <div className="d-flex justify-content-start gap-2 my-3">
                <a
                  className="cursor-pointer"
                  href="https://apps.apple.com/au/app/cabfare/id1186131140"
                >
                  <img src={appStore} alt="app-store" width={128} />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://play.google.com/store/apps/details?id=com.cabfare"
                >
                  <img src={googlePlay} alt="google-play" width={128} />
                </a>
              </div>
              <p className="fs--09 mb-0">
                CabFare Portal is available as well, sign in with your Apple ID or Google ID.
              </p>
              <Button as={Link} color="primary" size="sm" className="mt-3" to="/auth/login">
                <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="me-1" />
                CabFare Portal
              </Button>
            </>
          ) : (
            <>
              <h4 className="mb-3">The activation link has expired!</h4>
              <p className="fs--09 mb-1">
                Please request for a new activation link as noted in the email. Thank you.
              </p>
            </>
          )}
        </>
      )}
    </>
  );
}
