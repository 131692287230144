export const DRIVER_STATUS = [
  {
    value: 0,
    label: 'Inactive'
  },
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 2,
    label: 'Busy'
  }
];
