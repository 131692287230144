import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function LoadingButton({
  name,
  btnProps,
  iconProps,
  isLoading,
  size = 'md',
  className,
  loadingBtnName = 'Loading'
}) {
  return (
    <Button
      size={size}
      {...btnProps}
      formNoValidate
      className={classNames(`d-flex align-items-center ${className} `, {
        disabled: isLoading
      })}
    >
      {isLoading ? (
        <>
          {loadingBtnName}
          <FontAwesomeIcon icon={faSpinner} className="ms-1 fs--2 fa-spin" {...iconProps} />
        </>
      ) : (
        <span>{name}</span>
      )}
    </Button>
  );
}
