export const YupMessage = {
  REQUIRED: 'This field is required',
  INVALID_EMAIL: 'Please provide a valid email',
  INVALID_FORMAT_CONTACT_NUMBER: 'Please provide a valid format (Eg: +14155552671)',
  INVALID_FORMAT: 'Please provide a valid format',
  PASSWORD_REQUIREMENT:
    'Password must contain at least 6 characters, one uppercase, one number and one special case character.',
  PASSWORD_NOT_MATCH: 'Your passwords do not match.',
  MIN_PERCENTAGE: 'This field must be at least 0(%)',
  MIN_PERCENTAGE_100: 'This field must be at least -100(%)',
  MIN_PERCENTAGE_001: 'This field must be at least 0.01(%)',
  MAX_PERCENTAGE: 'This field is maximum at 100(%)',
  TWO_DIGITS_AFTER_COMMA: 'This field must be a decimal with maximum two digits after comma',
  GROUP_RATE_NOT_GREATER_THAN_SERVICE_RATE:
    'This field is not greater than Service Fee Rate when Service Fee Type is percentage',
  NOT_GREATER_THAN_GROUP_RATE: 'This field is not greater than Group Rate',
  INVALID_DATE: 'Please provide a valid date',
  PARTIAL_REFUND_LOWER_THAN_AMOUNT:
    'The partial refund amount cannot be greater than the total amount'
};

export const ToastMessage = {
  USER_NOT_REGISTER: 'User is not registered. Please contact with admin!',
  REFUND_SUCCESS: 'The transaction was successfully refunded.',
  REFUND_FAIL: 'Could not refund. Please try later!'
};
