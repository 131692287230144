import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { authFirebase } from 'firebase.config';
import logoGG from 'assets/img/logo-google.svg';

const GG = 'gg';

export default function LoginWithGG({ onLoginWithToken, loading }) {
  const googleProvider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const responseData = await signInWithPopup(authFirebase, googleProvider);
      onLoginWithToken(responseData.user.accessToken, GG);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <button
      onClick={signInWithGoogle}
      id="login-gg"
      disabled={loading}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="d-flex align-items-center">
        {loading === GG ? (
          <FontAwesomeIcon icon={faSpinner} className="me-3 ms-1 fs--2 fa-spin ms-1" />
        ) : (
          <img className="icon" src={logoGG} alt="Logo-google" />
        )}
        <span className="button-text">Sign in with Google</span>
      </div>
    </button>
  );
}
