import { PLATFORM_FEE_TYPE, SERVICE_FEE_TYPE, YupMessage } from 'constants/index';
import {
  isContactNumber,
  percentageSchema,
  userPrincipalSchema,
  twoDigitsValidationSchema,
  isEmail
} from 'helpers/utils';
import yup from 'validation.yup';

const transformFn = value => (Number.isNaN(value) ? null : value);

export const addOrUpdateGroupSchema = isAssignExistUser =>
  yup
    .object({
      name: yup.string().required(),
      email: yup.lazy(value => {
        if (!value) return yup.string().default('').nullable();
        return yup.string().matches(isEmail, YupMessage.INVALID_EMAIL);
      }),
      tax_number: yup.string().required(),
      contact_number: yup.lazy(value => {
        if (!value) return yup.string().default('').nullable();
        return yup.string().matches(isContactNumber, YupMessage.INVALID_FORMAT);
      }),
      address: yup.object().shape({
        country_id: yup.number().transform(transformFn).required()
      }),
      platform_fee_type: yup.number().transform(transformFn).required(),
      platform_fee: yup
        .number()
        .when('platform_fee_type', {
          is: type => {
            if (type === PLATFORM_FEE_TYPE[0].value) return true;
            return false;
          },
          then: schema => {
            return schema.concat(twoDigitsValidationSchema).required();
          },
          otherwise: () => {
            return yup.number().transform(transformFn).required();
          }
        })
        .required(),
      service_fee_type: yup.number().transform(transformFn).required(),
      service_fee: yup
        .number()
        .when('service_fee_type', {
          is: type => {
            if (type === SERVICE_FEE_TYPE[0].value) return true;
            return false;
          },
          then: schema => {
            return schema.concat(percentageSchema).concat(twoDigitsValidationSchema).required();
          },
          otherwise: () => {
            return yup.number().transform(transformFn).required();
          }
        })
        .required(),
      group_comm: yup
        .number()
        .concat(percentageSchema)
        .concat(twoDigitsValidationSchema)
        .when('service_fee_type', {
          is: type => {
            if (type === SERVICE_FEE_TYPE[0].value) return true;
            return false;
          },
          then: schema => {
            return schema.max(
              yup.ref('service_fee'),
              YupMessage.GROUP_RATE_NOT_GREATER_THAN_SERVICE_RATE
            );
          }
        })
        .required(),
      face_value_warning: yup.number().concat(twoDigitsValidationSchema).nullable(),
      country_tax_rate: yup
        .number()
        .concat(percentageSchema)
        .concat(twoDigitsValidationSchema)
        .nullable()
        .required(),
      state_tax_rate: yup
        .number()
        .concat(percentageSchema)
        .concat(twoDigitsValidationSchema)
        .required(),
      ...(isAssignExistUser
        ? { existing_principal: yup.object().required() }
        : { user_principal: yup.object().concat(userPrincipalSchema) })
    })
    .required();
