import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SingleNote from 'components/SingleNote';
import driverServices from 'services/driver.services';
import { ROLES } from 'constants/index';
import { useAuth } from 'hooks/useAuth';
import { useDriversContext } from '../../DriversContext';

export default function DriverNotes() {
  const { data, driverId, loadingDetail } = useDriversContext();
  const { viewAsRole } = useAuth();

  const onUpdateNote = async formValue => {
    try {
      await driverServices.updateDriver(driverId, { ...data, ...formValue });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Row className="g-3 supervisor-note">
      <Col xs={12} lg={6}>
        <SingleNote
          name="notes"
          titleClass="fs-0"
          title="Driver Note"
          value={data?.notes}
          handleUpdate={onUpdateNote}
          loadingInformation={loadingDetail}
          hasPermission={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL].includes(viewAsRole)}
        />
      </Col>
    </Row>
  );
}
