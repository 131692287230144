import React from 'react';
import { Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';

export default function StripeConnectSuccess() {
  return (
    <Flex className="w-100 min-vh-100" justifyContent="center" alignItems="center">
      <Spinner animation="grow" />
    </Flex>
  );
}
