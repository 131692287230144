import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import useQuery from 'hooks/useQuery';
import authServices from 'services/auth.services';
import {
  PATH_LOGIN,
  PATH_STRIPE_CONNECT_ERROR,
  PATH_STRIPE_CONNECT_SUCESS
} from 'routes/route.path';

export default function StripeConnectOnboard() {
  const navigate = useNavigate();
  const { query, search } = useQuery();

  const verifyStripeConnect = async body => {
    try {
      const { data } = await authServices.verifyStripeConnectOnboard({
        ...body,
        id: parseInt(body?.id, 10)
      });
      if (data.code === 200) {
        if (data.platform) {
          navigate(PATH_LOGIN, { replace: true });
        } else {
          navigate(`${PATH_STRIPE_CONNECT_SUCESS}${search}`, { replace: true });
        }
      } else {
        navigate(PATH_STRIPE_CONNECT_ERROR);
      }
    } catch (err) {
      navigate(PATH_STRIPE_CONNECT_ERROR);
    }
  };

  useEffect(() => {
    if (query?.id) {
      verifyStripeConnect(query);
    }
  }, [query?.email]);

  return (
    <Flex className="w-100 min-vh-100" justifyContent="center" alignItems="center">
      <Spinner animation="grow" />
    </Flex>
  );
}
