import React from 'react';

export default function CurrencyAmount({ classNames, amount, symbol = '$' }) {
  return (
    <span className={classNames}>
      {symbol}
      {amount || 0}
    </span>
  );
}
