import React from 'react';
import { useAuth } from 'hooks/useAuth';

const useFieldAuthorize = Component => props => {
  const { viewAsRole } = useAuth();
  const { permissions, formControlProps, ...restProps } = props;
  const { MODIFY = [], VIEW = [] } = permissions || {};

  if (MODIFY.includes(viewAsRole) || !permissions)
    return <Component formControlProps={formControlProps} {...restProps} />;
  if (VIEW.includes(viewAsRole)) {
    formControlProps.disabled = true;
    return <Component formControlProps={formControlProps} {...restProps} />;
  }
  return null;
};

export default useFieldAuthorize;
