import { useState, useEffect } from 'react';
import useDebounce from 'hooks/useDebounce';
import userServices from 'services/user.services';
import { getValueObj } from 'helpers/utils';

export default function useCheckValidABN({
  abn,
  bypass,
  name,
  setError,
  clearErrors,
  formState,
  validateSpecificField
}) {
  const [isValidABN, setValidABN] = useState(false);
  const [errAbnMsg, setErrMsgAbn] = useState(null);
  const [pendingCheckAbn, setPendingCheckAbn] = useState(false);
  const inputABN = useDebounce(abn);

  const handleCheckValidABN = async abn => {
    try {
      setPendingCheckAbn(true);
      await userServices.checkValidInfo({ text: abn, type: 'abn' });
      setValidABN(true);
      setErrMsgAbn(null);
      clearErrors(name);
    } catch (err) {
      setValidABN(false);
      setErrMsgAbn(err?.error?.message);
      setError(name, { type: 'optionality', message: err?.error?.message });
    } finally {
      setPendingCheckAbn(false);
    }
  };

  useEffect(() => {
    if (bypass) return;
    const errorMsgEmail = getValueObj(formState?.errors, name.split('.'));
    if (!validateSpecificField || errorMsgEmail?.message) return;
    if (!errorMsgEmail?.message && errAbnMsg && !isValidABN) {
      setError(name, {
        type: 'custom',
        message: errAbnMsg
      });
    }
  }, [isValidABN, errAbnMsg, formState, validateSpecificField, bypass]);

  useEffect(() => {
    if (inputABN && !bypass) {
      handleCheckValidABN(inputABN);
    }
  }, [inputABN, bypass]);

  return {
    errAbnMsg,
    isValidABN,
    pendingCheckAbn
  };
}
