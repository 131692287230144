import { createContext, useContext } from 'react';

export const DriversContext = createContext({
  data: {},
  driverId: null,
  onCloseModal: () => {},
  loadingDetail: false,
  fetchListDriver: () => {},
  onUpdateStatusSuccess: () => {},
  onUpdateOrCreateDriverSuccess: () => {},
  fetchDetailDriver: () => {}
});

DriversContext.displayName = 'DriversContext';

export const useDriversContext = () => useContext(DriversContext);
