import React, { useState } from 'react';
import { Offcanvas, Tab } from 'react-bootstrap';
import { BasicInfo, GeneralInfo, SupervisorAndNotes } from './components';
import NoDataBlock from 'components/common/NoDataBlock';
import TabWrapper from 'components/pages/TabWrapper';

export default function AddUpdateGroup({ show, onCloseModal, groupId }) {
  const [activeTab, setActiveTab] = useState('general');
  return (
    <>
      <Offcanvas
        show={show}
        onHide={onCloseModal}
        placement={'end'}
        backdropClassName="custom-drawer"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <span className="me-1">{groupId ? 'Viewing Group' : 'Creating Group'}</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {groupId && <BasicInfo />}
          <TabWrapper
            id="controlled-tab"
            activeKey={activeTab}
            onSelect={k => setActiveTab(k)}
            visibleItems={groupId ? ['general', 'note', 'history'] : ['general']}
          >
            <Tab
              eventKey="general"
              title="General"
              tabClassName="fs-1"
              className="border-bottom border-x p-3"
            >
              <GeneralInfo />
            </Tab>
            <Tab
              eventKey="note"
              title="Supervisors/Notes"
              tabClassName="fs-1"
              className="border-bottom border-x p-3"
            >
              <SupervisorAndNotes />
            </Tab>
            <Tab
              eventKey="history"
              title="History"
              tabClassName="fs-1"
              className="border-bottom border-x p-3"
            >
              <NoDataBlock />
            </Tab>
          </TabWrapper>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
