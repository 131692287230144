import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Form, Row, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import CFDatePicker from 'components/common/CFDatePicker';
import { useBreakpoints } from 'hooks/useBreakpoints';
import ColumnsFilterBox from './ColumnsFilterBox';
import ExportBtn from './ExportBtn';

const AdvanceTableHeader = ({
  title,
  columns,
  onAddNew,
  canAddNew,
  hasPermissionAdd,
  onExport,
  downloading,
  exportType = [],
  canFilter,
  filterKey,
  selectedRowIds,
  setHiddenColumns,
  canFilterColumns,
  pageSize,
  totalCount,
  setPageSize,
  hasDatePicker,
  onSelectDatePicker,
  setGlobalFilter,
  canSearch,
  setFilterStatus,
  activeFilter,
  rowsPerPageSelection,
  defaultVisibleColumnIds,
  dates,
  rangeTime,
  rowsPerPageOptions = [10, 20, 50, 100],
  visibleColumnIds = []
}) => {
  const { breakpoints } = useBreakpoints();

  return (
    <>
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{title}</h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex me-2">
              <Form.Select size="sm" aria-label="Bulk actions">
                <option>Bulk Actions</option>
                <option value="refund">Refund</option>
                <option value="delete">Delete</option>
                <option value="archive">Archive</option>
              </Form.Select>
              <Button type="button" variant="falcon-default" size="sm" className="ms-2">
                Apply
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              {canFilterColumns && totalCount > 0 && (
                <ColumnsFilterBox
                  className="me-2"
                  columns={columns}
                  filterKey={filterKey}
                  setHiddenColumns={setHiddenColumns}
                  visibleColumnIds={visibleColumnIds}
                  defaultVisibleColumnIds={defaultVisibleColumnIds}
                />
              )}
              {canAddNew && hasPermissionAdd && (
                <IconButton
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  variant="falcon-default"
                  onClick={onAddNew}
                >
                  <span className="d-none d-sm-inline-block ms-1">New</span>
                </IconButton>
              )}
              {exportType.length > 0 && totalCount > 0 && (
                <ExportBtn onExport={onExport} exportType={exportType} downloading={downloading} />
              )}
              {canFilter && (
                <IconButton
                  size="sm"
                  icon="filter"
                  transform="shrink-3"
                  onClick={() => setFilterStatus(!activeFilter)}
                  className="ms-2 w-fit-content"
                  variant={activeFilter ? 'primary' : 'falcon-default'}
                >
                  <span className="d-none d-sm-inline-block ms-1">Filters</span>
                </IconButton>
              )}
            </div>
          )}
        </Col>
      </Row>
      <div className="d-flex justify-content-between mt-3 g-2 flex-wrap">
        {rowsPerPageSelection && (
          <div className=" fs--1 d-flex justify-content-start align-items-center">
            <p className="mb-0 me-2">Show</p>
            <Form.Select
              size="sm"
              name="pagination-table"
              className="w-auto box-shadow-none"
              onChange={e => setPageSize(Number(e.target.value))}
              defaultValue={pageSize}
            >
              {rowsPerPageOptions.map(value => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
            <p className="mb-0 mx-2">entries</p>
            <span> | Total: {totalCount} entries</span>
          </div>
        )}
        <div className="d-flex flex-wrap flex-md-nowrap align-items-center">
          {hasDatePicker && (
            <CFDatePicker
              onSelect={onSelectDatePicker}
              className="mt-1"
              defaultPreConfig={rangeTime}
              defaultValue={dates}
            />
          )}
          {canSearch && (
            <AdvanceTableSearchBox
              table
              className={classNames('max-w-350', {
                'ml-auto ms-2': breakpoints.up('md'),
                'mt-1': breakpoints.down('md')
              })}
              setGlobalFilter={setGlobalFilter}
            />
          )}
        </div>
      </div>
    </>
  );
};

AdvanceTableHeader.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  onAddNew: PropTypes.func,
  canAddNew: PropTypes.bool,
  onExport: PropTypes.func,
  canFilter: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  totalCount: PropTypes.number,
  hasDatePicker: PropTypes.bool,
  canSearch: PropTypes.bool,
  setGlobalFilter: PropTypes.func,
  selectedRowIds: PropTypes.object,
  setHiddenColumns: PropTypes.func,
  canFilterColumns: PropTypes.bool,
  rowsPerPageSelection: PropTypes.bool,
  rowsPerPageOptions: PropTypes.object,
  visibleColumnIds: PropTypes.array,
  exportType: PropTypes.arrayOf(PropTypes.oneOf(['excel', 'csv', 'pdf', 'ggSheet']))
};

AdvanceTableHeader.defaultProps = {
  exportType: []
};

export default AdvanceTableHeader;
